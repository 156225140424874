import { NgForm } from '@angular/forms'
import { DirittiSegnalazione } from './../modello/diritti-segnalazione'

import { ESITO_IN_PROGRESS, ESITO_OK } from './../utility/esito'

import { Documento } from './../modello/documento'
import { Injectable, QueryList } from '@angular/core'
import { Segnalazione } from '../modello/segnalazione'

import { AuthService } from './auth.service'

import { ServiziRest, GlobalVars, decycle } from '../utility/utility'

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Messaggio } from '../modello/messaggio'
import { Odv } from '../modello/odv'

import { Esito } from '../utility/esito'
import { Subject, Observable, of, throwError } from 'rxjs'
import { Omissis } from '../modello/omissis'
import { Informativa } from '../modello/informativa'
import { Consenso } from '../modello/consenso'
import { DocumentiService } from './documenti.service'
import { SessionData } from '../sessione/dati-sessione'

import { catchError, tap, timeout } from 'rxjs/operators'
import { DestinatariPerTipologia } from '../modello/destinatari-per-tipologia'
import { Azienda } from '../modello/azienda'
import { OscuramentoDatiManifestamenteNonNecessari } from '../modello/oscuramento-dati-manifestamente-non-necessari'

@Injectable()
export class SegnalazioneService {

  paginationInfo = new Subject<{
    total: number;
    page: number;
    limit: number;
    totalPages: number;
  }>();

  private isFetching = false // Variabile di stato per tracciare se una richiesta è in corso
  notifichePerNuovaSegnalazioneSoloLocale = new Subject<Segnalazione>()
  riabilitaPannelloSegnalazione = new Subject<void>()

  notifichePerNuovaSegnalazione = new Subject<Segnalazione>()

  notifichePerSegnalazioneAggiornata = new Subject<Segnalazione>()
  notifichePerSegnalazioneInoltrata = new Subject<Segnalazione>()
  notifichePerSegnalazioneDisinoltrata = new Subject<Segnalazione>()

  notificaSalvataggioDocumenti = new Subject<object>()

  notificaDocumentoSalvato = new Subject<object>()

  notificaPerDocumentoAggiunto = new Subject<{
    canale: string;
    documento: Documento;
  }>()
  notificaPerDocumentoEliminato = new Subject<{
    canale: string;
    idDocumento: string;
  }>()
  notificaPerDocumentoAggiornato = new Subject<{
    canale: string;
    documento: Documento;
  }>()

  notificaPerElencoDocumentiDaAggiornare = new Subject<{
    canale: string;
    documenti: Documento[];
  }>()
  notifichePerMessaggioNonLettoSelezionato = new Subject<Messaggio>()

  notificaPerAggiornamentiDirittoSegnalazione =
    new Subject<DirittiSegnalazione>()
  notificaPerAggiornamentoDatiArchiviazione = new Subject<void>()

  notificaPerAggiornamentoFormLavorazione = new Subject<QueryList<NgForm>>()

  notificaFocusInputRicerca = new Subject<void>()

  notificaConsensoAggiornato = new Subject<Consenso>()

  salvaSegnalazioneInLocalStorage = new Subject<void>()

  sottoscritto = false
  sottoscrizioniNotifica: string[] = new Array<string>()
  sottoscrizioniAggiornamenti: string[] = new Array<string>()
  sottoscrizioniInoltri: string[] = new Array<string>()

  // subjectSegnalazioneOmissis = new Subject<Segnalazione>();
  flagVisibilitaSegnalazione = new Subject<{
    idSegnalazione: number;
    idOdv: number;
    visibile: boolean;
  }>()
  notifichePerAggiornamentoProcessoOscuramento = new Subject<OscuramentoDatiManifestamenteNonNecessari>()

  constructor(
    private http: HttpClient,
    private authenticationService: AuthService,
    private documentiService: DocumentiService,
    private sessionData: SessionData
  ) { }

  handleErrorPromise(error: Response | any) {
    console.error(error || error.message)
    return Promise.reject(error.error.message || error)
  }

  recuperaSegnalazioni(
    page: number = 1,
    limit: number = 50,
    stati?: number[],
    searchTerm?: string
  ): Observable<Esito> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());

    if (stati?.length > 0) {
      params = params.set('stati', stati.join(','));
    }

    if (searchTerm) {
      params = params.set('search', searchTerm);
    }

    return this.http.get<Esito>(ServiziRest.urlGetSegnalazioni, { params })
      .pipe(
        timeout(60000),
        tap((esito: Esito) => {
          if (esito.esito === ESITO_OK) {
            const response = JSON.parse(esito.payload);
            this.aggiornaSottoscrizioni(response.segnalazioni);
          }
        })
      );
  }

  recuperaStatisticheProvenienza(
    da: string,
    a: string,
    sorgente: string,
    filtro: string
  ): Promise<Segnalazione[]> {
    return this.http
      .get<Segnalazione[]>(
        ServiziRest.urlCrossOrigin +
        ServiziRest.urlStatisticheProvenienza +
        '/' +
        da +
        '/' +
        a +
        '/' +
        sorgente +
        '/' +
        filtro
      )
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaStatisticheTema(
    da: string,
    a: string,
    tema: string,
    filtro: string
  ): Promise<Segnalazione[]> {
    return this.http
      .get<Segnalazione[]>(
        ServiziRest.urlCrossOrigin +
        ServiziRest.urlStatisticheTema +
        '/' +
        da +
        '/' +
        a +
        '/' +
        tema +
        '/' +
        filtro
      )
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaStatisticheAmmissibilita(
    da: string,
    a: string,
    ammissibilita: string,
    filtro: string
  ): Promise<Segnalazione[]> {
    return this.http
      .get<Segnalazione[]>(
        ServiziRest.urlCrossOrigin +
        ServiziRest.urlStatisticheAmmissibilita +
        '/' +
        da +
        '/' +
        a +
        '/' +
        ammissibilita +
        '/' +
        filtro
      )
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaStatisticheMotiviInammissibilita(
    da: string,
    a: string,
    motivoInammissibilita: string,
    filtro: string
  ): Promise<Segnalazione[]> {
    return this.http
      .get<Segnalazione[]>(
        ServiziRest.urlCrossOrigin +
        ServiziRest.urlStatisticheMotiviInammissibilita +
        '/' +
        da +
        '/' +
        a +
        '/' +
        motivoInammissibilita +
        '/' +
        filtro
      )
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaStatisticheAzioniFollowUp(
    da: string,
    a: string,
    azione: string,
    filtro: string
  ): Promise<Segnalazione[]> {
    return this.http
      .get<Segnalazione[]>(
        ServiziRest.urlCrossOrigin +
        ServiziRest.urlStatisticheAzioniFollowUp +
        '/' +
        da +
        '/' +
        a +
        '/' +
        azione +
        '/' +
        filtro
      )
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  private segnalazioneCache = new Map<number, {data: Segnalazione, timestamp: number}>();
  private readonly CACHE_DURATION = 5000; // 5 secondi
  recuperaSegnalazione(idSegnalazione: number): Observable<Esito> {
    const cached = this.segnalazioneCache.get(idSegnalazione);
    if (cached && Date.now() - cached.timestamp < this.CACHE_DURATION) {
      return of({
        esito: ESITO_OK,
        descrizioneEsito: 'Segnalazione recuperata dalla cache',
        payload: JSON.stringify(cached.data)
      });
    }

    if (this.isFetching) {
      return of({
        esito: ESITO_IN_PROGRESS,
        descrizioneEsito: 'La richiesta è in corso di elaborazione.',
        payload: null,
      });
    }

    this.isFetching = true;

    return this.http.get<Esito>(ServiziRest.urlGetSegnalazioneById + '/' + idSegnalazione).pipe(
      tap((esito: Esito) => {
        this.isFetching = false;
        if (esito.esito === ESITO_OK) {
          // Salva in cache
          this.segnalazioneCache.set(idSegnalazione, {
            data: JSON.parse(esito.payload),
            timestamp: Date.now()
          });
        }
      }),
      catchError((error) => {
        this.isFetching = false;
        return throwError(error);
      })
    );
  }

  recuperaSegnalazioneByAzioneFollowUp(
    da: string,
    a: string,
    azione: string,
    filtro: string
  ): Promise<Segnalazione[]> {
    return this.http
      .get<Segnalazione[]>(
        ServiziRest.urlCrossOrigin +
        ServiziRest.urlGetSegnalazioniByAzioneFollowUp +
        '/' +
        da +
        '/' +
        a +
        '/' +
        azione +
        '/' +
        filtro
      )
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaSegnalazioneByDirittoSegnalazione(
    idDirittiSegnalazione: number
  ): Promise<Esito> {
    return this.http
      .get<Esito>(
        ServiziRest.urlGetSegnalazioniByDirittiSegnalazione +
        '/' +
        idDirittiSegnalazione
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaOmissisByDirittoSegnalazione(
    idDirittiSegnalazione: number
  ): Promise<Esito> {
    return this.http
      .get<Esito>(
        ServiziRest.urlGetOmissisByDirittiSegnalazione +
        '/' +
        idDirittiSegnalazione
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaSegnalazioneByTema(
    da: string,
    a: string,
    tema: string,
    filtro: string
  ): Promise<Segnalazione[]> {
    return this.http
      .get<Segnalazione[]>(
        ServiziRest.urlCrossOrigin +
        ServiziRest.urlGetSegnalazioniByTema +
        '/' +
        da +
        '/' +
        a +
        '/' +
        tema +
        '/' +
        filtro
      )
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaSegnalazioneByProvenienza(
    da: string,
    a: string,
    provenienza: string,
    filtro: string
  ): Promise<Segnalazione[]> {
    return this.http
      .get<Segnalazione[]>(
        ServiziRest.urlCrossOrigin +
        ServiziRest.urlGetSegnalazioniByProvenienza +
        '/' +
        da +
        '/' +
        a +
        '/' +
        provenienza +
        '/' +
        filtro
      )
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaSegnalazioneByAmmissibilita(
    da: string,
    a: string,
    ammissibilita: number,
    filtro: string
  ): Promise<Segnalazione[]> {
    return this.http
      .get<Segnalazione[]>(
        ServiziRest.urlCrossOrigin +
        ServiziRest.urlGetSegnalazioniByAmmissibilita +
        '/' +
        da +
        '/' +
        a +
        '/' +
        ammissibilita +
        '/' +
        filtro
      )
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaSegnalazioneByMotivoInammissibilita(
    da: string,
    a: string,
    motivoInammissibilita: string,
    filtro: string
  ): Promise<Segnalazione[]> {
    return this.http
      .get<Segnalazione[]>(
        ServiziRest.urlCrossOrigin +
        ServiziRest.urlGetSegnalazioniByMotivoInammissibilita +
        '/' +
        da +
        '/' +
        a +
        '/' +
        motivoInammissibilita +
        '/' +
        filtro
      )
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  sbloccaSegnalante(segnalazione: Segnalazione): Promise<Esito> {
    const idSegnalazione = segnalazione.id

    return this.http
      .patch(ServiziRest.urlSbloccaSegnalante + '/' + idSegnalazione, null)
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  getDirittiSegnalazioneById(id: number): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    return this.http
      .get(ServiziRest.urlDirittiSegnalazione + '/' + id, options)
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaRichiesteSblocco(): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    return this.http
      .get(ServiziRest.urlRichiestaSbloccoSegnalante, options)
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaNumeroRichiesteSbloccoDaLavorare(): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    return this.http
      .get(ServiziRest.urlNumeroRichiestaSbloccoSegnalanteDaLavorare, options)
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  richiediSbloccoSegnalante(
    dirittiSegnalazione: DirittiSegnalazione
  ): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    dirittiSegnalazione = decycle(dirittiSegnalazione)
    return this.http
      .post(
        ServiziRest.urlRichiestaSbloccoSegnalante,
        dirittiSegnalazione,
        options
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  aggiornaRichiestaSbloccoSegnalante(
    dirittiSegnalazione: DirittiSegnalazione
  ): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    return this.http
      .patch(
        ServiziRest.urlRichiestaSbloccoSegnalante,
        dirittiSegnalazione,
        options
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  sbloccaSegnalato(segnalazione: Segnalazione): Promise<Esito> {
    const idSegnalazione = segnalazione.id

    return this.http
      .patch(ServiziRest.urlSbloccaSegnalato + '/' + idSegnalazione, null)
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  salvaSegnalazione(segnalazione: Segnalazione): Promise<Esito> {
    const allegati = segnalazione.documenti
    segnalazione.documenti = undefined
    segnalazione.autoreUltimoAggiornamento =
      this.authenticationService.getUser()
    const nuova = !segnalazione.id

    segnalazione = decycle(segnalazione)

    return this.http
      .post<Esito>(ServiziRest.urlNuovaSegnalazione, segnalazione)
      .toPromise()
      .then((response: Esito) => {
        if (response.esito === ESITO_OK) {
          segnalazione = JSON.parse(response.payload)
          this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)

          if (nuova) {
            this.sottoscriviPerAggiornamentiAllaSegnalazione(segnalazione)
            this.sottoscriviPerModificheDirittiSegnalazione(
              segnalazione.dirittiSegnalazioneOdv,
              segnalazione.id
            )
          }
          this.notifichePerNuovaSegnalazioneSoloLocale.next(segnalazione)

          for (let i = 0; allegati !== undefined && i < allegati.length; i++) {
            this.documentiService
              .collegaDocumentoASegnalazione(allegati[i], segnalazione)
              .then((esito: Esito) => {
                if (esito.esito === ESITO_OK) {
                  const docSalvato: Documento = JSON.parse(esito.payload)
                  this.notificaDocumentoSalvato.next(docSalvato)
                } else {
                  console.error(
                    'salvataggio allegato in errore: ' + esito.descrizioneEsito
                  )
                  return esito
                }
              })
              .catch(this.handleErrorPromise)
          }
          return response
        }
      })
      .catch(this.handleErrorPromise)
  }

  inoltraSegnalazioneOppureAggiornaInoltro(
    idLavorazione: number,
    idSegnalazione: number,
    idMittente: number,
    idDestinatario: number,
    consultazioneSegnalazione: number,
    consultazioneSegnalazioneSospesa: number,
    consultazioneLavorazione: number,
    modificaLavorazione: number,
    aperturaLavorazioni: number,
    comunicazioneConSegnalante: number,
    consultaNomeSegnalante: number,
    consultaNomiSegnalati: number
  ): Promise<Esito> {
    return this.http
      .patch<Esito>(
        ServiziRest.urlInoltraSegnalazione +
        '/' +
        idLavorazione +
        '/' +
        idSegnalazione +
        '/' +
        idMittente +
        '/' +
        idDestinatario +
        '/' +
        consultazioneSegnalazione +
        '/' +
        consultazioneSegnalazioneSospesa +
        '/' +
        consultazioneLavorazione +
        '/' +
        modificaLavorazione +
        '/' +
        aperturaLavorazioni +
        '/' +
        comunicazioneConSegnalante +
        '/' +
        consultaNomeSegnalante +
        '/' +
        consultaNomiSegnalati,
        null
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  disinoltraSegnalazione(
    idLavorazione: number,
    idSegnalazione: number,
    idDestinatario: number,
    idOdvCheHaInoltrato: number
  ): Promise<Esito> {
    return this.http
      .patch<Esito>(
        ServiziRest.urlDisinoltraSegnalazione +
        '/' +
        idLavorazione +
        '/' +
        idSegnalazione +
        '/' +
        idDestinatario +
        '/' +
        idOdvCheHaInoltrato,
        null
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  ripristinaInoltro(
    idDiritto: number
  ): Promise<Esito> {
    return this.http
      .patch<Esito>(
        ServiziRest.urlRipristinaInoltro +
        '/' +
        idDiritto,
        null
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaDirittiSegnalazioneOdv(idSegnalazione: number): Promise<Esito> {
    // se idSegnalazione non è un numero valido (NaN) allora non eseguo la chiamata
    return this.http
      .get<Esito>(ServiziRest.urlGetDirittiSegnalazione + '/' + idSegnalazione)
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  async aggiornaVisibilitaSegnalazione(
    idDirittiSegnalazione: number,
    consultazioneSegnalazioneSospesa: number,
    idOdv: number,
    idSegnalazione: number
  ): Promise<Esito> {
    return this.http
      .patch<Esito>(
        ServiziRest.urlDirittiSegnalazione +
        '/aggiornaVisibilitaTemporaneaSegnalazione' +
        '/' +
        idDirittiSegnalazione +
        '/' +
        consultazioneSegnalazioneSospesa +
        '/' +
        idOdv +
        '/' +
        idSegnalazione,
        null
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async aggiornaAperturaLavorazioni(
    idDirittiSegnalazione: number,
    aperturaLavorazioni: number
  ): Promise<Esito> {
    return this.http
      .patch<Esito>(
        ServiziRest.urlDirittiSegnalazione +
        '/aggiornaAperturaLavorazioni' +
        '/' +
        idDirittiSegnalazione +
        '/' +
        aperturaLavorazioni,
        null
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async aggiornaComunicazioneConSegnalante(
    idDirittiSegnalazione: number,
    comunicazioneConSegnalante: number
  ): Promise<Esito> {
    return this.http
      .patch<Esito>(
        ServiziRest.urlDirittiSegnalazione +
        '/aggiornaComunicazioneConSegnalante' +
        '/' +
        idDirittiSegnalazione +
        '/' +
        comunicazioneConSegnalante,
        null
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async aggiornaVisibilitaSegnalati(
    idDirittiSegnalazione: number,
    visibilitaSegnalati: number
  ): Promise<Esito> {
    return this.http
      .patch<Esito>(
        ServiziRest.urlDirittiSegnalazione +
        '/aggiornaVisibilitaSegnalati' +
        '/' +
        idDirittiSegnalazione +
        '/' +
        visibilitaSegnalati,
        null
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async aggiornaAscoltoAudio(
    idDirittiSegnalazione: number,
    ascoltoAudio: number
  ): Promise<Esito> {
    return this.http
      .patch<Esito>(
        ServiziRest.urlDirittiSegnalazione +
        '/aggiornaAscoltoAudio' +
        '/' +
        idDirittiSegnalazione +
        '/' +
        ascoltoAudio,
        null
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async aggiornaStatoCondivisione(
    idDirittiSegnalazione: number,
    statoCondivisione: number
  ): Promise<Esito> {
    return this.http
      .patch<Esito>(
        ServiziRest.urlDirittiSegnalazione +
        '/aggiornaStatoCondivisione' +
        '/' +
        idDirittiSegnalazione +
        '/' +
        statoCondivisione,
        null
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async aggiornaVisibilitaSegnalante(
    idDirittiSegnalazione: number,
    visibilitaSegnalante: number
  ): Promise<Esito> {
    return this.http
      .patch<Esito>(
        ServiziRest.urlDirittiSegnalazione +
        '/aggiornaVisibilitaSegnalante' +
        '/' +
        idDirittiSegnalazione +
        '/' +
        visibilitaSegnalante,
        null
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  // async aggiornaOmissisDirittiSegnalazione(omissis: Omissis): Promise<Esito> {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //   });
  //   const options = { headers: headers };
  //   return this.http.patch<Esito>(ServiziRest.urlAggiornaOmissisDirittiSegnalazione,
  //     omissis, options).toPromise()
  //     .then((response: Esito) => {
  //       return response;
  //     })
  //     .catch(this.handleErrorPromise);

  // }

  async getInformative(): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    return this.http
      .get<Esito>(ServiziRest.urlInformativa, options)
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async getInformativaById(id: number): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    return this.http
      .get<Esito>(ServiziRest.urlInformativa + '/' + id, options)
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async getInformativaByIdConsenso(id: number): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    return this.http
      .get<Esito>(ServiziRest.urlInformativa + '/byIdConsenso/' + id, options)
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async salvaInformativa(informativa: Informativa): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    informativa = decycle(informativa)
    return this.http
      .post<Esito>(ServiziRest.urlInformativa, informativa, options)
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  aggiungiOmissisDirittiSegnalazione(omissis: Omissis): Observable<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    omissis = decycle(omissis)
    return this.http.post<Esito>(
      ServiziRest.urlOmissis,
      omissis,
      options
    )
  }

  async eliminaOmissisDirittiSegnalazione(omissis: Omissis): Promise<Esito> {
    return this.http
      .delete<Esito>(
        ServiziRest.urlOmissis + '/' + omissis.id
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaTestoInChiaroOmissis(omissis: Omissis): Observable<Esito> {
    return this.http
      .get<Esito>(
        ServiziRest.urlOmissis + '/testoInChiaro/' + omissis.id
      )
  }

  async salvaConsenso(consenso: Consenso): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    consenso = decycle(consenso)
    return this.http
      .post<Esito>(ServiziRest.urlConsenso, consenso, options)
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async inoltraConsenso(idConsenso: number): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    return this.http
      .patch<Esito>(ServiziRest.urlConsenso + '/inoltra/' + idConsenso, options)
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async annullaRichiestaConsenso(idConsenso: number): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    return this.http
      .patch<Esito>(ServiziRest.urlConsenso + '/annulla/' + idConsenso, options)
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async autorizzaConsenso(idConsenso: number): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    return this.http
      .patch<Esito>(
        ServiziRest.urlConsenso + '/autorizza/' + idConsenso,
        options
      )
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async negaConsenso(idConsenso: number): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    const options = { headers: headers }
    return this.http
      .patch<Esito>(ServiziRest.urlConsenso + '/nega/' + idConsenso, options)
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  consensiDaAutorizzare(): Observable<Esito> {
    return this.http.get<Esito>(ServiziRest.urlConsensDaAutorizzare)
  }

  recuperaGruppiGestori(): Promise<Esito> {
    return this.http
      .get<Esito>(ServiziRest.urlCrossOrigin + ServiziRest.urlDestinatari)
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaPersonaSegnalante(idSegnalazione: number): Promise<Esito> {
    return this.http
      .get<Esito>(
        ServiziRest.urlGetSegnalazione + '/personaSegnalante/' + idSegnalazione
      )
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaDatiArchiviazione(idSegnalazione: number): Promise<Esito> {
    return this.http
      .get<Esito>(
        ServiziRest.urlGetSegnalazione + '/datiArchiviazione/' + idSegnalazione
      )
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  dataOraUltimoRecuperoGruppiGestoriPerAperturaSegnalazione: Date
  stringaUltimoRecuperoGruppiGestoriPerAperturaSegnalazione: string

  recuperaGruppiGestoriPerAperturaSegnalazione(): Promise<Esito> {
    // se ho già recuperato i gruppi gestori per apertura segnalazione negli ultimi 5 secondi allora non faccio nulla e ritorno semplicemente i gruppi gestori
    if (this.dataOraUltimoRecuperoGruppiGestoriPerAperturaSegnalazione &&
      this.stringaUltimoRecuperoGruppiGestoriPerAperturaSegnalazione &&
      new Date().getTime() - this.dataOraUltimoRecuperoGruppiGestoriPerAperturaSegnalazione.getTime() < 1000 * 5) {
      return Promise.resolve({
        esito: ESITO_OK,
        descrizioneEsito: 'Gruppi gestori recuperati con successo',
        payload: this.stringaUltimoRecuperoGruppiGestoriPerAperturaSegnalazione
      })
    }
    // aggiorno la data dell'ultimo recupero
    this.dataOraUltimoRecuperoGruppiGestoriPerAperturaSegnalazione = new Date()
    // altrimenti recupero i gruppi gestori e li salvo nella mappa
    return this.http
      .get<Esito>(
        ServiziRest.urlCrossOrigin +
        ServiziRest.urlDestinatariAperturaSegnalazione
      )
      .pipe(
        tap(esito => {
          this.stringaUltimoRecuperoGruppiGestoriPerAperturaSegnalazione = esito.payload
        })
      )
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  recuperaGruppiGestoriByName(testo: string): Promise<Esito> {
    return this.http
      .get<Esito>(
        ServiziRest.urlCrossOrigin +
        ServiziRest.urlDestinatariByNome +
        '/' +
        testo
      )
      .toPromise()
      .then((response) => response)
      .catch(this.handleErrorPromise)
  }

  sottoscriviPerAggiornamentiProcessoOscuramento = (processoOscuramento: OscuramentoDatiManifestamenteNonNecessari) => {

    if (!this.sottoscrizioniAggiornamenti.includes('aggiornamento-processo-oscuramento-' + processoOscuramento.id)) {

      GlobalVars.socket.on('aggiornamento-processo-oscuramento-' + processoOscuramento.id,
        () => {
          console.log(
            'Ricevuto aggiornamento del processo di oscuramento: ' +
            processoOscuramento.id
          )
          this.notifichePerAggiornamentoProcessoOscuramento.next(
            processoOscuramento
          )
        })
      this.sottoscrizioniAggiornamenti.push('aggiornamento-processo-oscuramento-' + processoOscuramento.id)
    }
  }

  sottoscriviPerInoltriEDisinoltriSegnalazioni() {

    // console.log('Sottoscrivo per inoltri segnalazione: ' + segnalazione.id);
    this.authenticationService.getUser().odvMembership.forEach((gruppoOdv) => {
      if (
        !this.sottoscrizioniInoltri.includes(
          'segnalazione-inoltrata-' + gruppoOdv.id
        )
      ) {
        // console.log('Sottoscrivo per segnalazione-inoltrata-' + gruppoOdv.id)
        GlobalVars.socket.on(
          'segnalazione-inoltrata-' + gruppoOdv.id,
          (segnalazione: Segnalazione) => {
            console.log(
              'Ricevuto inoltro della segnalazione: ' + segnalazione.id
            )
            if (
              this.sessionData.getSegnalazioneSelezionata() &&
              +this.sessionData.getSegnalazioneSelezionata().id === +segnalazione.id
            ) {
              this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
            } else {
              const indice = this.sessionData.segnalazioni.findIndex(
                (segna) => +segna.id === +segnalazione.id
              )
              if (indice !== -1) {
                this.sessionData.segnalazioni.splice(indice, 1, segnalazione)
              } else {
                this.sessionData.segnalazioni.unshift(segnalazione)
              }
            }
            this.notifichePerSegnalazioneInoltrata.next(segnalazione)
          }
        )
        this.sottoscrizioniInoltri.push(
          'segnalazione-inoltrata-' + gruppoOdv.id
        )
      } else {
        console.log(
          'Sono già sottoscritto per segnalazione-inoltrata-' + gruppoOdv.id
        )
      }
    })

    this.authenticationService.getUser().odvMembership.forEach((gruppoOdv) => {
      if (
        !this.sottoscrizioniInoltri.includes(
          'segnalazione-disinoltrata-' + gruppoOdv.id
        )
      ) {
        GlobalVars.socket.on(
          'segnalazione-disinoltrata-' + gruppoOdv.id,
          (idSegnalazione: number) => {
            // console.log('Ricevuto inoltro della segnalazione: ' + segnalazione.id);
           // individuo la segnalazione in sessionData con lo stesso id
            const segnalazioneInSessionData = this.sessionData.segnalazioni.find(s => +s.id === +idSegnalazione)
            if (segnalazioneInSessionData) {
              // elimino i diritti segnalazione odv con destinatario uguale a gruppoOdv.id
              segnalazioneInSessionData.dirittiSegnalazioneOdv = segnalazioneInSessionData.dirittiSegnalazioneOdv.filter(dir => dir.odvDestinatario.id !== gruppoOdv.id)
              this.sessionData.nuovaSegnalazioneSelezionata(segnalazioneInSessionData)
              this.notifichePerSegnalazioneDisinoltrata.next(segnalazioneInSessionData)
            }

          }
        )
        this.sottoscrizioniInoltri.push(
          'segnalazione-disinoltrata-' + gruppoOdv.id
        )
      }
    })

    this.authenticationService.getUser().odvMembership.forEach((gruppoOdv) => {
      if (
        !this.sottoscrizioniInoltri.includes(
          'segnalazione-nascosta-' + gruppoOdv.id
        )
      ) {
        GlobalVars.socket.on(
          'segnalazione-nascosta-' + gruppoOdv.id,
          (idSegnalazione: number) => {
            // console.log('Ricevuto inoltro della segnalazione: ' + segnalazione.id);
            if (
              this.sessionData.getSegnalazioneSelezionata() &&
              +this.sessionData.getSegnalazioneSelezionata().id === idSegnalazione
            ) {
              this.sessionData.nuovaSegnalazioneSelezionata(undefined)
            }

            this.flagVisibilitaSegnalazione.next({
              idSegnalazione: idSegnalazione,
              idOdv: gruppoOdv.id,
              visibile: false,
            })
          }
        )
        this.sottoscrizioniInoltri.push(
          'segnalazione-nascosta-' + gruppoOdv.id
        )
      }
    })

    this.authenticationService.getUser().odvMembership.forEach((gruppoOdv) => {
      if (
        !this.sottoscrizioniInoltri.includes(
          'segnalazione-visibile-' + gruppoOdv.id
        )
      ) {
        GlobalVars.socket.on(
          'segnalazione-visibile-' + gruppoOdv.id,
          (idSegnalazione: number) => {
            // console.log('Ricevuto inoltro della segnalazione: ' + segnalazione.id);
            this.flagVisibilitaSegnalazione.next({
              idSegnalazione: idSegnalazione,
              idOdv: gruppoOdv.id,
              visibile: true,
            })
          }
        )
        this.sottoscrizioniInoltri.push(
          'segnalazione-visibile-' + gruppoOdv.id
        )
      }
    })
  }

  sottoscriviPerAggiornamentiAllaSegnalazione = (segnalazione) => {
    if (
      !this.sottoscrizioniAggiornamenti.includes(segnalazione.id.toString())
    ) {
      // console.log('Sottoscrivo per aggiornamenti per segnalazione: ' + segnalazione.id)
      GlobalVars.socket.on(
        'segnalazione-aggiornata-' + segnalazione.id,
        (segnalazione1: Segnalazione) => {
          console.log(
            'Arrivato aggiornamento per segnalazione: ' + segnalazione1.id
          )
          if (
            this.sessionData.getSegnalazioneSelezionata() &&
            this.sessionData.getSegnalazioneSelezionata().id === segnalazione1.id
          ) {
            this.sessionData.nuovaSegnalazioneSelezionata(segnalazione1)
          }
          // this.aggiornaElencoSegnalazioni(segnalazione);
          this.notifichePerSegnalazioneAggiornata.next(segnalazione1)
        }
      )
      this.sottoscrizioniAggiornamenti.push(segnalazione.id.toString())
    }
  }

  aggiornaElencoSegnalazioni(segnalazione: Segnalazione): any {
    const indice = this.sessionData.segnalazioni.findIndex(
      (tmpsegnalazione) => tmpsegnalazione.id === segnalazione.id
    )
    if (indice !== -1) {
      this.sessionData.segnalazioni.splice(indice, 1, segnalazione)
    }
  }

  aggiornaSottoscrizioni(segnalazioni: Segnalazione[]) {
    this.sottoscriviPerAggiornamentiAlleSegnalazioni(segnalazioni)
  }

  sottoscriviPerAggiornamentiAlleSegnalazioni(
    segnalazioni: Segnalazione[]
  ): any {

    segnalazioni.forEach((segnalazione) => {
      if (
        !this.sottoscrizioniAggiornamenti.includes(segnalazione.id.toString())
      ) {
        this.sottoscriviPerAggiornamentiAllaSegnalazione(segnalazione)
        this.sottoscriviPerModificheDirittiSegnalazione(
          segnalazione.dirittiSegnalazioneOdv,
          segnalazione.id
        )
      }
    })

    if (!this.sottoscrizioniNotifica.includes('nuova-segnalazione')) {
      // console.log(`Sottoscrivo per notifiche in caso di creazione nuove segnalazioni`);
      GlobalVars.socket.on(
        'nuova-segnalazione',
         (segnalazione: Segnalazione) => {
          //  @ToDo inserire controllo anche su nuova associazione odvDestinatariInoltro e portare su funzione esterna
          let dirittiReclamabili = segnalazione.dirittiSegnalazioneOdv.filter(
            (diritti) =>
              this.authenticationService
                .getUser()
                .odvMembership.find(
                  (gruppo) => +gruppo.id === +diritti.odvDestinatario.id
                  // && diritti.odvDestinatarioInConflittoDiInteressi !== true
                ) !== undefined
          )
          // escludi quelli dove odvDestinatarioInConflittoDiInteressi === true
          dirittiReclamabili = dirittiReclamabili.filter(
            (diritti) => diritti.odvDestinatarioInConflittoDiInteressi !== true
          )
          if (dirittiReclamabili.length > 0) {
            console.log(
              'Arrivata notifica per nuova segnalazione che mi riguarda! ID:' +
              segnalazione.id +
              ' - oggetto:' +
              segnalazione.oggetto
            )
            this.notifichePerNuovaSegnalazione.next(segnalazione)
            // that.sottoscriviPerNotificheAllaSegnalazione(segnalazione);
            this.sottoscriviPerAggiornamentiAllaSegnalazione(segnalazione)
            this.sottoscriviPerModificheDirittiSegnalazione(
              segnalazione.dirittiSegnalazioneOdv,
              segnalazione.id
            )
          } else {
            // console.log('Arrivata notifica per nuova segnalazione ma non mi riguarda!');
          }
        }
      )
      this.sottoscrizioniNotifica.push('nuova-segnalazione')
    }
    if (
      !this.sottoscrizioniNotifica.includes('segnalazione-inoltrata') &&
      this.authenticationService.getUser()?.odv
    ) {
      // console.log(`Sottoscrivo per notifiche in caso di segnalazioni inoltrate`);
      GlobalVars.socket.on(
        'segnalazione-inoltrata',
        (segnalazione: Segnalazione) => {
          // console.log(msg);
          //  @ToDo inserire controllo anche su nuova associazione odvDestinatariInoltro e portare su funzione esterna
          const exists = segnalazione.dirittiSegnalazioneOdv.some(
            (diritti) =>
              this.authenticationService
                .getUser()
                .odvMembership.find(
                  (gruppo) => gruppo.id === diritti.odvDestinatario.id
                ) !== undefined
          )
          if (exists) {
            console.log(
              'Arrivata notifica per aggiornamenti ad una segnalazione che mi riguarda! ID:' +
              segnalazione.id +
              ' - oggetto:' +
              segnalazione.oggetto
            )
            this.notifichePerNuovaSegnalazione.next(segnalazione)
            this.sottoscriviPerAggiornamentiAllaSegnalazione(segnalazione)
            this.sottoscriviPerModificheDirittiSegnalazione(
              segnalazione.dirittiSegnalazioneOdv,
              segnalazione.id
            )
          } else {
            // console.log('Arrivata notifica per nuova segnalazione ma non mi riguarda!');
          }
        }
      )
      this.sottoscrizioniNotifica.push('segnalazione-inoltrata')
    }
  }

  async sottoscriviPerModificheDirittiSegnalazione(
    dirittiSegnalazione: DirittiSegnalazione[],
    idSegnalazione
  ) {
    dirittiSegnalazione?.forEach(() => {
      GlobalVars.socket.on(
        'diritto-segnalazione-aggiornato-' + idSegnalazione,
        (miodiritto: DirittiSegnalazione) => {
          this.notificaPerAggiornamentiDirittoSegnalazione.next(miodiritto)
        }
      )
    })
  }

  salvaGruppoOdv(gruppo: Odv): Promise<Esito> {
    gruppo = decycle(gruppo)
    return this.http
      .post<Esito>(ServiziRest.urlDestinatari, gruppo)
      .toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  getDestinatariPredefinitiPerTipologiaSegnalazione(
    tipologiaSegnalazione: string
  ): Observable<Esito> {
    const parametroCodificato = encodeURIComponent(tipologiaSegnalazione)
    return this.http.get<Esito>(
      ServiziRest.urlDestinatariPredefinitiPerTipologiaSegnalazione +
      '/' +
      parametroCodificato
    )
  }

  getDestinatariPerSocieta(societa: Azienda): Observable<Esito> {
    return this.http.get<Esito>(
      ServiziRest.urlDestinatariPredefinitiPerSocieta + '/' + societa.id
    )
  }

  salvaDestinatariPerTipologiaSegnalazione(
    destinatari: DestinatariPerTipologia
  ): Observable<Esito> {
    console.log('salvaDestinatariPerTipologiaSegnalazione', destinatari)
    destinatari = decycle(destinatari)
    return this.http.post<Esito>(
      ServiziRest.urlDestinatariPredefinitiPerTipologiaSegnalazione,
      destinatari
    )
  }

  salvaDestinatariPerAzienda(
    azienda: Azienda,
    odv: Odv,
    predefinito: boolean
  ): Observable<Esito> {
    return this.http.post<Esito>(
      ServiziRest.urlDestinatariPredefinitiPerSocieta +
      '/' +
      azienda.id +
      '/' +
      odv.id +
      '/' +
      predefinito,
      azienda
    )
  }

  rimuoviDestinatariPerTipologiaSegnalazione(
    destinatario: DestinatariPerTipologia
  ): Observable<Esito> {
    return this.http.delete<Esito>(
      ServiziRest.urlDestinatariPredefinitiPerTipologiaSegnalazione +
      '/' +
      destinatario.id
    )
  }

  rimuoviDestinatariPerSocieta(
    destinatarioId: string,
    societa: Azienda
  ): Observable<Esito> {
    return this.http.delete<Esito>(
      ServiziRest.urlDestinatariPredefinitiPerSocieta +
      '/' +
      destinatarioId +
      '/' +
      societa.id
    )
  }

  inibisciUtentePerSegnalazione(
    idSegnalazione: number,
    idUtente: number,
    inibisci: boolean
  ): Observable<Esito> {
    return this.http.post<Esito>(
      ServiziRest.urlUtentiInibiti +
      '/' +
      idSegnalazione +
      '/' +
      idUtente +
      '/' +
      inibisci,
      null
    )
  }

  recuperaUtentiInibitiPerSegnalazione(
    idSegnalazione: number
  ): Observable<Esito> {
    return this.http.get<Esito>(
      ServiziRest.urlUtentiInibiti + '/' + idSegnalazione
    )
  }

  ritiraSegnalazione(
    idSegnalazione: number,
    ritira: boolean
  ): Observable<Esito> {
    return this.http.post<Esito>(
      ServiziRest.urlRitiraSegnalazione + '/' + idSegnalazione + '/' + ritira,
      null
    )
  }

  sospendiCancellazione(idSegnalazione: number, sospendi: boolean): Observable<Esito> {
    return this.http.post<Esito>(
      ServiziRest.urlGetSegnalazioni + '/sospendiCancellazione/' + idSegnalazione + '/' + sospendi,
      null
    )
  }

  aggiornaPianificazioneCancellazione(idSegnalazione: number, cancella: boolean): Observable<Esito> {
    return this.http.patch<Esito>(
      ServiziRest.urlPianificazioneCancellazioneSegnalazione + '/' + idSegnalazione + '/' + cancella,
      null
    )
  }
}

export interface NotificaMessaggioLetto {
  idMessaggio: string;
  idUtente: string;
  idSegnalazione: string;
}
