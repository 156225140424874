import { HttpClient } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { Chat } from '../modello/chat'
import { ChatMessageInfo } from '../modello/chat-message-info'
import { Messaggio } from '../modello/messaggio'
import { Segnalazione } from '../modello/segnalazione'
import { AuthService } from '../servizi/auth.service'
import { ChatService } from '../servizi/chat.service'
import { SegnalazioneService } from '../servizi/segnalazione.service'
import { SessionData } from '../sessione/dati-sessione'
import { Esito, ESITO_OK } from '../utility/esito'
import { membershipDaGestorePerSegnalazione } from '../utility/helper-segnalazioni'
import { GlobalVars, ServiziRest } from '../utility/utility'

@Injectable({ providedIn: 'root' })
export class MessaggiStore implements OnDestroy {
  sottoscrizioneOnRead: unknown
  messaggiNonLetti: Messaggio[] = []
  messaggiNonLettiAggiornati = new Subject<Messaggio[]>()
  messaggioLettoAggiornato = new Subject<Messaggio>()

  notifichePerInvitoInChat = new Subject()
  notifichePerInvitoInChatGruppo = new Subject()

  sottoscrizioniInoltri: string[] = new Array<string>()


  chatSelezionata: Chat

  messaggiChatSelezionata: Messaggio[] = []
  messaggiChatSelezionataAggiornati = new Subject<Messaggio[]>()

  private socketSubscriptions = new Map()
  sottoscrizioneDisinoltri: Subscription

  constructor(private chatService: ChatService,
    private authService: AuthService,
    private sessionData: SessionData,
    private segnalazioneService: SegnalazioneService,
    private http: HttpClient) {
  }

  public onInit() {
    console.log('dentro onInit messaggistore....')
    this.initMessageStore()

  }

  initMessageStore() {
    this.impostaSottoscrizioniChat()
    this.messaggiNonLetti = []
    this.inizializzaStoreMessaggiNonLetti()
    this.sottoscriviPerDisinoltriSegnalazione()
  }

  ngOnDestroy() {
    // Rimuovere tutte le sottoscrizioni
    this.rimuoviTutteLeSottoscrizioni()
    this.sottoscrizioneDisinoltri?.unsubscribe()
  }

  resetChatSelezionata() {
    this.messaggiChatSelezionata = [];
    this.messaggiChatSelezionataAggiornati.next([]);
  }

  setChatSelezionata(chat: Chat) {
    // Reset solo se è cambiata effettivamente la chat
    if (!this.chatSelezionata || this.chatSelezionata.id !== chat.id) {
      this.resetChatSelezionata();
      this.chatSelezionata = chat;
    }
  }

  rimuoviTutteLeSottoscrizioni() {
    this.socketSubscriptions.forEach((listener, eventKey) => {
      GlobalVars.socket.off(eventKey, listener)
    })
    this.socketSubscriptions.clear()
  }

  inizializzaStoreMessaggiNonLetti() {
    this.chatService.recuperaMessaggiNonLetti().then(async (esito: Esito) => {
      if (esito.esito === ESITO_OK) {
        const messaggi = JSON.parse(esito.payload)
        // cicla sui messaggi e invoca il servizio per aggiornare lo stato dei messaggi
        for (const messaggio of messaggi) {
          // pausa di 0.1 secondi per evitare di sovraccaricare il server
          await new Promise(resolve => setTimeout(resolve, 100))
          this.aggiornaStatoMessaggioRicevuto(messaggio)
        }
        this.aggiungiMessaggiNonLetti(messaggi)
      } else {
        throw new Error(esito.payload)
      }
    })
      .catch((errore: Error) => {
        console.error(errore)
      })
  }

  findChatMessageInfo(messaggio: Messaggio, id: number): ChatMessageInfo | undefined {
    return messaggio.statoInvii?.find(c => c.id === id)
  }

  aggiornaStatoMessaggioRicevuto(messaggio: Messaggio) {
    const cmi1 = this.findChatMessageInfo(messaggio, +this.authService.getUserPk())
    if (cmi1 && cmi1.read && cmi1.delivered) {
      console.log('stato invii messaggio ' + messaggio.id + ' già aggiornato')
      return
    }

    this.chatService.messaggioRicevuto(messaggio).subscribe((esito: Esito) => {
      if (esito.esito === ESITO_OK) {
        const cmi = JSON.parse(esito.payload) as ChatMessageInfo
        const cmiLocale = this.findChatMessageInfo(messaggio, cmi.id)

        if (cmiLocale) {
          cmiLocale.delivered = cmi.delivered
          cmiLocale.read = cmi.read
        } else {
          messaggio.statoInvii = messaggio.statoInvii ?? []
          messaggio.statoInvii.push(cmi)
        }
      } else {
        // elimino il messaggio
        this.messaggiNonLetti = this.messaggiNonLetti.filter(mess => mess.id !== messaggio.id)
        this.messaggiNonLettiAggiornati.next(this.messaggiNonLetti.slice())
        console.error('Non è stato possibile aggiornare lo stato del messaggio ' + messaggio.id, esito.payload)
      }
    }, (errore: Error) => {
      console.error(errore)
      // Handle the error in a meaningful way here
    })
  }

  async caricaAltriMessaggi(idChat: number) {
    try {
      const esito = await this.chatService.recuperaMessaggiByChat(idChat, 6, this.messaggiChatSelezionata.length)
      if (esito.esito === ESITO_OK) {
        const messaggi = JSON.parse(esito.payload)
        if (messaggi) {
          this.aggiungiMessaggiAChatSelezionata(messaggi)
        }
      } else {
        console.error('Non è stato possibile recuperare le chat collegate alla segnalazione.', esito.payload)
      }
    } catch (error) {
      console.error('Non è stato possibile recuperare le chat collegate alla segnalazione.', error)
    }
  }

  caricaMessaggiNonLetti() {
    this.chatService.recuperaMessaggiNonLetti().then((esito: Esito) => {
      if (esito.esito === ESITO_OK) {
        const messaggi = JSON.parse(esito.payload)
        this.messaggiNonLetti = []
        this.aggiungiMessaggiNonLetti(messaggi)
      } else {
        throw new Error(esito.payload)
      }
    })
      .catch((errore: Error) => {
        console.error(errore)
      })
  }

  aggiungiMessaggioNonLetto(messaggio: Messaggio) {
    this.aggiornaStatoMessaggioRicevuto(messaggio)
    this.messaggiNonLetti.push(messaggio)
    this.messaggiNonLettiAggiornati.next(this.messaggiNonLetti.slice())

  }

  aggiungiMessaggiNonLetti(messaggi: Messaggio[]) {
    this.messaggiNonLetti = [...this.messaggiNonLetti, ...messaggi]
    this.messaggiNonLettiAggiornati.next(this.messaggiNonLetti.slice())
  }

  // messaggioLetto(messaggio: Messaggio) {
  //   messaggio.letto = true
  //   this.aggiornaStatoMessaggioLetto(messaggio)
  //   this.aggiornaMessaggioChatSelezionata(messaggio)


  // }

  aggiornaStatoMessaggioLetto(messaggio: Messaggio) {
    // invoca il servizio per aggiornare lo stato del messaggio
    this.chatService.messaggioLetto(messaggio).subscribe((esito: Esito) => {
      if (esito.esito === ESITO_OK) {
        // ricevo il ChatMessageInfo aggiornato
        const cmi = JSON.parse(esito.payload) as ChatMessageInfo[]
        // aggiorno lo stato del chatmessageinfo cercando tra i chat message info quello con id uguale
        const cmiLocale = messaggio.statoInvii?.find(c => cmi?.find(c1 => c1.id === c.id) !== undefined)
        // se non c'è aggiungo direttamente quello ricevuto altrimenti aggiorno quello locale
        if (cmiLocale) {
          cmiLocale.delivered = cmi.find(c => c.id === cmiLocale.id).delivered
          cmiLocale.read = cmi.find(c => c.id === cmiLocale.id).read
          console.log('chatcompo - ********** notifico la lettura del messaggio ' + messaggio.id + ' ****************************')
          GlobalVars.socket.emit('read', this.sessionData.getSegnalazioneSelezionata().id, this.authService.getUserPk(), messaggio.id)

        } else {
          if (!messaggio.statoInvii) {
            messaggio.statoInvii = []
          }
          messaggio.statoInvii.push(...cmi)
        }
        console.log('stato invii messaggio ' + messaggio.id + ' aggiornato')
        this.messaggioLettoAggiornato.next(messaggio)
        this.messaggiNonLetti = this.messaggiNonLetti.filter(mess => mess.id !== messaggio.id)
        this.messaggiNonLettiAggiornati.next(this.messaggiNonLetti.slice())
      } else {
        console.error('Non è stato possibile aggiornare lo stato del messaggio ' + messaggio.id, esito.payload)
      }
    })
  }

  messaggioLettoId(messaggioId: number, userId?: number) {
    const messaggio = this.messaggiNonLetti.find(mess => +mess.id === messaggioId)
    if (messaggio) {
      // trova il chatmessageinfo relativo all'utente che ha letto il messaggio
      const cmi = messaggio.statoInvii?.find(c => +c.utente.id === +userId)
      cmi.read = true
      cmi.delivered = true
      this.messaggiNonLetti = this.messaggiNonLetti.filter(mess => +mess.id !== messaggioId)
      this.messaggiNonLettiAggiornati.next(this.messaggiNonLetti.slice())
      this.messaggioLettoAggiornato.next(messaggio)
      // this.aggiornaStatoMessaggioLetto(messaggio)
    }
    const messaggioChatSelezionata = this.messaggiChatSelezionata.find(mess => +mess.id === messaggioId)
    if (messaggioChatSelezionata) {
      messaggioChatSelezionata.letto = true
      // trova il chatmessageinfo relativo all'utente che ha letto il messaggio
      const cmi = messaggioChatSelezionata.statoInvii?.find(c => +c.utente.id === +userId)
      cmi.read = true
      cmi.delivered = true
      // this.aggiornaMessaggioChatSelezionata(messaggioChatSelezionata)
      this.messaggioLettoAggiornato.next(messaggioChatSelezionata)
    }
  }

  aggiungiMessaggioAChatSelezionata(messaggio: Messaggio) {
    // Mi accerto che il messaggiiio sia relativo alla chat selezionata
    if (+messaggio.chat.id === +this.chatSelezionata.id) {
      this.messaggiChatSelezionata.push(messaggio)
      this.messaggiChatSelezionataAggiornati.next(this.messaggiChatSelezionata.slice())
    }
  }

  aggiungiMessaggiAChatSelezionata(messaggi: Messaggio[]) {
    //  escludo dopppioni se ce ne sono
    messaggi = messaggi.filter(mess => this.messaggiChatSelezionata.find(m => m.id === mess.id) === undefined)
    // escludo eventuali messaggi non relativi alla chat selezionata
    messaggi = messaggi.filter(mess => +mess.chat?.id === +this.chatSelezionata?.id)
    console.log('[messaggi] aggiungi messaggiAChatSelezionata', messaggi)
    this.messaggiChatSelezionata = [...this.messaggiChatSelezionata, ...messaggi]
    this.messaggiChatSelezionataAggiornati.next(this.messaggiChatSelezionata.slice())
  }

  aggiornaMessaggioChatSelezionata(messaggio: Messaggio) {
    if (+messaggio.chat.id === +this.chatSelezionata.id) {
      this.messaggiChatSelezionata = [...this.messaggiChatSelezionata.filter(mess => +mess.id !== +messaggio.id), messaggio]
      this.messaggiChatSelezionataAggiornati.next(this.messaggiChatSelezionata.slice())
    }
  }

  impostaSottoscrizioniChat() {
    this.rimuoviTutteLeSottoscrizioni()
    this.http.get(ServiziRest.urlGetElencoIdChatUtente
    ).pipe(
      map(
        (esito: Esito) => {
          if (esito.esito === ESITO_OK) {
            const idChats = JSON.parse(esito.payload) as Array<{ id: number }>
            // console.log('idchats', idChats)
            return idChats.map(id => id.id)
          } else {
            console.log(esito)
            return []
          }
        })
    ).subscribe(ids => this.sottoscriviPerNuoviMessaggiChats(ids))
    this.sottoscriviPerMessaggiLetti()
  }

  sottoscriviPerNuoviMessaggiChats(idChats: number[]) {
    idChats.forEach(idChat =>
      this.sottoscriviPerNuoviMessaggiChat(+idChat)
    )
    this.sottoscriviPerInvitiInChat()
  }
  /**
 * Sottoscrive il client a ricevere notifiche in caso di nuovi messaggi verso la chat passata come parametro
 *
 * @param chat
 *
 */
  sottoscriviPerNuoviMessaggiChat(idChat: number) {
    const eventKey = 'chat-' + idChat
    if (!this.socketSubscriptions.has(eventKey)) {
      const listener = (msg: Messaggio) => {

        console.log('nuovo messaggio', msg.id)
        if (+msg.mittente.id !== +this.authService.getUserPk()) {
          console.log('Arrivata notifica per nuovo messaggio che mi riguarda!!!: ' + msg.testo)
          if (this.chatSelezionata && +this.chatSelezionata.id === idChat) {
            this.aggiungiMessaggioAChatSelezionata(msg)
          }
          this.aggiungiMessaggioNonLetto(msg)
        } else {
          console.log('Arrivata notifica per nuovo messaggio ma ad altro destinatario!!!: ' + msg.testo)
        }

      }
      GlobalVars.socket.on(eventKey, listener)
      this.socketSubscriptions.set(eventKey, listener)
    } else {
      console.log('chat ' + idChat + ' già inclusa nella lista delle chat ascoltate')
    }
  }

  sottoscriviPerDisinoltriSegnalazione() {
    this.sottoscrizioneDisinoltri = this.segnalazioneService.notifichePerSegnalazioneDisinoltrata.subscribe
      ((segnalazione: Segnalazione) => {
        // console.log('Ricevuto inoltro della segnalazione: ' + segnalazione.id);
        if (segnalazione) {
          // verifico se ho una membership sui diritti segnalazione odv
          const membership = membershipDaGestorePerSegnalazione(segnalazione, this.authService.getUser())
          if (membership === 'Nessuna') {
            // elimina la segnalazione dalla lista delle segnalazioni in sessione
            this.sessionData.segnalazioni = this.sessionData.segnalazioni.filter(s => s.id !== segnalazione.id)
            if (this.sessionData.getSegnalazioneSelezionata()?.id === segnalazione.id) {
              this.sessionData.nuovaSegnalazioneSelezionata(this.sessionData.segnalazioni[0])
            }
          }

          // cerca tutte le chat relative alla segnalazione e le elimina
          this.impostaSottoscrizioniChat()
        }
      })
  }

  sottoscriviPerMessaggiLetti() {
    const eventKey = 'read'
    if (!this.socketSubscriptions.has(eventKey)) {
      const listener = (idSegnalazione: string, idUtente: string, idMessaggio: string) => {
        console.log('Ricevuta notifica read per messaggio ' + idMessaggio)
        this.messaggioLettoId(+idMessaggio, +idUtente)
      }
      GlobalVars.socket.on(eventKey, listener)
      this.socketSubscriptions.set(eventKey, listener)
    } else {
      console.log('sottoscrizione per messaggi letti già attiva')
    }
  }

  sottoscriviPerInvitiInChat() {
    if (this.authService.getUser().odv) {
      this.authService.getUser().odvMembership?.forEach(odv => {
        const eventKey = 'inviti-chat-gruppo-' + odv.id
        if (!this.socketSubscriptions.has(eventKey)) {
          const listener = (payload = []) => {
            // il payload è del tipo [idChat, idSegnalazione, inout]
            // dove inout se vale "OUT" significa che il gruppo è stato rimosso dalla chat
            // console.log('Ricevuto inoltro della segnalazione: ' + segnalazione.id);
            if (payload) {
              if (payload[2] === 'OUT') {
                this.eliminaSottoscrizioneNotifica(eventKey)
              } else {
                this.sottoscriviPerNuoviMessaggiChat(+payload[0])
              }
            }
          }

          GlobalVars.socket.on(eventKey, listener)
          this.socketSubscriptions.set(eventKey, listener)
        }
      })
    }
  }

  eliminaSottoscrizioneNotifica(eventKey: string) {
    const listener = this.socketSubscriptions.get(eventKey)
    if (listener) {
      GlobalVars.socket.off(eventKey, listener)
      this.socketSubscriptions.delete(eventKey)
    }
  }

}
