<app-toolbar [mostraFiltro]="false"></app-toolbar>
<div class="flex-column custom-warning testo-large" *ngIf="dataEliminazioneBozza && isBozza() && segnalazioneVisibile">
  <b i18n class="testo-avviso-segnalazione">
    Questa segnalazione deve essere inviata entro il
    {{ dataEliminazioneBozza | date: 'dd MMMM yyyy' }}
    altrimenti verrà eliminata</b>
</div>
<div class="alternate-theme">
  <div class="overlay flex centered" *ngIf="loading || loadingSegnalante || saving || loadingDocumenti">
    <mat-spinner diameter="50" strokeWidth="5" style="margin:auto"></mat-spinner>
  </div>
  <as-split #split direction="horizontal">
    <as-split-area #pannelloSegnalazione [size]="70">

      <div class="form-segnalazione">
        <div *ngIf="false">
          <!-- bottone per fare refresh della pagina posizionato al centro -->
          <div class="flex-column flex-100 centered" style="margin-top: 20px;">
            <h2 i18n> Sembra ci sia stato un problema nel caricamento della pagina </h2>
            <button mat-button (click)="reloadPage()" class="refresh-button" i18n>
              Clicca qui per ricaricare la pagina
            </button>
          </div>
        </div>
        <button *ngIf="!datiSegnalanteInModifica && odv && !modalitaOmissis && !modalitaOscuramento"
          class="bottone-flottante-alto-sinistra-basso-basso-col1" mat-fab
          (click)="autoescludiDialog(); $event.stopPropagation();" i18n-matTooltip
          matTooltip="Disabilita la visualizzazione della Segnalazione per la mia utenza">
          <mat-icon aria-label="auto escludi" class="icona-fab">disabled_visible</mat-icon>
        </button>
        <button
          *ngIf="!datiSegnalanteInModifica && sonoGestoreSceltoDaSegnalante && !segnalazioneRitirata && !modalitaOmissis && !modalitaOscuramento"
          class="bottone-flottante-alto-sinistra-basso-basso-col3" mat-fab
          (click)="ritiroSegnalazioneDialog(); $event.stopPropagation();" i18n-matTooltip
          matTooltip="Ritira la segnalazione">
          <mat-icon aria-label="ritira" class="icona-fab">do_not_touch</mat-icon>
        </button>
        <button
          *ngIf="!datiSegnalanteInModifica && sonoGestoreSceltoDaSegnalante && segnalazioneRitirata && !modalitaOmissis && !modalitaOscuramento"
          class="bottone-flottante-alto-sinistra-basso-basso-col3" mat-fab
          (click)="annullaRitiroSegnalazioneDialog(); $event.stopPropagation();" i18n-matTooltip
          matTooltip="Annulla il Ritiro della segnalazione">
          <mat-icon aria-label="ritira" class="icona-fab">cached</mat-icon>
        </button>
        <button
          *ngIf="hoPresoInCaricoSegnalazione && !datiSegnalanteInModifica && sonoGestoreSceltoDaSegnalante && !segnalazioneRitirata && !(modalitaOmissis && !modalitaOscuramento) "
          [disabled]="oscuramentoInPreparazioneENonSonoGestore"
          [ngClass]="{'bottone-flottante-alto-sinistra-basso-basso-col4': !modalitaOscuramento ,
          'bottone-flottante-alto-sinistra-basso-basso-col1': modalitaOscuramento,
          'bottone-giallo': processoOscuramento && !processoOscuramento.condiviso && !modalitaOscuramento && oscuramentoInPreparazioneESonoGestore}" mat-fab #badge
          (click)="handleClickBottoneProcessoOscuramento(); $event.stopPropagation();" matBadgeColor="accent"
          matBadgeSize="medium" [matBadge]="contaAutorizzazioniPendenti()" [matBadgeHidden]="!mostrareBadge(badge)"
          [matTooltip]="avviaProcessoOscuramentoText()">
          <mat-icon aria-label="avvia processo oscuramento" class="icona-fab">{{modalitaOscuramento ? 'arrow_back' :
            'brightness_6'}}</mat-icon>
        </button>
        <button *ngIf="modalitaOscuramento && oscuramentoInPreparazioneESonoGestore && ciSonoOmissisNellOscuramento"
          [ngClass]="{'bottone-flottante-alto-sinistra-basso-basso-col3': modalitaOscuramento}" mat-fab #badge
          (click)="handleClickBottoneSottomettiProcessoOscuramento(); $event.stopPropagation();" matBadgeColor="accent"
          matBadgeSize="medium" [matBadge]="contaAutorizzazioniPendenti()" [matBadgeHidden]="!mostrareBadge(badge)"
          i18n-matTooltip matTooltip="Sottometti proposta di oscuramento per approvazione">
          <mat-icon aria-label="Sottometti proposta di oscuramento per approvazione" class="icona-fab">share</mat-icon>
        </button>
        <button *ngIf="modalitaOscuramento && gruppoSelezionato && processoOscuramento && processoOscuramento.condiviso &&
        (!autorizzazioneOscuramento.autorizzato || autorizzazioneOscuramento.autorizzato == null)
        && ciSonoOmissisNellOscuramento" class="bottone-flottante-alto-sinistra-basso-basso-col2" mat-fab
          (click)="handleClickAutorizzaOscuramento(); $event.stopPropagation();" i18n-matTooltip
          matTooltip="Autorizza oscuramento">
          <mat-icon aria-label="autorizza oscuramento" class="icona-fab">thumb_up</mat-icon>
        </button>
        <button *ngIf="modalitaOscuramento && gruppoSelezionato && processoOscuramento && processoOscuramento.condiviso &&
        (autorizzazioneOscuramento.autorizzato || autorizzazioneOscuramento.autorizzato == null)
        && ciSonoOmissisNellOscuramento" class="bottone-flottante-alto-sinistra-basso-basso-col3" mat-fab
          (click)="handleClickNegaOscuramento(); $event.stopPropagation();" i18n-matTooltip
          matTooltip="Nega oscuramento">
          <mat-icon aria-label="nega oscuramento" class="icona-fab">thumb_down</mat-icon>
        </button>
        <button *ngIf="modalitaOscuramento && gruppoSelezionatoResponsabile && processoOscuramento"
          class="bottone-flottante-alto-sinistra-basso-basso-col4" mat-fab
          (click)="handleClickAnnullaOscuramento(); $event.stopPropagation();" i18n-matTooltip
          matTooltip="Annulla processo oscuramento">
          <mat-icon aria-label="annulla oscuramento" class="icona-fab">undo</mat-icon>
        </button>
        <div class="selettore-gruppo-gestore" *ngIf="modalitaOscuramento">
          <mat-form-field color="accent">
            <mat-select [(ngModel)]="gruppoSelezionato" i18n-placeholder placeholder="Opera come gruppo">
              <mat-option *ngFor="let odv of gruppiImpersonati"
                (onSelectionChange)="onGruppoSelezionato($event.source.selected, odv)" [value]="odv"
                [class.possiede-autorizzazioni-pendenti]="possiedeAutorizzazioniPendenti(odv)">
                {{ odv.nomeOdv }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button *ngIf="utentiEsclusi && utentiEsclusi.length > 0 && !modalitaOscuramento && odv"
          class="bottone-flottante-alto-sinistra-basso-basso-col2" mat-fab [matMenuTriggerFor]="menuEsclusi"
          i18n-matTooltip matTooltip="Sono presenti utenti esclusi dalla visibilità della segnalazione">
          <mat-icon aria-label="utenti esclusi" class="icona-fab">info_outline</mat-icon>
        </button>

        <mat-menu #menuEsclusi="matMenu">
          <div *ngIf="utentiEsclusi && utentiEsclusi.length > 0" style="margin-right: 10px; margin-left: 10px;">
            <table aria-label="Table with user information">
              <thead>
                <tr>
                  <th>Username</th>
                  <th i18n>Data esclusione</th>
                  <th></th> <!-- Nuova colonna senza nome di testata -->
                </tr>
                <tr>
                  <td colspan="3">
                    <mat-divider style="position: relative;"></mat-divider>
                  </td>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let utenteEscluso of utentiEsclusi">
                  <tr>
                    <td>
                      <mat-form-field>
                        <input matInput #userid [value]="utenteEscluso.utenteInibito.userId" readonly>
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field>
                        <input matInput #dataEsclusione
                          [value]="utenteEscluso.dataInibizione | date: 'dd/MM/yyyy HH:mm'" readonly>
                      </mat-form-field>
                    </td>
                    <td>
                      <button mat-icon-button (click)="riabilitaUtenteDialog(utenteEscluso)">
                        <mat-icon matTooltip="Riabilita" i18n-matTooltip>arrow_circle_up</mat-icon>
                        <!-- Icona rappresentativa (esempio: "add_circle") -->
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

        </mat-menu>

        <button *ngIf="modalitaOscuramento && gruppoSelezionatoResponsabile && processoOscuramento && odv"
          class="bottone-flottante-alto-sinistra-basso-basso-col5" mat-fab [matMenuTriggerFor]="menuOscuramento"
          i18n-matTooltip matTooltip="Elenco delle autorizzazioni accordate e negate">
          <mat-icon aria-label="utenti esclusi" class="icona-fab">info_outline</mat-icon>
        </button>

        <mat-menu #menuOscuramento="matMenu">
          <div style="margin-right: 10px; margin-left: 10px;">
            <table aria-label="Table with user information">
              <thead>
                <tr>
                  <th i18n>Gruppo</th>
                  <th i18n>Username</th>
                  <th i18n>Decisione</th> <!-- Nuova colonna senza nome di testata -->
                </tr>
                <tr>
                  <td colspan="3">
                    <mat-divider style="position: relative;"></mat-divider>
                  </td>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let autorizzazione of autorizzazioniOscuramento">
                  <tr>
                    <td>
                      <mat-form-field>
                        <input matInput #gruppo [value]="autorizzazione.odv.nomeOdv" readonly>
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field>
                        <input matInput #userid [value]="autorizzazione.utente?.userId" readonly>
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-icon *ngIf="autorizzazione.autorizzato" matTooltip="Autorizzato"
                        i18n-matTooltip>thumb_up</mat-icon> <!-- Icona rappresentativa (esempio: "add_circle") -->
                      <mat-icon
                        *ngIf="!autorizzazione.autorizzato && autorizzazione.autorizzato !== null && autorizzazione.autorizzato !== undefined"
                        matTooltip="Negato" i18n-matTooltip>thumb_down</mat-icon>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

        </mat-menu>



        <button *ngIf="ultimoProcessoOscuramentoGestitoEChiuso && !modalitaOscuramento && !modalitaOmissis && odv"
          class="bottone-flottante-alto-sinistra-basso-basso-col5" mat-fab
          [matMenuTriggerFor]="menuOscuramentoCompletato" i18n-matTooltip
          matTooltip="Elenco definitivo delle autorizzazioni accordate e negate dell'ultima richiesta di oscuramento">
          <mat-icon aria-label="autorizzazioni" class="icona-fab">info_outline</mat-icon>
        </button>

        <mat-menu #menuOscuramentoCompletato="matMenu">
          <div style="margin-right: 10px; margin-left: 10px;">
            <table aria-label="Table with user information">
              <thead>
                <tr>
                  <th i18n>Gruppo</th>
                  <th i18n>Username</th>
                  <th i18n>Decisione</th> <!-- Nuova colonna senza nome di testata -->
                </tr>
                <tr>
                  <td colspan="3">
                    <mat-divider style="position: relative;"></mat-divider>
                  </td>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let autorizzazione2 of ultimoProcessoOscuramentoGestitoEChiuso?.autorizzazioniOscuramento">
                  <tr>
                    <td>
                      <mat-form-field>
                        <input matInput #gruppo2 [value]="autorizzazione2.odv.nomeOdv" readonly>
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field>
                        <input matInput #userid2 [value]="autorizzazione2.utente.userId" readonly>
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-icon *ngIf="autorizzazione2.autorizzato" matTooltip="Autorizzato"
                        i18n-matTooltip>thumb_up</mat-icon> <!-- Icona rappresentativa (esempio: "add_circle") -->
                      <mat-icon
                        *ngIf="!autorizzazione2.autorizzato && autorizzazione2.autorizzato !== null && autorizzazione2.autorizzato !== undefined"
                        matTooltip="Negato" i18n-matTooltip>thumb_down</mat-icon>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

        </mat-menu>

        <form #formSegnalazione="ngForm" [formGroup]="segnalazioneForm" *ngIf="segnalazioneForm  && segnalazione"
          (keydown)="keyDownFunction($event); $event.stopPropagation()" class="flex-column">
          <div class="centered sotto-testata-dettaglio-segnalazione flex-gap-10"
            style="padding: 12px; min-height:34px;">
            <div class="link-manuale" *ngIf="!odv">
              <mat-icon class="colore-background">help</mat-icon>
              <a mat-button class="left-pad" (click)="apriDialogManualeUtente()" i18n>Manuale Utente</a>
            </div>

            <div class="flex-fill"></div>
            <div mat-button class="sfondo-giallo-trasparente padding-5 margin-right-10"
              *ngIf="(!isBozza() && segnalazioneVisibile) && (odv || (!odv && !segnalazioneRitirata))">
              <mat-icon color="accent">warning</mat-icon>
              <span *ngIf="dataEliminazioneBozza" i18n style="align-self:center"> Questa segnalazione deve essere
                lavorata entro il
                {{ dataCompletamentoIndagine | date: 'dd MMMM yyyy' }} </span>
            </div>

            <div mat-button *ngIf="(eliminazionePianificata || segnalazione.sospendiCancellazione)
            && odv " class="sfondo-giallo-trasparente padding-5">
              <mat-icon color="accent">info</mat-icon>
              <span [matTooltip]="messaggioPerSegnalazioneDaCancellare" i18n>{{testoPerSegnalazioneDaCancellare}}</span>
            </div>
            <button mat-fab *ngIf="odvDestinatarioSegnalazione" (click)="sospendiCancellazione()"
              style="top:-12px; left:4px;">
              <mat-icon class="icona-fab" *ngIf="!segnalazione.sospendiCancellazione" i18n-matTooltip
                matTooltip="Sospendi i termini di cancellazione">stop</mat-icon>
              <mat-icon class="icona-fab" *ngIf="segnalazione.sospendiCancellazione" i18n-matTooltip
                matTooltip="Riavvia i termini di cancellazione">play_arrow</mat-icon>
            </button>
          </div>

          <mat-expansion-panel [expanded]="true" disabled hideToggle id="SchedaSegnalazione" name="Scheda Segnalazione">
            <mat-expansion-panel-header class="testata-principale-dettagli-segnalazione">
              <mat-panel-title>
                <p i18n class="titolo-pagina">Scheda Segnalazione {{ getIdSegnalazione() }}</p>

                <!-- Inserisce un bottone tutto a destra -->


                <div style="position:relative; margin-top:20px;" *ngIf="!readOnly() && segnalazioneVocaleConsentita">
                  <!-- inserisci un bottone con testo ed anche una icona come prefisso record_voice_over -->
                  <button mat-stroked-button #segnalazioneVocale matInput type="checkbox" class="larger-font"
                    (click)="onSegnalazioneVocaleClick()">
                    <mat-icon *ngIf="segnalazione.segnalazioneVocale">edit</mat-icon>
                    <mat-icon *ngIf="segnalazione.segnalazioneVocale">record_voice_over</mat-icon>
                    <mat-icon *ngIf="!segnalazione.segnalazioneVocale">record_voice_over</mat-icon>
                    {{etichettaTipoSegnalazione}}
                  </button>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="!segnalazioneVisibile && !loadingSegnalazione"
              style=" position:relative; margin-top:20px; text-align: center;">
              <H3 i18n>NON SEI ABILITATO ALLA CONSULTAZIONE DELLA SEGNALAZIONE</H3>
            </div>
            <div *ngIf="segnalazioneRitirata" style=" position:relative; margin-top:20px; text-align: center;">
              <H3 i18n>SEGNALAZIONE RITIRATA SU RICHIESTA DEL SEGNALANTE</H3>
            </div>
            <div *ngIf="segnalazioneVisibile && ((!segnalazioneRitirata && !odv) || odv)" class="flex-column">

              <div *ngIf="odvDestinatarioSegnalazione && segnalazione">
                <app-pianificazione-eliminazione-segnalazione [segnalazione]="segnalazione"
                  (recuperaDatiArchiviazione)="recuperaDatiArchiviazione(+segnalazione.id)"></app-pianificazione-eliminazione-segnalazione>
              </div>

              <div class="flex-column" style=" position:relative; margin-top:20px;"
                *ngIf="isGestore() && !isBozza() && !condivisioneSegnalazioneNellaSchedaSegnalazione && !modalitaOscuramento">
                <app-condivisione-segnalazione [segnalazione]="segnalazione" [elencoAziende]="elencoAziende">
                </app-condivisione-segnalazione>
              </div>
              <div style=" position:relative; margin-top:20px;" *ngIf="segnalazione.id || segnalazione['segnalazione']"
                class="flex-row">
                <mat-form-field class="form-group width-id-modulo" *ngIf="odv">
                  <input (keydown)="onKeyDown($event)" matInput autocompleteOff disabled
                    class="form-control contenuto-segnalazione" id="progSegnalazione"
                    value="{{ segnalazione.progressivo }}" />
                  <mat-label i18n class="label-segnalazione testo-label-scalabile">Progressivo di sistema</mat-label>
                </mat-form-field>
                <mat-form-field class="form-group width-id-modulo">
                  <input (keydown)="onKeyDown($event)" matInput autocompleteOff disabled
                    class="form-control contenuto-segnalazione" id="idSegnalazione"
                    value="{{ segnalazione.idPerSegnalante}}" />
                  <mat-label i18n class="label-segnalazione testo-label-scalabile">Identificativo
                    Segnalazione</mat-label>
                </mat-form-field>

                <mat-form-field class="form-group width-stato">
                  <input (keydown)="onKeyDown($event)" matInput autocompleteOff disabled
                    class="form-control contenuto-segnalazione" id="stato"
                    value="{{ _convertiStatoSegnalazione(segnalazione) }}" />
                  <mat-label i18n class="label-segnalazione testo-label-scalabile">Stato</mat-label>
                </mat-form-field>
                <mat-form-field class="form-group width-stato">
                  <input (keydown)="onKeyDown($event)" matInput autocompleteOff disabled
                    class="form-control contenuto-segnalazione" id="dataInserimento"
                    value="{{ segnalazione.dataInserimento | date: test.formato }}" />
                  <mat-label class="label-segnalazione testo-label-scalabile" i18n>Data Inserimento</mat-label>
                </mat-form-field>
              </div>


              <div style=" position:relative; margin-top:20px;" class="flex-column" *ngIf="visualizzaDestinatari">
                <mat-form-field class="form-group width-full">
                  <mat-label class="testo-label-scalabile" i18n>Destinatari</mat-label>
                  <div>
                    <mat-chip-list #chipListDestinatari>
                      <mat-chip *ngFor="let destinatario of destinatariVisibili(2)" [selectable]="selectable"
                        [removable]="!readOnlyDestinatari()" (removed)="rimuoviDestinatario(destinatario)"
                        [class.odv-scelto-da-segnalante]="destinatario.sceltoDaSegnalante && !destinatario.odvDestinatarioInConflittoDiInteressi"
                        [class.odv-inoltrato]="!destinatario.sceltoDaSegnalante"
                        [class.conflitto-interessi]="destinatario.odvDestinatarioInConflittoDiInteressi"
                        [matTooltip]="tooltipDestinatari(destinatario)">
                        {{ destinatario.odvDestinatario.nomeOdv }}
                        <mat-icon matChipRemove *ngIf="!readOnlyDestinatari()">cancel
                        </mat-icon>
                      </mat-chip>
                      <input (keydown)="onKeyDown($event)" #inputDestinatari matInput formControlName="destinatari"
                        [matChipInputFor]="chipListDestinatari" [matAutocomplete]="auto4"
                        [readonly]="readOnlyDestinatari()" (click)="elencoDestinatariClicked($event)" autocompleteOff
                        #triggerDestinatari="matAutocompleteTrigger" (click)="triggerDestinatari.openPanel()"
                        (matChipInputTokenEnd)="addDestinatario($event)">
                      <mat-autocomplete #auto4="matAutocomplete" (optionSelected)="openPanel()">
                        <mat-option *ngFor="let destinatario of destinatariSegnalazioneFiltrati"
                          [value]="destinatario.nomeOdv" (onSelectionChange)="selectedDestinatario(destinatario)">
                          {{ destinatario.nomeOdv }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-chip-list>
                  </div>

                </mat-form-field>

              </div>
              <div class="flex-row" *ngIf="gestioneConflittoInteressiAttivo">
                <mat-form-field color="accent" class="flex-row">
                  <input matInput class="pointer" type="text">
                  <div class="flex-row flex-gap-10">
                    <mat-checkbox #segnalazioneRiferibileAlGestorePredefinito
                      formControlName="segnalazioneRiferibileAlGestorePredefinito">
                    </mat-checkbox>
                    <div i18n class="flex-100 testo-label-scalabile">Segnalazione riferibile a uno o più soggetti del
                      gruppo gestore</div>
                  </div>
                </mat-form-field>
              </div>
              <div *ngIf="!segnalazioneVocale" style=" margin-top:20px;">
                <div>
                  <mat-form-field class="form-group width-oggetto">
                    <mat-label class="testo-label-scalabile" i18n>Oggetto</mat-label>
                    <textarea maxlength="20000" (keydown)="onKeyDown($event)" matInput formControlName="oggetto"
                      i18n-placeholder placeholder="Oggetto" required autocompleteOff class="contenuto-segnalazione"
                      id="oggettoSegnalazione" *ngIf="!modalitaOmissis" [readonly]="readOnly()">
                    </textarea>

                    <mat-chip-list #chipListOggetto *ngIf="modalitaOmissis">
                      <mat-chip *ngFor="
                        let segmento of oggettoSegments" [selectable]="selectable"
                        [removable]="modalitaOmissis && segmento.parola?.trim()!== 'OMISSIS' && segmento.parola?.trim()!== '*OMISSIS*'"
                        (click)="segmento.parola?.trim()!== 'OMISSIS' && segmento.parola?.trim()!== '*OMISSIS*' ?
                        toggleOmissis(segmento) : nonPuoiGestireOmissis()"
                        [ngClass]="{'disabled-omissis': !segmento?.isOmissis , 'enabled-omissis': segmento?.isOmissis}">
                        {{ segmento.parola }}
                        <!--                         <mat-icon class="pointer" *ngIf="modalitaOscuramento && (segmento.parola?.trim()=== 'OMISSIS' || segmento.parola?.trim()=== '*OMISSIS*')"
                          matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                          (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segmento)">
                          'visibility'
                        </mat-icon> -->
                      </mat-chip>
                      <input (keydown)="onKeyDown($event)" matInput autocompleteOff i18n-placeholder
                        [matChipInputFor]="chipListOggetto" class="contenuto-segnalazione" id="oggettoSegnalazione"
                        [readonly]="true" maxlength="1" size="1" autocompleteOff>
                    </mat-chip-list>


                  </mat-form-field>
                </div>


              </div>

              <div *ngIf="!segnalazioneVocale">
                <mat-form-field class="form-group width-full">
                  <textarea maxlength="20000" [required]="descrizioneObbligatoria" matInput autocomplete="new-password"
                    class="contenuto-segnalazione" formControlName="descrizioneFatto" id="descrizioneFatto"
                    *ngIf="!modalitaOmissis" i18n-placeholder placeholder="Descrizione del fatto"
                    [(ngModel)]="segnalazione.descrizioneFatto"></textarea>
                  <mat-label class="testo-label-scalabile" i18n>Descrizione del fatto</mat-label>
                    <mat-chip-list #chipListDescrizioneFatto *ngIf="modalitaOmissis">
                      <textarea maxlength="20000" matInput autocompleteOff class="contenuto-segnalazione"
                        style="min-height: 100px;" formControlName="descrizioneFatto" id="descrizioneFatto"
                        *ngIf="modalitaOmissis && descrizioneFattoSegments?.length === 0" i18n-placeholder
                        placeholder="Descrizione del fatto"></textarea>
                    <ng-container *ngFor="let segmento of descrizioneFattoSegments">
                        <ng-container *ngIf="segmento.parola === '\\n'; else chip">
                          <div style="width:100%"></div>
                        </ng-container>
                        <ng-template #chip>
                          <mat-chip [selectable]="selectable"
                            [removable]="modalitaOmissis && segmento.parola?.trim()!== 'OMISSIS' && segmento.parola?.trim()!=='*OMISSIS*'"
                            (click)="segmento.parola?.trim()!== 'OMISSIS' && segmento.parola?.trim()!=='*OMISSIS*' ? toggleOmissis(segmento) :
                           nonPuoiGestireOmissis()"
                            [ngClass]="{'disabled-omissis': !segmento?.isOmissis , 'enabled-omissis': segmento?.isOmissis}">
                            {{ segmento.parola }}
                            <!--                       <mat-icon class="pointer"
                          *ngIf="segmento.parola?.trim()==='*OMISSIS*' && modalitaOscuramento "
                            style="padding: 8px;" matTooltip="Visualizza parola in chiaro"
                            (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segmento)">
                            'visibility'
                          </mat-icon> -->
                          </mat-chip>
                        </ng-template>
                      </ng-container>
                    </mat-chip-list>
                </mat-form-field>
              </div>

              <div>
                <mat-card class="card-registratore">
                  <div class="container">
                    <div class="col1">
                      <mat-icon class="mic">mic</mat-icon>
                    </div>
                    <div class="col2">
                      <div class="row1">
                        <mat-card-header>
                          <mat-card-title *ngIf="!modalitaOmissis" i18n class="colore-background">Registrazione
                            audio {{vocaleObbligatorio}}
                          </mat-card-title>

                          <mat-chip-list *ngIf="modalitaOmissis && segnalazione.hashAudio" #chipListAudio>
                            <mat-chip [selectable]="selectable" i18n *ngIf="hashAudioSegment && hashAudioSegment[0]"
                              [removable]="modalitaOmissis && segnalazione.hashAudio?.trim() !== 'OMISSIS' &&
                              segnalazione.hashAudio?.trim() !== '*OMISSIS*'" (click)="segnalazione.hashAudio?.trim() !== 'OMISSIS' && segnalazione.hashAudio?.trim() !== '*OMISSIS*' ?
                              toggleOmissis(hashAudioSegment[0], true) :
                              nonPuoiGestireOmissis()" [ngClass]="{'disabled-omissis': hashAudioSegment && !hashAudioSegment[0]?.isOmissis ,
                              'enabled-omissis': hashAudioSegment && hashAudioSegment[0]?.isOmissis}">
                              Registrazione audio
                            </mat-chip>
                            <input (keydown)="onKeyDown($event)" matInput i18n-placeholder
                              [matChipInputFor]="chipListAudio" class="form-control contenuto-segnalazione"
                              [readonly]="true" maxlength="1" size="1">
                          </mat-chip-list>

                          <mat-card-subtitle class="sottotitoli-audio" *ngIf="!readOnly()"
                            [innerHtml]="testoRichiestaConsensoAudio"></mat-card-subtitle>
                          <mat-card-subtitle class="sottotitoli-audio"
                            *ngIf="!modalitaOmissis && segnalazione.hashAudio?.trim() === 'OMISSIS' && segnalazione.hashAudio?.trim() === '*OMISSIS*'">
                            <h3 class="colore-riferimento">OMISSIS</h3>
                          </mat-card-subtitle>
                        </mat-card-header>
                      </div>
                      <div class="row2">
                        <app-pitch-modification [readOnly]="readOnly()" class="app-pitch"
                          *ngIf="segnalazione.hashAudio?.trim() !== 'OMISSIS' && segnalazione.hashAudio?.trim() !== '*OMISSIS*'"
                          (registrazioneVocalePresente)="registrazioneVocalePresente()"
                          (registrazioneVocaleNonPresente)="registrazioneVocaleNonPresente()"
                          (inizioProcessingRegistrazioneVocale)="registrazioneVocaleNonSalvata()"
                          (fineProcessingRegistrazioneVocale)="stopLoading()"
                          (registrazioneVocaleEliminata)="registrazioneVocaleNonPresente()"
                          (registrazioneVocaleSalvata)="registrazioneVocaleSalvata($event)"
                          (ricaricaSegnalazione)="reloadSegnalazione()" (salvaSegnalazione)="preSaveSegnalazione()"
                          (stopLoading)="toggleLoading(false)" [audioBase64]="audioBase64" [segnalazione]="segnalazione"
                          [audioCifrato]="audioCifrato" [anonima]="segnalazione.anonima"></app-pitch-modification>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
              <div style="position:relative; margin-top:20px;"
                *ngIf="segnalazioniAnonimeConsentite && segnalazioneVocale && (!readOnly() || (readOnly() && segnalazione.anonima))">
                <mat-checkbox #anonima matInput type="checkbox" formControlName="anonima"
                  (click)="onAnonimaClick(anonima.checked)"></mat-checkbox>
                <mat-label class="testo-label-scalabile" i18n style="padding: 6px;">Segnalazione Anonima</mat-label>
              </div>
              <div *ngIf="visualizzaCampoAziendaInteressata" style="margin-top:24px">
                <mat-form-field>
                  <mat-label class="testo-label-scalabile" i18n>Società del gruppo a cui è riferito il fatto
                    *</mat-label>
                  <input (keydown)="onKeyDown($event)" matInput autocompleteOff *ngIf="readOnly() && !modalitaOmissis"
                    class="contenuto-segnalazione" [readonly]="true" maxlength="1" size="1"
                    [value]="segnalazione.aziendaInteressata?.nomeAzienda" />
                  <mat-select formControlName="societa" *ngIf="!modalitaOmissis && !readOnly()"
                    [compareWith]="compareAziende">
                    <mat-option *ngFor="let azienda of elencoAziende" [value]="azienda">
                      {{ azienda.nomeAzienda }}
                    </mat-option>
                  </mat-select>
                  <mat-chip-list #chipListSocietaOmissis *ngIf="modalitaOmissis">
                    <mat-chip [selectable]="selectable"
                      *ngIf="aziendaInteressataSegment && aziendaInteressataSegment[0]" [removable]="modalitaOmissis && segnalazione.aziendaInteressata?.nomeAzienda?.trim() !== 'OMISSIS' &&
                      segnalazione.aziendaInteressata?.nomeAzienda?.trim() !== '*OMISSIS*'" (click)="segnalazione.aziendaInteressata?.nomeAzienda?.trim() !== 'OMISSIS' &&
                      segnalazione.aziendaInteressata?.nomeAzienda?.trim() !== '*OMISSIS*' ? toggleOmissis(aziendaInteressataSegment[0], true):
                      nonPuoiGestireOmissis()"
                      [ngClass]="{'disabled-omissis': aziendaInteressataSegment && !aziendaInteressataSegment[0].isOmissis, 'enabled-omissis': aziendaInteressataSegment && aziendaInteressataSegment[0].isOmissis}">
                      {{ aziendaInteressataSegment[0]?.parola }}
                      <!--                       <mat-icon class="pointer mostra-parola-in-chiaro"
                        *ngIf="(aziendaInteressataSegment[0]?.parola === '*OMISSIS*' ) && modalitaOscuramento"
                        matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                        (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(aziendaInteressataSegment[0])">
                        'visibility'
                      </mat-icon> -->
                    </mat-chip>
                    <input (keydown)="onKeyDown($event)" matInput autocompleteOff
                      [matChipInputFor]="chipListSocietaOmissis" class="contenuto-segnalazione" [readonly]="true"
                      maxlength="1" size="1">
                  </mat-chip-list>
                </mat-form-field>
              </div>
              <div *ngIf="mostraTipiNonConformita">
                <mat-form-field class="form-group width-full">
                  <mat-label i18n class="testo-label-scalabile">Tipo di non conformità / Violazione oggetto di
                    segnalazione *</mat-label>
                  <mat-chip-list #chipListTipoNonConformita *ngIf="modalitaOmissis">

                    <mat-chip *ngFor="
                      let segmento of descrizioneSegment" [selectable]="selectable"
                      [removable]="modalitaOmissis && segmento.parola?.trim() !== 'OMISSIS' && segmento.parola?.trim() !== '*OMISSIS*'"
                      (click)="segmento.parola?.trim() !== 'OMISSIS' && segmento.parola?.trim() !== '*OMISSIS*' ? toggleOmissis(segmento, true) :
                      nonPuoiGestireOmissis()"
                      [ngClass]="{'disabled-omissis': !segmento?.isOmissis , 'enabled-omissis': segmento?.isOmissis}">
                      {{ segmento.parola }}
                      <!--                       <mat-icon class="pointer mostra-parola-in-chiaro"
                      *ngIf="(segmento.parola === '*OMISSIS*' ) && modalitaOscuramento"
                        matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                        (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segmento)">
                        'visibility'
                      </mat-icon> -->
                    </mat-chip>
                    <input (keydown)="onKeyDown($event)" matInput class="contenuto-segnalazione" [readonly]="true"
                      maxlength="1" size="1" [matChipInputFor]="chipListTipoNonConformita" />
                  </mat-chip-list>
                  <mat-select formControlName="descrizione" *ngIf="!modalitaOmissis && !readOnly()">
                    <mat-option *ngFor="let tipologiaSegnalazione of tipologiaSegnalazioneOptions"
                      [value]="tipologiaSegnalazione">
                      {{ tipologiaSegnalazione }}
                    </mat-option>
                  </mat-select>
                  <input (keydown)="onKeyDown($event)" matInput class="contenuto-segnalazione" [readonly]="true"
                    *ngIf="!modalitaOmissis && readOnly()" formControlName="descrizione" />

                </mat-form-field>
              </div>

              <mat-expansion-panel [expanded]="true" class="scheda-full-width" id="SchedaLavorazioni" name="Lavorazioni"
                *ngIf="visibilitaPannelloLavorazioni">
                <mat-expansion-panel-header class="sottotestata-principale-dettagli-segnalazione">
                  <mat-panel-title i18n>
                    Lavorazioni
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="
              lavorazioniVisibili &&
                lavorazioniVisibili.length > 0
              " class="form-control contenuto-segnalazione">
                  {{ labelQuanteLavorazioni}}
                  <div *ngFor="
                  let lavorazione of lavorazioniVisibili
                  let i = index
                " class="form-control contenuto-segnalazione">
                    <div *ngIf="mostraTestoLavorazioni(lavorazione)">
                      {{ i + 1 }} -
                      {{
                      getTestoLavorazioni(lavorazione)
                      }}
                      <button mat-icon-button *ngIf="hoAccessoAllaLavorazione(lavorazione)" (click)="
                      recuperaPoiMostraChatConSegnalanteOGestore(lavorazione)
                    " i18n-matTooltip matTooltip="Gestione Chat">
                        <mat-icon class="icone-in-mezzo-al-testo">message</mat-icon>
                      </button>
                    </div>
                    <div *ngIf="!odv && !lavorazione.visibileAlSegnalante" i18n>
                      {{ i + 1 }} - Lavorazione non visibile
                    </div>
                  </div>
                </div>
                <div *ngIf="
                !segnalazione ||
                !segnalazione.lavorazioni ||
                segnalazione.lavorazioni.length === 0
              " class="form-control contenuto-segnalazione" i18n>
                  Nessuna lavorazione in corso
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="true" class="scheda-full-width" id="SchedaSegnalante"
                name="Dati Segnalante" *ngIf="!segnalazioneVocale">
                <mat-expansion-panel-header class="sottotestata-principale-dettagli-segnalazione">
                  <mat-panel-title>
                    <span *ngIf="!segnalazione.anonima" i18n>Dati Segnalante {{statoVerificaEmailSegnalante()}}</span>
                    <span *ngIf="segnalazione.anonima" i18n>Segnalazione Anonima, dati segnalante non disponibili</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-divider *ngIf="!segnalazione.anonima && odv"></mat-divider>

                <div *ngIf="!segnalazione.anonima">
                  <div *ngFor="let diritto of dirittiUtenteSullaSegnalazione">
                    <h1 i18n>Accesso ai dati del segnalante come membro del gruppo {{ diritto.odvDestinatario.nomeOdv }}
                    </h1>
                    <div *ngIf="custodiaAbilitata()">
                      <div *ngIf="richiestaSbloccoSegnalanteInEsame(diritto)">
                        <H2 i18n>La tua ultima richiesta di accesso ai dati del segnalante è stata inoltrata al custode.
                          Attendi
                          il responso.
                        </H2>
                      </div>

                      <div *ngIf=" esitoRichestaSbloccoSegnalante(diritto) === false">
                        <div *ngIf="motivazioneRispostaRichiestaDiSbloccoSegnalante(diritto)?.trim() == ''">
                          <H2 i18n>Una tua precedente richiesta di accesso ai dati del segnalante è stata respinta dal
                            Custode.
                          </H2>
                          <H2 i18n> Il custode non ha fornito una motivazione </H2>
                        </div>

                        <div *ngIf="motivazioneRispostaRichiestaDiSbloccoSegnalante(diritto)?.trim() !== ''">
                          <H2 i18n>Una tua precedente richiesta di accesso ai dati del segnalante è stata respinta dal
                            Custode
                          </H2>
                          <H2 i18n>Clicca il bottone <button mat-fab (click)="openDialogRispostaCustode(diritto)">
                              <mat-icon class="icona-fab" aria-label="locked">rate_review</mat-icon>
                            </button> per consultare la motivazione
                          </H2>
                        </div>

                      </div>

                      <div *ngIf=" esitoRichestaSbloccoSegnalante(diritto) === true">
                        <div *ngIf="motivazioneRispostaRichiestaDiSbloccoSegnalante(diritto)?.trim() == ''">
                          <H2 i18n>La tua precedente richiesta di accesso ai dati del segnalante è stata accolta dal
                            Custode.
                          </H2>
                          <H2 i18n> Il custode non ha fornito note. </H2>
                        </div>

                        <div *ngIf="motivazioneRispostaRichiestaDiSbloccoSegnalante(diritto)?.trim() !== ''">
                          <H2 i18n>La tua precedente richiesta di accesso ai dati del segnalante è stata accolta dal
                            Custode
                          </H2>
                          <H2 i18n>Clicca il bottone <button mat-fab (click)="openDialogRispostaCustode(diritto)">
                              <mat-icon class="icona-fab" aria-label="locked">rate_review</mat-icon>
                            </button> per consultare la motivazione </H2>
                        </div>
                      </div>

                    </div>


                    <div *ngIf="puoInoltrareRichiestaAlCustode(diritto)">
                      <H2 i18n>Dati non visibili, richiedere l'accesso al custode
                        <button mat-fab (click)="richiediAccessoAlCustode(diritto)">
                          <mat-icon class="icona-fab" aria-label="locked">lock</mat-icon>
                        </button>
                      </H2>
                    </div>


                    <div *ngIf="consultazioneSegnalanteAbilitabile(diritto)">
                      <H2 i18n>Per visualizzare i dati, clicca sul lucchetto
                        <button mat-fab (click)="sbloccaSegnalante(diritto)">
                          <mat-icon class="icona-fab" aria-label="locked">lock_open</mat-icon>
                        </button>
                      </H2>
                    </div>

                    <div *ngIf="nonAbilitatoAllaConsultazioneDatiSegnalante(diritto)">
                      <H2 i18n>Non sei abilitato alla consultazione dei dati del
                        segnalante</H2>
                    </div>

                    <div *ngIf="consensoNecessario(diritto) && !ricevutoConsensoDaSegnalante(diritto)">
                      <H2 i18n>E' necessario il consenso del segnalante</H2>
                    </div>
                    <mat-divider></mat-divider>
                  </div>

                </div>


                <div *ngIf="datiSegnalanteVisibili()" class="margin-top-20px dati-segnalante-no-red">
                  <div class="flex-row" *ngIf="sonoGestoreSceltoDaSegnalante">
                    <div class="flex-row"></div>
                    <button *ngIf="!datiSegnalanteInModifica" mat-fab class="top-10px" [disabled]="processoOscuramento"
                      (click)="abilitaModificaDatiSegnalante(); $event.stopPropagation();" i18n-matTooltip
                      [matTooltip]="toolTipBottoneModificaDatiSegnalante()">
                      <mat-icon aria-label="modifica dati segnalante" class="icona-fab">edit</mat-icon>
                    </button>
                  </div>
                  <div [ngClass]="screenWidth && screenWidth>=500 ? 'flex-row' : 'flex-column'">
                    <div style="flex:1 1 100%">
                      <mat-form-field class="form-group">
                        <input (keydown)="onKeyDown($event)" matInput autocompleteOff formControlName="nomeSegnalante"
                          i18n-placeholder placeholder="Nome" required class="form-control contenuto-segnalazione"
                          id="nomeSegnalante" *ngIf="!modalitaOmissis || modalitaOscuramento" />
                        <mat-chip-list *ngIf="modalitaOmissis && !modalitaOscuramento" #chipListNomeSegnalante>
                          <mat-chip [selectable]="selectable" *ngIf="segnalanteNomeSegment && segnalanteNomeSegment[0]"
                            [removable]="modalitaOmissis && segnalazione.segnalante.nome?.trim() !== 'OMISSIS' &&
                            segnalazione.segnalante.nome?.trim() !== '*OMISSIS*'" (click)="segnalazione.segnalante.nome?.trim() !== 'OMISSIS'  ?
                            toggleOmissis(segnalanteNomeSegment[0], true) :
                            nonPuoiGestireOmissis()" [ngClass]="{'disabled-omissis': segnalanteNomeSegment && !segnalanteNomeSegment[0]?.isOmissis , 'enabled-omissis':
                            segnalanteNomeSegment && segnalanteNomeSegment[0]?.isOmissis}">
                            {{ segnalanteNomeSegment[0].parola }}
                            <!--                             <mat-icon class="pointer mostra-parola-in-chiaro"
                              *ngIf="(segnalanteNomeSegment[0].parola === '*OMISSIS*' ) && modalitaOscuramento"
                              matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                              (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalanteNomeSegment[0])">
                              'visibility'
                            </mat-icon> -->
                          </mat-chip>
                          <input (keydown)="onKeyDown($event)" matInput i18n-placeholder placeholder="Nome"
                            [matChipInputFor]="chipListNomeSegnalante" class="form-control contenuto-segnalazione"
                            [readonly]="true" maxlength="1" size="1">
                        </mat-chip-list>
                      </mat-form-field>
                    </div>
                    <div style="flex:1 1 100%">

                      <mat-form-field class="form-group">
                        <input (keydown)="onKeyDown($event)" matInput autocompleteOff
                          formControlName="cognomeSegnalante" i18n-placeholder placeholder="Cognome" required
                          class="form-control contenuto-segnalazione" id="cognomeSegnalante"
                          *ngIf="!modalitaOmissis || modalitaOscuramento" />
                        <mat-chip-list *ngIf="modalitaOmissis  && !modalitaOscuramento" #chipListCognomeSegnalante>
                          <mat-chip [selectable]="selectable" [removable]="modalitaOmissis"
                            *ngIf="segnalanteCognomeSegment && segnalanteCognomeSegment[0]" (click)="segnalazione.segnalante.cognome?.trim() !== 'OMISSIS' && segnalazione.segnalante.cognome?.trim() !== '*OMISSIS*' ?
                            toggleOmissis(segnalanteCognomeSegment[0], true) :
                            nonPuoiGestireOmissis()" [ngClass]="{'disabled-omissis': segnalanteCognomeSegment && !segnalanteCognomeSegment[0]?.isOmissis , 'enabled-omissis':
                            segnalanteCognomeSegment && segnalanteCognomeSegment[0]?.isOmissis}">
                            {{ segnalanteCognomeSegment[0].parola }}
                            <!--                             <mat-icon class="pointer mostra-parola-in-chiaro"
                              *ngIf="(segnalanteCognomeSegment[0].parola === '*OMISSIS*' ) && modalitaOscuramento"
                              matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                              (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalanteCognomeSegment[0])">
                              'visibility'
                            </mat-icon> -->
                          </mat-chip>
                          <input (keydown)="onKeyDown($event)" matInput i18n-placeholder placeholder="Cognome"
                            [matChipInputFor]="chipListCognomeSegnalante" type="search"
                            class="form-control contenuto-segnalazione" [readonly]="true" maxlength="1" size="1">
                        </mat-chip-list>
                      </mat-form-field>
                    </div>

                  </div>

                  <div [ngClass]="screenWidth>=500 ? 'flex-row' : 'flex-column'"
                    style=" position:relative; margin-top:20px;">
                    <div class="flex-column">
                      <mat-form-field class="form-group" style="flex:1 1 100%">
                        <input (keydown)="onKeyDown($event)" matInput autocompleteOff
                          formControlName="codiceFiscaleSegnalante" i18n-placeholder
                          placeholder="Codice Fiscale o altro numero di identificazione personale"
                          class="form-control contenuto-segnalazione" id="codiceFiscaleSegnalante"
                          *ngIf="!modalitaOmissis  || modalitaOscuramento"
                          (input)="validateCodiceFiscale(segnalazioneForm.controls.codiceFiscaleSegnalante.value)" />
                        <mat-chip-list *ngIf="modalitaOmissis && !modalitaOscuramento" #chipListCodiceFiscaleSegnalante>
                          <mat-chip [selectable]="selectable" [removable]="modalitaOmissis"
                            *ngIf="segnalanteCodiceFiscaleSegment && segnalanteCodiceFiscaleSegment[0]" (click)="segnalazione.segnalante.codiceFiscale?.trim() !== 'OMISSIS' && segnalazione.segnalante.codiceFiscale?.trim() !== '*OMISSIS*' ?
                            toggleOmissis(segnalanteCodiceFiscaleSegment[0], true) :
                            nonPuoiGestireOmissis()" [ngClass]="{'disabled-omissis': segnalanteCodiceFiscaleSegment && !segnalanteCodiceFiscaleSegment[0]?.isOmissis , 'enabled-omissis':
                            segnalanteCodiceFiscaleSegment && segnalanteCodiceFiscaleSegment[0]?.isOmissis}">
                            {{ segnalanteCodiceFiscaleSegment[0].parola }}
                            <!--                             <mat-icon class="pointer mostra-parola-in-chiaro"
                              *ngIf="(segnalanteCodiceFiscaleSegment[0].parola === '*OMISSIS*' ) && modalitaOscuramento"
                              matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                              (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalanteCodiceFiscaleSegment[0])">
                              'visibility'
                            </mat-icon> -->
                          </mat-chip>
                          <input (keydown)="onKeyDown($event)" matInput i18n-placeholder
                            placeholder="Codice Fiscale o altro numero di identificazione personale"
                            [matChipInputFor]="chipListCodiceFiscaleSegnalante"
                            class="form-control contenuto-segnalazione" [readonly]="true" maxlength="1" size="1" />
                        </mat-chip-list>
                      </mat-form-field>
                      <!--div
                        *ngIf="!isCodiceFiscaleValid && !(segnalazioneForm.controls.codiceFiscaleSegnalante?.dirty || segnalazioneForm.controls.codiceFiscaleSegnalante?.touched)">
                        <mat-error i18n>Il codice fiscale inserito potrebbe non essere corretto</mat-error>
                      </div>
                      <div
                        *ngIf="!isCodiceFiscaleValid && (segnalazioneForm.controls.codiceFiscaleSegnalante?.dirty || segnalazioneForm.controls.codiceFiscaleSegnalante?.touched)"
                        class="alert alert-danger posizione-errori-password errori-form">
                        <mat-error i18n>Il codice fiscale inserito potrebbe non essere corretto, ricontrollalo prima di
                          salvare la segnalazione</mat-error>
                      </div>
                      <div *ngIf="segnalazioneForm.controls.codiceFiscaleSegnalante?.invalid && (segnalazioneForm.controls.codiceFiscaleSegnalante?.dirty ||
                            segnalazioneForm.controls.codiceFiscaleSegnalante?.touched)"
                        class="alert alert-danger posizione-errori-password errori-form">
                      </div-->
                    </div>
                  </div>
                  <div [ngClass]="screenWidth>=500 ? 'flex-row' : 'flex-column'"
                    style=" position:relative; margin-top:20px;">
                    <div class="flex-fill">
                      <mat-form-field class="form-group full">
                        <input (keydown)="onKeyDown($event)" matInput autocompleteOff i18n-placeholder
                          placeholder="Indirizzo di Residenza" required formControlName="indirizzoDiResidenzaSegnalante"
                          class="form-control contenuto-segnalazione" id="indirizzoDiResidenzaSegnalante"
                          *ngIf="!modalitaOmissis  || modalitaOscuramento" autocompleteOff />
                        <mat-chip-list *ngIf="modalitaOmissis && !modalitaOscuramento" #chipListIndirizzoDiResidenza>
                          <mat-chip [selectable]="selectable"
                            *ngIf="segnalanteIndirizzoDiResidenzaSegment && segnalanteIndirizzoDiResidenzaSegment[0]"
                            [removable]="modalitaOmissis && segnalazione.segnalante.indirizzoDiResidenza?.trim() !== 'OMISSIS' &&
                            segnalazione.segnalante.indirizzoDiResidenza?.trim() !== '*OMISSIS*'" (click)="segnalazione.segnalante.indirizzoDiResidenza?.trim() !== 'OMISSIS' && segnalazione.segnalante.indirizzoDiResidenza?.trim() !== '*OMISSIS*' ?
                            toggleOmissis(segnalanteIndirizzoDiResidenzaSegment[0], true) :
                            nonPuoiGestireOmissis()"
                            [ngClass]="{'disabled-omissis': segnalanteIndirizzoDiResidenzaSegment && !segnalanteIndirizzoDiResidenzaSegment[0]?.isOmissis , 'enabled-omissis':
                            segnalanteIndirizzoDiResidenzaSegment && segnalanteIndirizzoDiResidenzaSegment[0]?.isOmissis}">
                            {{ segnalanteIndirizzoDiResidenzaSegment[0].parola }}
                            <!--                             <mat-icon class="pointer mostra-parola-in-chiaro"
                              *ngIf="(segnalanteIndirizzoDiResidenzaSegment[0].parola === '*OMISSIS*' ) && modalitaOscuramento"
                              matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                              (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalanteIndirizzoDiResidenzaSegment[0])">
                              'visibility'
                            </mat-icon> -->
                          </mat-chip>
                          <input (keydown)="onKeyDown($event)" matInput i18n-placeholder
                            placeholder="Indirizzo di Residenza" [matChipInputFor]="chipListIndirizzoDiResidenza"
                            class="form-control contenuto-segnalazione" [readonly]="true" maxlength="1" size="1">
                        </mat-chip-list>
                      </mat-form-field>
                    </div>
                  </div>


                  <div [ngClass]="screenWidth>=500 ? 'flex-row' : 'flex-column'"
                    style=" position:relative; margin-top:20px;">
                    <div class="flex-column">
                      <mat-form-field class="form-group">
                        <input (keydown)="onKeyDown($event)" matInput autocompleteOff i18n-placeholder placeholder="CAP"
                          required formControlName="capSegnalante" class="form-control contenuto-segnalazione"
                          id="capSegnalante" *ngIf="!modalitaOmissis  || modalitaOscuramento" autocompleteOff />
                        <mat-error i18n
                          *ngIf="formSegnalazione.control.controls.capSegnalante.errors?.pattern && formSegnalazione.control.controls.capSegnalante.touched ">
                          Il CAP deve avere una lunghezza compresa tra 3 e 9 caratteri
                        </mat-error>
                        <mat-chip-list *ngIf="modalitaOmissis  &&  !modalitaOscuramento" #chipListCapSegnalante>
                          <mat-chip [selectable]="selectable" *ngIf="segnalanteCapSegment && segnalanteCapSegment[0]"
                            [removable]="modalitaOmissis && segnalazione.segnalante.cap?.trim() !== 'OMISSIS' && segnalazione.segnalante.cap?.trim() !== '*OMISSIS*'"
                            (click)="segnalazione.segnalante.cap?.trim() !== 'OMISSIS' && segnalazione.segnalante.cap?.trim() !== '*OMISSIS*' ?
                            toggleOmissis(segnalanteCapSegment[0], true) :
                            nonPuoiGestireOmissis()" [ngClass]="{'disabled-omissis': segnalanteCapSegment && !segnalanteCapSegment[0]?.isOmissis , 'enabled-omissis':
                            segnalanteCapSegment && segnalanteCapSegment[0]?.isOmissis}">
                            {{ segnalanteCapSegment[0].parola }}
                            <!--                             <mat-icon class="pointer mostra-parola-in-chiaro"
                              *ngIf="(segnalanteCapSegment[0].parola === '*OMISSIS*' ) && modalitaOscuramento"
                              matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                              (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalanteCapSegment[0])">
                              'visibility'
                            </mat-icon> -->
                          </mat-chip>
                          <input (keydown)="onKeyDown($event)" matInput i18n-placeholder placeholder="CAP"
                            [matChipInputFor]="chipListCapSegnalante" class="form-control contenuto-segnalazione"
                            [readonly]="true" maxlength="1" size="1">

                        </mat-chip-list>
                      </mat-form-field>
                    </div>

                    <div class="flex-fill">
                      <mat-form-field class="form-group">

                        <input (keydown)="onKeyDown($event)" matInput autocompleteOff i18n-placeholder
                          placeholder="Città" required formControlName="cittaSegnalante" [matAutocomplete]="autoCitta"
                          class="form-control contenuto-segnalazione" id="cittaSegnalante"
                          *ngIf="!modalitaOmissis  || modalitaOscuramento"
                          (keydown.enter)="onKeyDownEnter($event, trigger)" #trigger="matAutocompleteTrigger"
                          autocompleteOff />

                        <mat-autocomplete #autoCitta="matAutocomplete">
                          <mat-option *ngFor="let comune of filteredComuni" [value]="comune.nome">
                            <span>{{ comune.nome }}</span>
                            <small> ({{comune.regione}})</small>
                          </mat-option>
                        </mat-autocomplete>


                        <mat-chip-list *ngIf="modalitaOmissis && !modalitaOscuramento" #chipListCittaSegnalante>
                          <mat-chip [selectable]="selectable"
                            *ngIf="segnalanteCittaSegment && segnalanteCittaSegment[0]"
                            [removable]="modalitaOmissis && segnalazione.segnalante.citta?.trim() !== 'OMISSIS' && segnalazione.segnalante.citta?.trim() !== '*OMISSIS*'"
                            (click)="segnalazione.segnalante.citta?.trim() !== 'OMISSIS' && segnalazione.segnalante.citta?.trim() !== '*OMISSIS*'? toggleOmissis(segnalanteCittaSegment[0], true) :
                            nonPuoiGestireOmissis()" [ngClass]="{'disabled-omissis': segnalanteCittaSegment && !segnalanteCittaSegment[0]?.isOmissis, 'enabled-omissis':
                            segnalanteCittaSegment && segnalanteCittaSegment[0]?.isOmissis}">
                            {{ segnalanteCittaSegment[0].parola }}
                            <!--                             <mat-icon class="pointer mostra-parola-in-chiaro"
                              *ngIf="(segnalanteCittaSegment[0].parola === '*OMISSIS*') && modalitaOscuramento"
                              matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                              (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalanteCittaSegment[0])">
                              'visibility'
                            </mat-icon> -->
                          </mat-chip>
                          <input (keydown)="onKeyDown($event)" matInput i18n-placeholder placeholder="Città"
                            [matChipInputFor]="chipListCittaSegnalante" class="form-control contenuto-segnalazione"
                            [readonly]="true" maxlength="1" size="1">
                        </mat-chip-list>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="flex-row" style=" position:relative; margin-top:20px;">
                    <div class="flex-fill">
                      <mat-form-field class="form-group full">
                        <input (keydown)="onKeyDown($event)" matInput autocompleteOff i18n-placeholder
                          placeholder="Numero di Telefono" formControlName="telefonoSegnalante"
                          class="form-control contenuto-segnalazione" id="telefonoSegnalante"
                          *ngIf="!modalitaOmissis  || modalitaOscuramento" autocompleteOff />
                        <mat-error i18n
                          *ngIf="formSegnalazione.control.controls.telefonoSegnalante.errors?.min || formSegnalazione.control.controls.telefonoSegnalante.errors?.max">
                          Il numero telefonico introdotto non sembra valido. Sono ammesse solo 9 o 10 cifre numeriche
                        </mat-error>
                        <mat-error i18n
                          *ngIf="formSegnalazione.control.controls.telefonoSegnalante.errors?.pattern && formSegnalazione.control.controls.telefonoSegnalante.touched">
                          E' ammesso il formato con prefisso internazionale digitando + seguito da 1 a 3 numeri, quindi
                          il segno - ed un numero di lunghezza compresa tra 7 e 15 cifre numeriche</mat-error>
                        <mat-chip-list *ngIf="modalitaOmissis && !modalitaOscuramento" #chipListTelefonoSegnalante>
                          <mat-chip [selectable]="selectable"
                            *ngIf="segnalanteTelefonoSegment && segnalanteTelefonoSegment[0]"
                            [removable]="modalitaOmissis && segnalazione.segnalante.telefono?.trim() !== 'OMISSIS' && segnalazione.segnalante.telefono?.trim() !== '*OMISSIS*'"
                            (click)="segnalazione.segnalante.telefono?.trim() !== 'OMISSIS' && segnalazione.segnalante.telefono?.trim() !== '*OMISSIS*' ?
                            toggleOmissis(segnalanteTelefonoSegment[0], true) :
                            nonPuoiGestireOmissis()" [ngClass]="{'disabled-omissis': segnalanteTelefonoSegment && !segnalanteTelefonoSegment[0]?.isOmissis, 'enabled-omissis':
                            segnalanteTelefonoSegment && segnalanteTelefonoSegment[0]?.isOmissis}">
                            {{ segnalanteTelefonoSegment[0].parola }}
                            <!--                             <mat-icon class="pointer mostra-parola-in-chiaro"
                              *ngIf="(segnalanteTelefonoSegment[0].parola === '*OMISSIS*' ) && modalitaOscuramento"
                              matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                              (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalanteTelefonoSegment[0])">
                              'visibility'
                            </mat-icon> -->
                          </mat-chip>
                          <input (keydown)="onKeyDown($event)" matInput i18n-placeholder placeholder="Telefono"
                            [matChipInputFor]="chipListTelefonoSegnalante" class="form-control contenuto-segnalazione"
                            [readonly]="true" maxlength="1" size="1">
                        </mat-chip-list>
                      </mat-form-field>
                    </div>

                    <div class="flex-fill">
                      <mat-form-field class="form-group full">
                        <input (keydown)="onKeyDown($event)" matInput autocompleteOff i18n-placeholder
                          placeholder="Indirizzo Email" required formControlName="emailSegnalante"
                          class="form-control contenuto-segnalazione" id="emailSegnalante"
                          *ngIf="!modalitaOmissis  || modalitaOscuramento" autocompleteOff />
                        <mat-error i18n
                          *ngIf="formSegnalazione.control.controls.emailSegnalante.errors?.emailNonValida && formSegnalazione.control.controls.emailSegnalante.touched">
                          Email non valida</mat-error>
                        <mat-error i18n
                          *ngIf="formSegnalazione.control.controls.emailSegnalante.errors?.dominioNonPermesso ">
                          Non è consentito l'utilizzo di email appartenenti ai seguenti domini: {{dominiPermessi}}
                        </mat-error>

                        <mat-chip-list *ngIf="modalitaOmissis && !modalitaOscuramento" #chipListEmailSegnalante>
                          <mat-chip [selectable]="selectable"
                            *ngIf="segnalanteEmailSegment && segnalanteEmailSegment[0]"
                            [removable]="modalitaOmissis && segnalazione.segnalante.email?.trim() !== 'OMISSIS' && segnalazione.segnalante.email?.trim() !== '*OMISSIS*'"
                            (click)="segnalazione.segnalante.email?.trim() !== 'OMISSIS' && segnalazione.segnalante.email?.trim() !== '*OMISSIS*'  ?
                            toggleOmissis(segnalanteEmailSegment[0], true) :
                            nonPuoiGestireOmissis()" [ngClass]="{'disabled-omissis': segnalanteEmailSegment && !segnalanteEmailSegment[0]?.isOmissis , 'enabled-omissis':
                            segnalanteEmailSegment && segnalanteEmailSegment[0]?.isOmissis}">
                            {{ segnalanteEmailSegment[0].parola }}
                            <!--                             <mat-icon class="pointer mostra-parola-in-chiaro"
                              *ngIf="(segnalanteEmailSegment[0].parola === '*OMISSIS*' ) && modalitaOscuramento"
                              matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                              (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalanteEmailSegment[0])">
                              'visibility'
                            </mat-icon> -->
                          </mat-chip>
                          <input (keydown)="onKeyDown($event)" matInput i18n-placeholder placeholder="Indirizzo Email"
                            [matChipInputFor]="chipListEmailSegnalante" class="form-control contenuto-segnalazione"
                            [readonly]="true" maxlength="1" size="1">
                        </mat-chip-list>
                      </mat-form-field>
                    </div>
                  </div>

                  <div style=" position:relative; margin-top:20px;">
                    <mat-form-field class="form-group width-half">

                      <mat-chip-list *ngIf="modalitaOmissis && !modalitaOscuramento"
                        #chipListRapportoConLaSocietaSegnalante>
                        <mat-chip [selectable]="selectable"
                          *ngIf="segnalanteRapportoConLaSocietaSegment && segnalanteRapportoConLaSocietaSegment[0]"
                          [removable]="modalitaOmissis && segnalazione.segnalante.rapportoConLaSocieta?.trim() !== 'OMISSIS' && segnalazione.segnalante.rapportoConLaSocieta?.trim() !== '*OMISSIS*'"
                          (click)="segnalazione.segnalante.rapportoConLaSocieta?.trim() !== 'OMISSIS' &&  segnalazione.segnalante.rapportoConLaSocieta?.trim() !== '*OMISSIS*'   ?
                          toggleOmissis(segnalanteRapportoConLaSocietaSegment[0], true) :
                          nonPuoiGestireOmissis()"
                          [ngClass]="{'disabled-omissis': segnalanteRapportoConLaSocietaSegment && !segnalanteRapportoConLaSocietaSegment[0]?.isOmissis, 'enabled-omissis':
                          segnalanteRapportoConLaSocietaSegment && segnalanteRapportoConLaSocietaSegment[0]?.isOmissis}">
                          {{ getTranslatedText(segnalanteRapportoConLaSocietaSegment[0].parola) }}
                          <!--                           <mat-icon class="pointer mostra-parola-in-chiaro"
                            *ngIf="(segnalanteRapportoConLaSocietaSegment[0].parola === '*OMISSIS*') && modalitaOscuramento"
                            matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                            (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalanteRapportoConLaSocietaSegment[0])">
                            'visibility'
                          </mat-icon> -->
                        </mat-chip>
                        <input (keydown)="onKeyDown($event)" matInput i18n-placeholder
                          placeholder="Rapporto con la Società"
                          [matChipInputFor]="chipListRapportoConLaSocietaSegnalante"
                          class="form-control contenuto-segnalazione" maxlength="1" size="1">
                      </mat-chip-list>

                      <mat-label class="testo-label-scalabile" i18n
                        *ngIf="!modalitaOmissis && !readOnly() && !datiSegnalanteInModifica">Rapporto con
                        la società
                      </mat-label>
                      <mat-select formControlName="rapportoConLaSocietaSegnalante" required
                        *ngIf="!modalitaOmissis && (!readOnly() || datiSegnalanteInModifica)"
                        class="contenuto-segnalazione">
                        <mat-option *ngFor="let rapporto of rapportiConSegnalanteOptions" [value]="rapporto">
                          {{ getTranslatedText(rapporto) }}
                        </mat-option>
                      </mat-select>
                      <input (keydown)="onKeyDown($event)" matInput i18n-placeholder
                        placeholder="Rapporto con la Società" class="contenuto-segnalazione"
                        *ngIf="(!modalitaOmissis || modalitaOscuramento) && readOnly() && !datiSegnalanteInModifica"
                        [value]="getTranslatedText(segnalazione.segnalante.rapportoConLaSocieta)" />

                    </mat-form-field>
                    <div>
                    </div>
                  </div>


                  <div style=" position:relative;">

                    <mat-form-field class="form-group width-full">
                      <input (keydown)="onKeyDown($event)" matInput autocompleteOff formControlName="incarico"
                        i18n-placeholder placeholder="Incarico attuale" class="form-control contenuto-segnalazione"
                        id="incarico" *ngIf="!modalitaOmissis  || modalitaOscuramento" />

                      <mat-chip-list *ngIf="modalitaOmissis && !modalitaOscuramento" #chipListIncaricoSegnalante>
                        <mat-chip [selectable]="selectable" [removable]="modalitaOmissis"
                          *ngIf="segnalanteIncaricoSegment && segnalanteIncaricoSegment[0]" (click)="segnalazione.segnalante.incarico?.trim() !== 'OMISSIS' && segnalazione.segnalante.incarico?.trim() !== '*OMISSIS*'  ?
                          toggleOmissis(segnalanteIncaricoSegment[0], true) :
                          nonPuoiGestireOmissis()" [ngClass]="{'disabled-omissis': segnalanteIncaricoSegment && !segnalanteIncaricoSegment[0]?.isOmissis , 'enabled-omissis':
                          segnalanteIncaricoSegment && segnalanteIncaricoSegment[0]?.isOmissis}">
                          {{ segnalanteIncaricoSegment[0].parola }}
                          <!--                           <mat-icon class="pointer mostra-parola-in-chiaro"
                            *ngIf="(segnalanteIncaricoSegment[0].parola === '*OMISSIS*' ) && modalitaOscuramento"
                            matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                            (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalanteIncaricoSegment[0])">
                            'visibility'
                          </mat-icon> -->
                        </mat-chip>
                        <input (keydown)="onKeyDown($event)" matInput i18n-placeholder placeholder="Incarico attuale"
                          [matChipInputFor]="chipListIncaricoSegnalante" class="contenuto-segnalazione"
                          [readonly]="true" maxlength="1" size="1">
                      </mat-chip-list>
                    </mat-form-field>
                  </div>
                </div>
                <div style="position:relative; margin-top:20px;"
                  *ngIf="segnalazioniAnonimeConsentite && (!readOnly() || (readOnly() && segnalazione.anonima))">
                  <mat-checkbox #anonima matInput type="checkbox" formControlName="anonima" [disabled]="readOnly()"
                    (click)="onAnonimaClick(anonima.checked)"></mat-checkbox>
                  <mat-label class="testo-label-scalabile" i18n style="padding: 6px;">Segnalazione Anonima</mat-label>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel id="consenso" [expanded]="true"
                *ngIf="isMiaSegnalazione() && !isBozza() && !segnalazione.anonima">
                <mat-expansion-panel-header style="height:auto">
                  <mat-panel-title i18n>
                    Richieste di Consenso sull'invio a terzi dei dati identificativi del segnalante
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <app-elenco-consensi-segnalante [segnalazione]="segnalazione"></app-elenco-consensi-segnalante>
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="true" id="dettagliSegnalazione" name="Dettagli Segnalazione"
                class="scheda-full-width" *ngIf="!segnalazioneVocale">
                <mat-expansion-panel-header class="sottotestata-principale-dettagli-segnalazione">
                  <mat-panel-title i18n>
                    Dettagli Segnalazione
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div style="position:relative; ">
                  <div class="flex-row">
                    <mat-form-field class="form-group width-half" *ngIf="!modalitaOmissis">
                      <mat-label class="testo-label-scalabile" i18n>Periodo in cui si è verificato il fatto</mat-label>
                      <input (keydown)="onKeyDown($event)" *ngIf="readOnly()" matInput type="text" [readonly]="true"
                        maxlength="1" size="1" class="form-control contenuto-segnalazione" disabled
                        [value]="getTranslatedText(segnalazione.periodo?.toString())">
                      <mat-select *ngIf="!readOnly()" formControlName="periodo" i18n-placeholder
                        placeholder="Periodo in cui si è verificato il fatto">
                        <mat-option *ngFor="let periodo of periodoDelFattoOptions" [value]="periodo">
                          {{ getTranslatedText(periodo) }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="form-group width-half" *ngIf="modalitaOmissis">
                      <mat-label class="testo-label-scalabile" i18n>Periodo in cui si è verificato il fatto</mat-label>
                      <mat-chip-list *ngIf="modalitaOmissis" #chipListPeriodo>
                        <mat-chip [selectable]="selectable" [removable]="modalitaOmissis"
                          *ngIf="segnalazionePeriodoSegment && segnalazionePeriodoSegment[0]" (click)="segnalazione.periodo?.trim() !== 'OMISSIS' && segnalazione.periodo?.trim() !== '*OMISSIS*' ?
                          toggleOmissis(segnalazionePeriodoSegment[0], true) :
                          nonPuoiGestireOmissis()" [ngClass]="{'disabled-omissis': segnalazionePeriodoSegment && !segnalazionePeriodoSegment[0]?.isOmissis , 'enabled-omissis':
                          segnalazionePeriodoSegment && segnalazionePeriodoSegment[0]?.isOmissis}">
                          {{ getTranslatedText(segnalazionePeriodoSegment[0].parola) }}
                          <!--                           <mat-icon class="pointer mostra-parola-in-chiaro"
                            *ngIf="(segnalazionePeriodoSegment[0].parola === '*OMISSIS*' ) && modalitaOscuramento"
                            matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                            (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalazionePeriodoSegment[0])">
                            'visibility'
                          </mat-icon> -->
                        </mat-chip>
                        <input (keydown)="onKeyDown($event)" matInput type="text" [readonly]="true"
                          [matChipInputFor]="chipListPeriodo" maxlength="1" size="1"
                          class="form-control contenuto-segnalazione" disabled>
                      </mat-chip-list>
                    </mat-form-field>
                  </div>
                  <div class="flex-row">
                    <mat-form-field class="form-group" style="top:3px; width:95%!important"
                      *ngIf="!modalitaOmissis && segnalazione.data?.toString() !== 'OMISSIS' && segnalazione.data?.toString() !== '*OMISSIS*'">
                      <mat-label class="testo-label-scalabile" i18n>Data</mat-label>
                      <input (click)="picker.open()" readonly matInput autocompleteOff [matDatepicker]="picker"
                        formControlName="data" class="contenuto-segnalazione" (dateInput)="addEvent('input', $event)" />
                      <mat-datepicker-toggle class="accent" matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker class="no-print"></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="form-group" style="top:3px; width:95%!important"
                      *ngIf="!modalitaOmissis && segnalazione.data?.toString() === 'OMISSIS' && segnalazione.data?.toString() === '*OMISSIS*'">
                      <mat-label class="testo-label-scalabile" i18n>Data</mat-label>
                      <input readonly matInput autocompleteOff formControlName="data" class="contenuto-segnalazione"
                        (dateInput)="addEvent('input', $event)" />
                    </mat-form-field>

                    <mat-form-field class="form-group width-half-start" style="top:3px" *ngIf="modalitaOmissis">
                      <mat-label class="testo-label-scalabile" i18n>Data</mat-label>
                      <mat-chip-list #chipListData>
                        <mat-chip [selectable]="selectable" [removable]="modalitaOmissis"
                          *ngIf="segnalazioneDataSegment && segnalazioneDataSegment[0]" (click)="segnalazione.data?.toString() !== 'OMISSIS' && segnalazione.data?.toString() !== '*OMISSIS*' ?
                          toggleOmissis(segnalazioneDataSegment[0], true) :
                        nonPuoiGestireOmissis()" [ngClass]="{'disabled-omissis': segnalazioneDataSegment && !segnalazioneDataSegment[0].isOmissis ,
                          'enabled-omissis': segnalazioneDataSegment && segnalazioneDataSegment[0].isOmissis}">
                          {{ segnalazioneDataSegment[0]?.parola.toString() !== 'OMISSIS' &&
                          segnalazioneDataSegment[0]?.parola.toString() !== '*OMISSIS*' ?
                          (segnalazioneDataSegment[0].parola | date: 'medium') :
                          'OMISSIS' }}
                          <!--                           <mat-icon class="pointer mostra-parola-in-chiaro"
                            *ngIf="(segnalazioneDataSegment[0].parola === '*OMISSIS*' ) && modalitaOscuramento"
                            matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                            (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalazioneDataSegment[0])">
                            'visibility'
                          </mat-icon> -->
                        </mat-chip>
                        <input (keydown)="onKeyDown($event)" matInput type="text" i18n-placeholder [readonly]="true"
                          [matChipInputFor]="chipListData" maxlength="1" size="1"
                          class="form-control contenuto-segnalazione input-corti" disabled>
                      </mat-chip-list>
                    </mat-form-field>

                    <mat-form-field class="flex-0-row" style="top:3px;" *ngIf="!modalitaOmissis">
                      <div class="flex-0-row">
                        <input (keydown)="onKeyDown($event)" style="text-align: end;" i18n-placeholder placeholder="Ora"
                          matInput type="number" min="0" max="23" formControlName="ora" (blur)="formatTime('ora')" />:
                      </div>
                    </mat-form-field>

                    <mat-form-field class="flex-0-row" style="top:3px" *ngIf="!modalitaOmissis">
                      <div class="flex-0-row">
                        <input (keydown)="onKeyDown($event)" matInput type="number" min="0" max="59" step="1"
                          formControlName="minuti" (blur)="formatTime('minuti')" />
                      </div>
                    </mat-form-field>

                  </div>
                </div>

                <div class="sottotestata-principale-dettagli-segnalazione testo-label-scalabile"
                  style="margin-top:20px;" *ngIf="
                (!segnalazione.segnalati || segnalazione.segnalati.length == 0) && puoConsultareSegnalati()" i18n>
                  Nessun Segnalato
                </div>
                <div *ngIf="segnalazione.segnalati && segnalazione.segnalati.length > 0 &&
              !puoConsultareSegnalati()">
                  <H1 i18n>Non sei abilitato alla consultazione dei dati delle persone
                    segnalate</H1>
                </div>
                <div class="sottotestata-principale-dettagli-segnalazione testo-label-scalabile"
                  style="margin-top:20px;" *ngIf="!readOnly()" i18n>
                  Aggiungi Segnalato
                  <button type="button" i18n-matTooltip matTooltip="Aggiungi Segnalato" mat-icon-button class="sinistra"
                    (click)="aggiungiSegnalato($event); $event.stopPropagation()">
                    <mat-icon class="icone-testata-tabelle" style="margin-top: -13px!important;"
                      aria-label="aggiungi segnalato">add_circle
                    </mat-icon>
                  </button>
                </div>
                <div
                  *ngIf="(puoConsultareSegnalati() || !readOnly()) && (segnalazione.segnalati && segnalazione.segnalati.length > 0)">
                  <mat-expansion-panel
                    *ngFor="let segnalato of ( modalitaOmissis ? segnalazione.segnalati: segnalazione.segnalati); index as i"
                    [expanded]="true" id="segnalato{{ i }}" class="scheda-full-width">
                    <mat-expansion-panel-header class="sottotestata-principale-dettagli-segnalazione">
                      <mat-panel-title i18n> Segnalato {{ i + 1 }} </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngIf="
                    segnalazione.segnalatoSbloccato ||
                    !odv || (odv && segnalazione.id == undefined) ||
                    mittenteSegnalazione()
                  ">
                      <mat-form-field class="form-group width-third">
                        <input (keydown)="onKeyDown($event)" matInput autocompleteOff autocompleteOff
                          formControlName="nome_segnalato_{{ i  }}" class="form-control contenuto-segnalazione"
                          id="nomeSegnalato{{ i  }}" *ngIf="!modalitaOmissis" />
                        <mat-label i18n class="label-segnalazione testo-label-scalabile ">Nome</mat-label>
                        <mat-chip-list *ngIf="modalitaOmissis">
                          <mat-chip [selectable]="selectable" [removable]="modalitaOmissis"
                            *ngIf="segnalatoNomeSegments && segnalatoNomeSegments[i] && segnalatoNomeSegments[i][0]"
                            (click)="segnalato.nome !== 'OMISSIS' && segnalato.nome !== '*OMISSIS*' ? toggleOmissis(segnalatoNomeSegments[i][0], true, i) :
                            nonPuoiGestireOmissis()" [ngClass]="{'disabled-omissis': !segnalatoNomeSegments[i][0]?.isOmissis,
                        'enabled-omissis': segnalatoNomeSegments[i][0]?.isOmissis}">
                            {{ segnalatoNomeSegments[i][0].parola }}
                            <!--                             <mat-icon class="pointer mostra-parola-in-chiaro"
                              *ngIf="(segnalatoNomeSegments[i][0].parola === '*OMISSIS*' ) && modalitaOscuramento"
                              matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                              (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalatoNomeSegments[i][0])">
                              'visibility'
                            </mat-icon> -->
                          </mat-chip>
                        </mat-chip-list>
                      </mat-form-field>
                      <mat-form-field class="form-group width-third">
                        <input (keydown)="onKeyDown($event)" matInput autocompleteOff
                          formControlName="cognome_segnalato_{{ i  }}" class="form-control contenuto-segnalazione"
                          id="cognomeSegnalato{{ i  }}" *ngIf="!modalitaOmissis" />
                        <mat-label i18n class="label-segnalazione testo-label-scalabile">Cognome</mat-label>
                        <mat-chip-list *ngIf="modalitaOmissis">
                          <mat-chip [selectable]="selectable" [removable]="modalitaOmissis"
                            *ngIf="segnalatoCognomeSegments && segnalatoCognomeSegments[i] && segnalatoCognomeSegments[i][0]"
                            (click)="segnalato.cognome !== 'OMISSIS' && segnalato.cognome !== '*OMISSIS*' ? toggleOmissis(segnalatoCognomeSegments[i][0], true, i) :
                            nonPuoiGestireOmissis()" [ngClass]="{'disabled-omissis': !segnalatoCognomeSegments[i][0]?.isOmissis,
                        'enabled-omissis': segnalatoCognomeSegments[i][0]?.isOmissis}">
                            {{ segnalatoCognomeSegments[i][0].parola }}
                            <!--                             <mat-icon class="pointer mostra-parola-in-chiaro"
                              *ngIf="(segnalatoCognomeSegments[i][0].parola === '*OMISSIS*' ) && modalitaOscuramento"
                              matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                              (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalatoCognomeSegments[i][0])">
                              'visibility'
                            </mat-icon> -->
                          </mat-chip>
                        </mat-chip-list>
                      </mat-form-field>
                      <mat-form-field class="form-group width-third">
                        <input (keydown)="onKeyDown($event)" matInput autocompleteOff
                          formControlName="qualifica_segnalato_{{ i  }}" class="form-control contenuto-segnalazione"
                          id="qualificaSegnalato{{ i  }}" *ngIf="!modalitaOmissis" />
                        <mat-label i18n class="label-segnalazione testo-label-scalabile">Qualifica</mat-label>
                        <mat-chip-list *ngIf="modalitaOmissis">
                          <mat-chip [selectable]="selectable" [removable]="modalitaOmissis"
                            *ngIf="segnalatoQualificaSegments && segnalatoQualificaSegments[i] && segnalatoQualificaSegments[i][0]"
                            (click)="segnalato.qualifica !== 'OMISSIS' && segnalato.qualifica !== '*OMISSIS*' ?
                            toggleOmissis(segnalatoQualificaSegments[i][0], true) :
                            nonPuoiGestireOmissis()" [ngClass]="{'disabled-omissis': !segnalatoQualificaSegments[i][0]?.isOmissis,
                        'enabled-omissis': segnalatoQualificaSegments[i][0]?.isOmissis}">
                            {{ segnalatoQualificaSegments[i][0].parola }}
                            <!--                             <mat-icon class="pointer mostra-parola-in-chiaro"
                              *ngIf="(segnalatoQualificaSegments[i][0].parola === '*OMISSIS*' ) && modalitaOscuramento"
                              matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                              (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segnalatoQualificaSegments[i][0])">
                              'visibility'
                            </mat-icon> -->
                          </mat-chip>
                        </mat-chip-list>
                      </mat-form-field>
                      <button type="button" class="bottone-elimina-segnalato destra" i18n-matTooltip
                        matTooltip="Rimuovi Segnalato" mat-icon-button (click)="
                      rimuoviSegnalato(segnalato, i); $event.stopPropagation()
                    " *ngIf="!readOnly()">
                        <mat-icon class="icone-testata-tabelle" color="primary" aria-label="rimuovi segnalato">delete
                        </mat-icon>
                      </button>
                    </div>
                  </mat-expansion-panel>
                  <div *ngIf="
                    !segnalazione.segnalatoSbloccato &&
                    odv &&
                    readOnly() &&
                    !mittenteSegnalazione()
                  ">
                    <H1 i18n>Dati non visibili, sbloccare per consultazione</H1>
                    <button mat-fab (click)="sbloccaSegnalato()">
                      <mat-icon class="icona-fab" aria-label="locked">lock_open</mat-icon>
                    </button>
                  </div>
                </div>

                <div style="margin-top:20px;">
                  <mat-form-field class="form-group width-half min-width">
                    <mat-label class="testo-label-scalabile" i18n>Eventuali imprese coinvolte</mat-label>
                    <textarea maxlength="20000" matInput *ngIf="!modalitaOmissis" formControlName="altreImprese"
                      class="form-control contenuto-segnalazione" id="altre_imprese" matTextareaAutosize></textarea>
                    <mat-chip-list #chipListAltreImprese *ngIf="modalitaOmissis">
                      <mat-chip *ngFor="let segmento of segnalazioneAltreImpreseSegments" [selectable]="selectable"
                        [removable]="modalitaOmissis && segmento.parola?.trim()!== 'OMISSIS' && segmento.parola?.trim()!== '*OMISSIS*'"
                        (click)="segmento.parola?.trim()!== 'OMISSIS'  && segmento.parola?.trim()!== '*OMISSIS*' ? toggleOmissis(segmento) :
                         nonPuoiGestireOmissis()"
                        [ngClass]="{'disabled-omissis': !segmento?.isOmissis , 'enabled-omissis': segmento?.isOmissis }">
                        {{ segmento.parola }}
                        <!--                         <mat-icon class="pointer mostra-parola-in-chiaro"
                          *ngIf="(segmento.parola === '*OMISSIS*' ) && modalitaOscuramento"
                          matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                          (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segmento)">
                          'visibility'
                        </mat-icon> -->
                      </mat-chip>
                      <input (keydown)="onKeyDown($event)" matInput autocompleteOff
                        [matChipInputFor]="chipListAltreImprese" class="contenuto-segnalazione" [readonly]="true"
                        maxlength="1" size="1">
                    </mat-chip-list>
                  </mat-form-field>
                  <mat-form-field class="form-group width-half min-width">
                    <mat-label class="testo-label-scalabile" i18n>Eventuali soggetti privati coinvolti</mat-label>
                    <textarea maxlength="20000" matInput *ngIf="!modalitaOmissis" formControlName="altriSoggetti"
                      class="form-control contenuto-segnalazione" id="altri_soggetti" matTextareaAutosize></textarea>
                    <mat-chip-list #chipListAltriSoggetti *ngIf="modalitaOmissis">
                      <mat-chip *ngFor="let segmento of segnalazioneAltriSoggettiSegments" [selectable]="selectable"
                        [removable]="modalitaOmissis && segmento.parola?.trim()!== 'OMISSIS' && segmento.parola?.trim()!== '*OMISSIS*'"
                        (click)="segmento.parola?.trim()!== 'OMISSIS'  && segmento.parola?.trim()!== '*OMISSIS*' ? toggleOmissis(segmento) :
                        nonPuoiGestireOmissis()"
                        [ngClass]="{'disabled-omissis': !segmento?.isOmissis, 'enabled-omissis': segmento?.isOmissis }">
                        {{ segmento.parola }}
                        <!--                         <mat-icon class="pointer mostra-parola-in-chiaro"
                          *ngIf="(segmento.parola === '*OMISSIS*' ) && modalitaOscuramento"
                          matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                          (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segmento)">
                          'visibility'
                        </mat-icon> -->
                      </mat-chip>
                      <input (keydown)="onKeyDown($event)" matInput autocompleteOff
                        [matChipInputFor]="chipListAltriSoggetti" class="contenuto-segnalazione" [readonly]="true"
                        maxlength="1" size="1">
                    </mat-chip-list>
                  </mat-form-field>
                </div>
                <div style="margin-top:20px;">
                  <mat-form-field class="form-group width-half min-width">
                    <mat-label i18n class="testo-label-scalabile">Modalità con cui è venuto a conoscenza del
                      fatto</mat-label>
                    <textarea maxlength="20000" matInput *ngIf="!modalitaOmissis" formControlName="mod"
                      class="form-control contenuto-segnalazione" id="mod" matTextareaAutosize></textarea>
                    <mat-chip-list #chipListModalita *ngIf="modalitaOmissis">
                      <mat-chip *ngFor="let segmento of segnalazioneModalitaConoscenzaFattoSegments"
                        [selectable]="selectable"
                        [removable]="modalitaOmissis && segmento.parola?.trim()!== 'OMISSIS'  && segmento.parola?.trim()!== '*OMISSIS*'"
                        (click)="segmento.parola?.trim()!== 'OMISSIS'  && segmento.parola?.trim()!== '*OMISSIS*' ? toggleOmissis(segmento) :
                        nonPuoiGestireOmissis()"
                        [ngClass]="{'disabled-omissis': !segmento?.isOmissis, 'enabled-omissis': segmento?.isOmissis }">
                        {{ segmento.parola }}
                        <!--                         <mat-icon class="pointer mostra-parola-in-chiaro"
                          *ngIf="(segmento.parola === '*OMISSIS*' ) && modalitaOscuramento"
                          matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                          (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segmento)">
                          'visibility'
                        </mat-icon> -->
                      </mat-chip>
                      <input (keydown)="onKeyDown($event)" matInput autocompleteOff [matChipInputFor]="chipListModalita"
                        class="contenuto-segnalazione" [readonly]="true" maxlength="1" size="1">

                    </mat-chip-list>
                  </mat-form-field>
                  <mat-form-field class="form-group width-half min-width">
                    <mat-label i18n class="testo-label-scalabile">Eventuali altri soggetti / enti che possono riferire
                      sul fatto</mat-label>
                    <textarea maxlength="20000" matInput *ngIf="!modalitaOmissis" formControlName="others"
                      class="form-control contenuto-segnalazione " id="others" matTextareaAutosize></textarea>

                    <mat-chip-list #chipListAltro *ngIf="modalitaOmissis">
                      <mat-chip *ngFor="let segmento of segnalazioneAltroSegments" [selectable]="selectable"
                        [removable]="modalitaOmissis && segmento.parola?.trim()!== 'OMISSIS'  && segmento.parola?.trim()!== '*OMISSIS*'"
                        (click)="segmento.parola?.trim()!== 'OMISSIS'  && segmento.parola?.trim()!== '*OMISSIS*'? toggleOmissis(segmento) :
                        nonPuoiGestireOmissis()"
                        [ngClass]="{'disabled-omissis': !segmento?.isOmissis, 'enabled-omissis': segmento?.isOmissis }">
                        {{ segmento.parola }}
                        <!--                         <mat-icon class="pointer mostra-parola-in-chiaro"
                          *ngIf="(segmento.parola === '*OMISSIS*' ) && modalitaOscuramento"
                          matTooltip="Visualizza parola in chiaro" i18n-matTooltip
                          (click)="$event.stopPropagation(); recuperaTestoInChiaroOmissis(segmento)">
                          'visibility'
                        </mat-icon> -->
                      </mat-chip>
                      <input (keydown)="onKeyDown($event)" matInput autocompleteOff [matChipInputFor]="chipListAltro"
                        class="contenuto-segnalazione" [readonly]="true" maxlength="1" size="1">
                    </mat-chip-list>
                  </mat-form-field>
                </div>
              </mat-expansion-panel>
              <app-elenco-allegati class="alternate-theme" style="height:auto" #allegati *ngIf="true"
                [omissis]="omissis" [dirittiSegnalazione]="dirittiSegnalazione" [disabilitato]="readOnly()"
                [loading]="loadingDocumenti" (loadingDocumenti)="doLoadingDocumenti($event)"
                [entitaPrincipale]="segnalazione" [tipoEntitaPrincipale]="'segnalazione'"
                [modalitaOscuramento]="modalitaOscuramento" [modalitaOmissis]="modalitaOmissis"
                [processoOscuramento]="processoOscuramento" [modalitaOmissis]="modalitaOmissis"
                [documenti]="segnalazione.documenti" [canaleDocAggiunto]="
              'documento-aggiunto-segnalazione-' + segnalazione.id
            " [canaleDocAggiornato]="
              'documento-aggiornato-segnalazione-' + segnalazione.id
            " [canaleDocEliminato]="
              'documento-eliminato-segnalazione-' + segnalazione.id
            " (salvaDocumento)="salvaDocumento($event)" (aggiornaDocumenti)="aggiornaDocumenti()"
                (unlinkDocumento)="scollegaDocumento($event)"
                (eseguiAbilitaCondivisioneDocumento)="abilitaCondivisioneDocumento($event)"
                (eseguiDisabilitaCondivisioneDocumento)="disabilitaCondivisioneDocumento($event)">
              </app-elenco-allegati>
            </div>
          </mat-expansion-panel>
        </form>
      </div>


      <div class="bottone-flottante-basso-destra-su-su-su bottoni-flottanti no-print" *ngIf="false">
        <button mat-fab (click)="window.print()" i18n-matTooltip matTooltip="Stampa Segnalazione" class="no-print">
          <mat-icon aria-label="Stampa Segnalazione" class="icona-fab no-print">print</mat-icon>
        </button>
      </div>
      <div *ngIf="!readOnly() || datiSegnalanteInModifica">
        <div
          [ngClass]="{'bottone-flottante-basso-destra': !readOnly(), 'bottone-flottante-alto-sinistra-basso-basso-col1': datiSegnalanteInModifica}">
          <button mat-fab (click)="preSaveSegnalazione(); $event.stopPropagation()" i18n-matTooltip matTooltip="Salva"
            class="no-print custom-tooltip" matTooltipPosition="left">
            <mat-icon aria-label="salva segnalazione" class="icona-fab no-print">save</mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="!readOnly()" class="bottone-flottante-basso-destra-su bottoni-flottanti ">
        <button mat-fab (click)="sottomettiSegnalazione(); $event.stopPropagation()" i18n-matTooltip
          matTooltip="Sottometti la segnalazione" class="no-print" matTooltipPosition="left">
          <mat-icon aria-label="salva segnalazione" class="icona-fab no-print">send</mat-icon>
        </button>
      </div>

      <div *ngIf="modalitaOmissis && !modalitaOscuramento" i18n
        class="posizione-avviso-modalita-omissis bottoni-flottanti sfondo-giallo-trasparente padding-20 bold">
        Modifica Omissis per il gruppo {{nomeOdvOmissis}}
      </div>
      <div *ngIf="modalitaOscuramento" i18n
        class="posizione-avviso-modalita-omissis bottoni-flottanti sfondo-giallo-trasparente padding-20 bold">
        Eliminazione contenuti manifestamente non necessari
      </div>
      <div *ngIf="readOnly() && (!pannelloLavorazioneVisibile || !odv) && (odv || (!odv && !segnalazioneRitirata))"
        class="bottone-flottante-basso-destra-su bottoni-flottanti no-print">
        <button mat-fab (click)="openChat(); $event.stopPropagation()" i18n-matTooltip matTooltip="Approfondimenti"
          class="no-print" matTooltipPosition="left">
          <mat-icon aria-label="chat segnalazione" class="icona-fab no-print">chat</mat-icon>
        </button>
      </div>
    </as-split-area>
    <as-split-area #pannelloLavorazione class="cell" *ngIf="odv" [size]="30">
      <div *ngIf="isGestore() && !isBozza() && condivisioneSegnalazioneNellaSchedaSegnalazione">
        <app-condivisione-segnalazione *ngIf="pannelloLavorazioneVisibile" [segnalazione]="segnalazione"
          [elencoAziende]="elencoAziende">
        </app-condivisione-segnalazione>
      </div>

      <div [style.margin-top]="marginTop" *ngIf="pannelloLavorazioneVisibile">
        <app-pannello-lavorazione-segnalazione *ngIf="odv">
        </app-pannello-lavorazione-segnalazione>
      </div>
    </as-split-area>
  </as-split>
</div>
