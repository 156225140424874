/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-delimiter-style */
import { ENTER, COMMA } from '@angular/cdk/keycodes'
import { DOCUMENT } from '@angular/common'
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  QueryList,
  Inject,
  ChangeDetectorRef,
  HostListener,
  AfterViewInit,
  ChangeDetectionStrategy,
} from '@angular/core'
import {
  UntypedFormControl,
  UntypedFormGroup,
  NgForm,
  Validators,
  ValidatorFn,
} from '@angular/forms'
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete'
import { MatChipInput, MatChipInputEvent } from '@angular/material/chips'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router, ActivatedRoute } from '@angular/router'
// import { AmazingTimePickerService } from "amazing-time-picker";
import { SplitComponent } from 'angular-split'

import * as moment from 'moment'

import { Subscription, Observable, of } from 'rxjs'
import { catchError, debounceTime, filter, map } from 'rxjs/operators'
import { ComponentCanDeactivate } from '../can-deactivate/can-deactivate.component'
import { VisualizzatorePdfDialogComponent } from '../componenti/visualizzatore-pdf/visualizzatore-pdf.component'
import { comuni } from '../comuni'
import { ElencoAllegatiComponent } from '../elenco-allegati/elenco-allegati.component'
import { Azienda } from '../modello/azienda'
import { AzioniFollowUp } from '../modello/azioni-follow-up'
import { DirittiSegnalazione } from '../modello/diritti-segnalazione'
import { Documento, TipoDocumento } from '../modello/documento'
import { Lavorazione } from '../modello/lavorazione'
import { Odv } from '../modello/odv'
import { Omissis } from '../modello/omissis'
import { Segnalato } from '../modello/segnalato'
import { Segnalazione } from '../modello/segnalazione'
import { SorgenteSegnalazione } from '../modello/sorgente-segnalazione'
import { Tema } from '../modello/tema'
import { Traduzioni } from '../modello/traduzioni'
import { User } from '../modello/user'
import { DataPipe } from '../pipe/data.pipe'
import { OraPipe } from '../pipe/ora.pipe'
import { AuthService } from '../servizi/auth.service'
import { AziendeService } from '../servizi/aziende.service'
import { ChatService } from '../servizi/chat.service'
import { ConfigurazioneService } from '../servizi/configurazione.service'
import { DocumentiService } from '../servizi/documenti.service'
import { OmissisService } from '../servizi/omissis.service'
import { SegnalazioneService } from '../servizi/segnalazione.service'
import { WINDOW } from '../servizi/window-service'
import { SessionData } from '../sessione/dati-sessione'
import { ESITO_OK, Esito, ESITO_IN_PROGRESS } from '../utility/esito'
import {
  editabile,
  destinatarioEditabile,
  isMembroDiGruppo,
  corpoSegnalazioneEditabile,
  segnalante,
  isUtenteMembrodiOdvSceltoDaSegnalante,
  isSegnalante,
  mittenteSegnalazione,
  puoEssereAbilitatoAllaConsultazioneDelSegnalante,
  puoConsultareNomeSegnalante,
  puoConsultareNomeSegnalati,
  dirittiUtenteSullaSegnalazione,
  isUtenteGestoreDiAlmenoUnaLavorazione,
  destinatariVisibili,
  readOnlyDestinatari,
  getIdSegnalazione,
  nuovaSegnalazione,
  odvHaRicevutoConsensoDaSegnalante,
  isMembroDiAlmenoUnGruppo,
  dirittiSegnalazioneUtenteRicevutiDaSegnalante,
} from '../utility/helper-segnalazioni'
import {
  BOZZA_INDEX,
  NESSUNA_LAVORAZIONE_IN_CORSO_INDEX,
  statoSegnalazioneLeggibile,
  statoLavorazioneLeggibile,
  SottoTipoContenuto,
  TipoContenuto,
  apriChatLavorazione,
  caricaChatLavorazione,
  openDialogGenerica,
  RITIRATA_INDEX,
  TUTTE_LE_LAVORAZIONI_CHIUSE_INDEX,
} from '../utility/utility'
import { CustomValidators } from '../validators/CustomValidators'
import { ConfermaInvioSegnalazioneDialogComponent } from './dialogs/conferma-invio-segnalazione'
import { ConfermaSbloccoDatiDialogComponent } from './dialogs/conferma-sblocco-dati'
import { RichiestaSbloccoSegnalanteDialogComponent } from './dialogs/richiesta-sblocco-segnalante.dialog.component'
import { InformativaComponent } from './informativa/informativa.component'
import { RispostaCustodeDialogComponent } from './risposta-custode/risposta-custode.dialog.component'

import { Operazioni } from '../utility/utility'
import { LogOperativoService } from '../servizi/log-operativo.service'
import * as helperSegnalazioni from '../utility/helper-segnalazioni'
import { Segnalante } from '../modello/segnalante'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { DialogRef } from '@angular/cdk/dialog'
import { VersioneContenuto } from '../modello/versione-contenuto'
import { ContenutiService } from '../servizi/contenuti.service'
import { OscuramentiService } from '../servizi/oscuramenti.service'
import { OscuramentoDatiManifestamenteNonNecessari } from '../modello/oscuramento-dati-manifestamente-non-necessari'
import { AutorizzazioneOscuramento } from '../modello/autorizzazione-oscuramento'
import { DialogSelezioneGruppoComponent } from '../componenti/dialog/dialog-selezione-gruppo/dialog-selezione-gruppo.component'
import { MatButton } from '@angular/material/button'
import { InibizioneUtenteGestoreSuSegnalazione } from '../modello/inibizione-utente-gestore-su-segnalazione'
import { PincodeService } from '../servizi/pincode.service'
import { MessaggiStore } from '../stores/messaggi.store'
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { LanguageService } from '../servizi/language.service';

// Definisci i formati delle date
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

class Comune {
  nome: string
  regione: string
}

// import * as jsPDF from 'jspdf';

@Component({
  selector: 'app-dettaglio-segnalazione',
  templateUrl: './dettaglio-segnalazione.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./dettaglio-segnalazione.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class DettaglioSegnalazioneComponent
  extends ComponentCanDeactivate
  implements OnInit, OnDestroy, AfterViewInit {
  subscriptions: Subscription[]
  testoRichiestaConsensoAudio: string
  @ViewChild('autoCitta') autoCitta: MatAutocomplete
  isCodiceFiscaleValid = true
  testoRichiestaConsenso: string
  mostraAvviso = false
  loadingSegnalazione: boolean
  oggettoSegments: Segment[]
  descrizioneSegment: Segment
  hashAudioSegment: Segment
  nomeAziendaSegment: Segment
  aziendaInteressataSegment: Segment
  descrizioneFattoSegments: Segment[]
  segnalanteNomeSegment: Segment
  segnalanteCognomeSegment: Segment
  segmentCache: any
  segnalanteEmailSegment: Segment
  segnalanteTelefonoSegment: Segment
  segnalanteCodiceFiscaleSegment: Segment
  segnalanteIndirizzoDiResidenzaSegment: Segment
  segnalanteCittaSegment: Segment
  segnalanteCapSegment: Segment
  segnalanteRegioneSegment: Segment
  segnalanteStatoSegment: Segment
  segnalanteIncaricoSegment: Segment
  segnalanteOrgUnitAndSiteSegment: Segment
  segnalanteQualificaSegment: Segment
  segnalanteIncaricoT0Segment: Segment
  segnalanteRapportoConLaSocietaSegment: Segment
  segnalazionePeriodoSegment: Segment
  segnalazioneDataSegment: Segment
  segnalatoNomeSegments: Segment[]
  segnalatoCognomeSegments: Segment[]
  segnalatoQualificaSegments: Segment[]
  segnalazioneAltreImpreseSegments: Segment[]
  segnalazioneAltriSoggettiSegments: Segment[]
  segnalazioneModalitaConoscenzaFattoSegments: Segment[]
  segnalazioneAltroSegments: Segment[]
  processoOscuramento: OscuramentoDatiManifestamenteNonNecessari
  autorizzazioniOscuramentoLavorabili: AutorizzazioneOscuramento[]
  autorizzazioniOscuramento: AutorizzazioneOscuramento[]
  autorizzazioneOscuramento: AutorizzazioneOscuramento
  gruppoSelezionato: Odv
  gruppiImpersonati: Odv[]
  dirittoSelezionato: DirittiSegnalazione
  inibizioniUtente: InibizioneUtenteGestoreSuSegnalazione[]
  dirittoSegnalazioneSelezionato: DirittiSegnalazione
  ultimoProcessoOscuramentoGestitoEChiuso: OscuramentoDatiManifestamenteNonNecessari
  reloadAttempts = 0
  maxReloadAttempts = 3  // Imposta un limite, ad esempio 3 tentativi
  campiSegnalante: { campo: string; customValidators: ValidatorFn[] }[] = []

  @ViewChild('allegati', { static: false })
  elencoAllegati: ElencoAllegatiComponent

  @ViewChild('inputDestinatari', { static: false })
  inputDestinatari: ElementRef
  @ViewChild('inputAziende', { static: false, read: MatAutocompleteTrigger })
  autoTrigger: MatAutocompleteTrigger
  @ViewChild('inputAziende', { static: false, read: MatAutocompleteTrigger })
  autoTrigger2: MatAutocompleteTrigger

  @ViewChild('split', { static: true }) split: SplitComponent
  azioneControl = new UntypedFormControl()

  navIsFixed: boolean
  segnalazioneForm: UntypedFormGroup

  public selectedTime: string

  rapportiConSegnalanteOptions = [
    'dipendente',
    'ex-dipendente',
    'somministrato',
    'collaboratore',
    'fornitore',
    'partner-commerciale',
    'consulente',
    'altro',
  ]
  genereOptions = [$localize`M`, $localize`F`]
  periodoDelFattoOptions = [
    'meno-di-1-settimana-fa',
    'meno-di-1-mese-fa',
    'da-1-a-3-mesi-fa',
    'da-3-a-6-mesi-fa',
    'da-6-mesi-ad-1-anno-fa',
    'oltre-1-anno-fa'
  ]

  tipologiaSegnalazioneOptions: string[]
  destinatariSegnalazione: Odv[]
  destinatariSegnalazioneFiltrati: Odv[]
  sorgentiSegnalazione: SorgenteSegnalazione[]
  testoSorgentiSegnalazione: string[]
  sorgenteSegn: string

  inviaSegnalazione: boolean

  temiSegnalazione: Tema[]
  azioniFollowUp: AzioniFollowUp[]
  azioniFollowUpFiltrate: AzioniFollowUp[]

  formLavorazione: QueryList<NgForm>

  @ViewChild('formSegnalazione', { static: true })
  ngFormSegnalazione: NgForm

  @ViewChild('inputAziende', { read: MatAutocompleteTrigger })
  autoCompleteTrigger: MatAutocompleteTrigger

  destinatario: string

  anonimaControl: UntypedFormControl

  visible = true
  selectable = true
  removable = true
  addOnBlur = true
  readonly separatorKeysCodes: number[] = [ENTER, COMMA]
  showWarning = false
  test: any = { formato: 'dd/MM/yyyy HH:mm' }
  condivisione: any = false
  saving = false
  elencoAziende: Azienda[]
  elencoAziendeFiltrato: any
  informativaAccettata = false

  modalitaOmissis = false
  sottoscrizioneGestioneOmissis: Subscription
  dirittiSegnalazione: DirittiSegnalazione
  omissis: Omissis[]
  nomeOdvOmissis: string

  sottoscrizioneSegnalazionePerOmissis: Subscription
  sottoscrizioneAggiornamentoFormLavorazione: Subscription
  sottoscrizioneComboCambioDestinatari: Subscription
  sottoscrizioneVisibilitaSegnalazione: Subscription
  sottoscrizioneAggiuntaDocumento: Subscription
  sottoscrizioni: Subscription[] = []

  loading = false
  loadingDocumenti: boolean = false;
  dialogRefRichiestaSbloccoSegnalante: any
  segnalanteVisibile: boolean

  filteredComuni: Comune[] = []
  isLoading: boolean
  dialogRefConfermaScondivisioneSegnalazione: DialogRef
  dialogRefDirittiInoltro: DialogRef
  dialogRefConfermaCambioVisibilitaSegnalazione: DialogRef
  dialogRefConfermaVisibilita: DialogRef
  nomeOdvOmissisInModifica: string
  splitData: any
  datiArchiviazione: {
    dataEliminazioneSistema: Date
    dataDecisioneGestore: Date
    dataEliminazioneGestore: Date
    odv: Odv
  }
  loadingSegnalante: boolean
  tipiNonConformita: string
  datiSegnalanteInModifica: boolean
  segments: Segment[] = []
  modalitaOscuramento: boolean
  screenWidth: number
  audioBase64: string

  private langSubscription: Subscription;

  @ViewChild('consenso') consensoRef: ElementRef;

  scrollToConsenso() {
    this.consensoRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  // Estendi la classe per mantenere un registro delle sottoscrizioni
  private processoOscuramentoSubscriptions: Map<number, Subscription> = new Map()
  registrazioneVocaleDaSalvare: boolean
  getTestoLavorazioni(lavorazione) {
    if (this.gestore(lavorazione) === 'Nessuno') {
      return ''
    }
    return !this.odv
      ? lavorazione.visibileAlSegnalante
        ? $localize`Gruppo Responsabile ` +
        this.gestore(lavorazione) +
        $localize`, lo stato attuale è ` +
        this.convertiStatoLavorazione(lavorazione.stato)
        : $localize`Gruppo Responsabile ` +
        this.gestore(lavorazione) +
        $localize`. Lo stato di questa lavorazione non è consultabile`
      : $localize`Gruppo Responsabile ` +
      this.gestore(lavorazione) +
      $localize`, lo stato attuale è ` +
      this.convertiStatoLavorazione(lavorazione.stato)
  }

  mostraTestoLavorazioni(lavorazione) {
    return (
      !(!this.odv && !lavorazione.visibileAlSegnalante) &&
      this.getTestoLavorazioni(lavorazione) !== $localize`Nessuno`
    )
  }

  constructor(
    public dialog: MatDialog,
    public segnalazioniService: SegnalazioneService,
    private router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) public window: Window,
    public cd: ChangeDetectorRef,
    private aziendaService: AziendeService,
    private chatService: ChatService,
    private documentiService: DocumentiService,
    private omissisService: OmissisService,
    private sessionData: SessionData,
    private configurazioneService: ConfigurazioneService,
    private logOperativoService: LogOperativoService,
    private cdr: ChangeDetectorRef,
    private _adapter: DateAdapter<any>,
    private contenutiService: ContenutiService,
    private oscuramentiService: OscuramentiService,
    private pincodeService: PincodeService,
    private activatedRoute: ActivatedRoute,
    private messageStore: MessaggiStore,
    private dateAdapter: DateAdapter<any>,
    private languageService: LanguageService
  ) {
    super()
    this.subscriptions = []
    this.elencoAziende = []
    this.datiSegnalanteInModifica = false
    this.modalitaOscuramento = false
    this._adapter.setLocale('it')
  }
  ngAfterViewInit(): void {
    this.split.dragProgress$.subscribe((x) => {
      // console.log('x:', x)
      this.splitData = x
    })
    this.campiSegnalante = [
      { campo: 'nomeSegnalante', customValidators: [] },
      { campo: 'cognomeSegnalante', customValidators: [] },
      {
        campo: 'codiceFiscaleSegnalante',
        customValidators: [],
      },
      {
        campo: 'capSegnalante',
        customValidators: [],
      },
      {
        campo: 'cittaSegnalante',
        customValidators: [],
      },
      { campo: 'indirizzoDiResidenzaSegnalante', customValidators: [] },
      {
        campo: 'telefonoSegnalante',
        customValidators: [],
      },
      {
        campo: 'emailSegnalante',
        customValidators: [],
      },
      { campo: 'rapportoConLaSocietaSegnalante', customValidators: [] },
      { campo: 'incarico', customValidators: [] },
    ]
    // setTimeout(() => {
    //   if ((!this.segnalazioneForm || !this.segnalazione) && this.reloadAttempts < this.maxReloadAttempts) {
    //     this.reloadAttempts++
    //     console.log('tentativo di reload della pagina: ' + this.reloadAttempts)

    //     // invoca il resolver per ricaricare i dati
    //     this.router.navigate([], {
    //       queryParams: { reload: 'true' },
    //       queryParamsHandling: 'merge',
    //     })

    //   }
    // }
    //   , 3000)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth
    // aggiorna la classe dell'elemento HTML in base alla grandezza dello schermo
    if (this.screenWidth < 500) {
      // aggiungi la classe per schermi piccoli
    } else {
      // rimuovi la classe per schermi piccoli
    }
  }

  toggleLoading(loading: boolean) {
    this.loading = loading
    console.log('loading: ' + loading)
    this.cdr.detectChanges()
  }

  get utente(): User {
    return this.authService.getUser()
  }

  get odv(): boolean {
    return this.authService.getUser()?.odv
  }

  get odvDestinatarioSegnalazione(): boolean {
    return (
      isUtenteMembrodiOdvSceltoDaSegnalante(
        this.segnalazione,
        this.authService.getUser()
      )
    )
  }

  get custode(): boolean {
    return this.authService.getUser()?.custode
  }

  get editabile(): boolean {
    return editabile(this.segnalazione)
  }

  get destinatarioEditabile(): boolean {
    return destinatarioEditabile(this.segnalazione, this.authService.getUser())
  }

  get segnalazioneVisibile(): boolean {
    const gruppi = this.segnalazione?.dirittiSegnalazioneOdv
      ?.filter((dirsegna) => !dirsegna.consultazioneSegnalazioneSospesa)
      .map((dirsegna) => dirsegna.odvDestinatario)
    const isVisibile =
      this.segnalazione?.id === undefined ||
      isMembroDiAlmenoUnGruppo(this.authService.getUser(), gruppi) ||
      isSegnalante(this.segnalazione, this.authService.getUser())
    return isVisibile
  }

  get dirittiSegnalazioneOdvFiltrati() {
    let dirittiFiltrati = this.segnalazione?.dirittiSegnalazioneOdv
    if (this.odv) {
      dirittiFiltrati = dirittiFiltrati?.filter(
        (diritto) =>
          diritto.odvCheHaInoltrato === undefined ||
          (diritto.odvDestinatario &&
            isMembroDiGruppo(
              this.authService.getUser(),
              diritto.odvDestinatario
            )) ||
          (diritto.odvCheHaInoltrato &&
            isMembroDiGruppo(
              this.authService.getUser(),
              diritto.odvCheHaInoltrato
            ))
      )
    }

    return dirittiFiltrati
  }

  get corpoSegnalazioneEditabile(): boolean {
    return corpoSegnalazioneEditabile(this.segnalazione)
  }

  get segnalazione(): Segnalazione {
    const tempSegna = this.sessionData.getSegnalazioneSelezionata()
    return tempSegna
  }

  get segnalante(): boolean {
    // @ToDo da rivedere in fase di test
    return segnalante(this.segnalazione, this.authService.getUser())
  }

  get pinCode() {
    return this.sessionData.pinCode
  }

  get pannelloLavorazioneVisibile() {
    return this.splitData ? this.splitData.sizes[1] > 0 : true
  }

  ngOnInit() {

    // Imposta la localizzazione del datepicker in base alla lingua corrente
    const currentLang = window.location.pathname.split('/')[1] || 'it';
    this.dateAdapter.setLocale(currentLang);

    this.langSubscription = this.languageService.languageChange$.subscribe(lang => {
      this.dateAdapter.setLocale(lang);
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['reload'] === 'true') {
        // Inserisci qui la logica per gestire il ricaricamento o l'azione desiderata
        console.log('La pagina verrà ricaricata in base al parametro.');
        // this.modalitaOscuramento = false
        // this.modalitaOmissis = false
        this.recuperaUtentiInibitiPerSegnalazione()
        this.recuperaProcessoOscuramentoInCorso()
        this.recuperaUltimoOscuramentoChiuso()
        this.initSegments()
        this.reloadSegnalazione()
        // Se necessario, puoi anche rimuovere il parametro reload dopo averlo gestito
        // per evitare che successive navigazioni senza il parametro eseguano comunque il codice di ricaricamento.
        this.router.navigate([], {
          queryParams: { reload: null },
          queryParamsHandling: 'merge'
        });
      }
    });

    this.sessionData.pinCode = this.pincodeService.pinCode
    this.loadingSegnalazione = true
    this.loading = true
    this.screenWidth = window.innerWidth

    this.recuperaSegnalazione(this.segnalazione?.id)

    this.recuperaUtentiInibitiPerSegnalazione()
    this.recuperaProcessoOscuramentoInCorso()
    this.recuperaUltimoOscuramentoChiuso()

    const tmp =
      this.segnalazioniService.riabilitaPannelloSegnalazione.subscribe((x) => {
        this.loading = false
      })
    this.subscriptions.push(
      this.segnalazioniService.notificaPerAggiornamentoDatiArchiviazione.subscribe(
        () => {
          this.recuperaDatiArchiviazione(+this.segnalazione.id)
        }
      )
    )
    this.subscriptions.push(tmp)

    this.subscriptions.push(this.getDocumentiAssociatiASegnalazione())

    this.route.data.subscribe(async (data) => {
      const datiSessione = data['datiSessione']
      if (datiSessione) {
        this.sessionData.configurazione = datiSessione.configurazione
        this.sessionData.segnalazioni = datiSessione.segnalazioni
        this.sessionData.nuovaSegnalazioneSelezionata(datiSessione.segnalazioneSelezionata)

        this.sessionData.messaggiSegnalazioneSelezionata = datiSessione.messaggiSegnalazioneSelezionata
        this.sessionData.chatSelezionata = datiSessione.chatSelezionata
      }

      // console.log('datiSessione', datiSessione)
      // console.log('segnalazione', this.segnalazione)

      // verifico se nel localsotrage c'è un audio
      const audio = localStorage.getItem('audio')
      if (audio) {
        this.audioBase64 = audio
      }
      if (datiSessione && this.segnalazione) {
        this.createForm()
      } else {
        console.log('0 in attesa dei dati....')
      }
      // console.log(data);
      // this.sessionData.getSegnalazioneSelezionata() = data['segnalazione']
      let segnalazione = this.sessionData.getSegnalazioneSelezionata()

      if (!segnalazione) {
        segnalazione = this.nuovaSegnalazione(segnalazione, data)
      }
      if (segnalazione) {
        if (segnalazione.id) {
          // recupero le chat segnalante per ogni eventuale lavorazione associata alla segnalazione
          this.segnalazione.lavorazioni?.forEach((lavorazione) => {
            caricaChatLavorazione(lavorazione, this.chatService)
          })
          this.documentiService
            .getDocumentiAssociatiASegnalazione(this.segnalazione)

            .then((esito: Esito) => {
              if (esito.esito === ESITO_OK) {
                const documenti = esito.payload ? JSON.parse(esito.payload) : []
                this.sessionData.nuovaSegnalazioneSelezionata(!segnalazione[
                  'oggetto'
                ]
                  ? nuovaSegnalazione(this.authService.getUser())
                  : segnalazione)
                this.sessionData.getSegnalazioneSelezionata().documenti = documenti
                this.segnalazione.documenti = documenti
              } else {
                //
                console.log(
                  'Errore nel recupero degli allegati della segnalazione'
                )
              }
            })
        }
        await this.recuperaTipiNonConformitaLinguaUtente()
        await this.recuperaTestoRichiestaConsensoAudioLinguaUtente()
        this.createForm()
      } else {
        console.log(data)
        this.router.navigate(['/landing-page'], {
          queryParams: { initialize: true },
        })
      }
      return
    })

    if (this.segnalazione) {
      this.loading = true
      this.loadingSegnalante = true
      this.recuperaPersonaSegnalante(+this.segnalazione.id)
    }

    if (this.segnalazione && this.segnalazione.id) {
      this.recuperaDatiArchiviazione(+this.segnalazione.id)
    }

    // this.form.push(this.ngFormSegnalazione);
    if (
      !this.readOnly() &&
      this.sessionData.configurazione?.segnalanteVedeOdv
    ) {
      this.loading = true
      this.recuperaTuttiIDestinatari()
    }

    this.recuperaTutteLeAziende()

    if (!this.segnalazione?.dirittiSegnalazioneOdv && this.segnalazione?.id) {
      //  prova a recuperare i diritti segnalazione

      this.loading = true
      this.recuperaDirittiSegnalazione(+this.segnalazione.id)
    }

    this.sottoscrizioneAggiornamentoFormLavorazione =
      this.segnalazioniService.notificaPerAggiornamentoFormLavorazione.subscribe(
        (ngForm) => {
          this.formLavorazione = ngForm
        }
      )

    this.sottoscrizioni.push(
      this.omissisService.attivaDisattivaOmissis.subscribe(
        (dirittoSegnalazione) => {
          this.condivisioneSelezionata(dirittoSegnalazione)
        },
        (error) => {
          console.error(error)
        }
      )
    )

    this.sottoscrizioni.push(
      this.omissisService.disattivaOmissisSeAttivi.subscribe(
        () => {
          this.disattivaModalitaOmissis()
        },
        (error) => {
          console.error(error)
        }
      )
    )

    this.sottoscrizioni.push(
      this.segnalazioniService.salvaSegnalazioneInLocalStorage.subscribe(
        () => {
          // raccoglie i dati presenti nel form della segnalazione
          this.salvaSegnalazioneInLocalStorage()
        }
      )
    )

    this.sottoscrizioneVisibilitaSegnalazione =
      this.segnalazioniService.flagVisibilitaSegnalazione.subscribe(
        ({ idSegnalazione, idOdv, visibile }) => {
          if (
            +this.segnalazione.id === +idSegnalazione &&
            !visibile &&
            !isUtenteMembrodiOdvSceltoDaSegnalante(
              this.segnalazione,
              this.authService.getUser()
            )
          ) {
            this.snackBar.open(
              $localize`Ti è stata appena negata la visibilità di questa segnalazione. Vieni rediretto alla pagina principale`,
              null,
              {
                duration: 4000,
              }
            )

            this.router.navigate(['home'])
            return
          } else if (+this.segnalazione.id === +idSegnalazione && visibile) {
            console.log(
              'ricevuta autorizzazione di visibilità sulla segnalazione',
              idSegnalazione
            )
          }
        }
      )

    this.sottoscrizioneAggiuntaDocumento =
      this.documentiService.aggiungiDocumento.subscribe((epdoc) => {
        if (epdoc.tipoEntitaPrincipale !== 'segnalazione') {
          return
        }
        if (!this.segnalazione.documenti) {
          this.segnalazione.documenti = []
        }
        //  this.segnalazione.documenti.push(documento);

        if (this.segnalazione.id != null) {
          this.documentiService
            .collegaDocumentoASegnalazione(epdoc.documento, this.segnalazione)
            .then((esito) => {
              if (esito.esito === ESITO_OK) {
                const documentoUpd = JSON.parse(esito.payload)
                this.segnalazione.documenti.push(documentoUpd)
                this.segnalazioniService.notificaPerDocumentoAggiunto.next({
                  canale:
                    'documento-aggiunto-segnalazione-' + this.segnalazione.id,
                  documento: documentoUpd,
                })
              } else {
                this.snackBar.open(
                  $localize`Il salvataggio del documento è fallito! ` +
                  esito.descrizioneEsito,
                  null,
                  {
                    duration: 6000,
                  }
                )
              }
            })
            .catch((errore) => {
              // console.log(errore);
              this.snackBar.open(
                $localize`Il salvataggio del documento è fallito!`,
                null,
                {
                  duration: 6000,
                }
              )
            })
        } else {
          this.segnalazione.documenti.push(epdoc.documento)
          // if (this.segnalazione.id) {
          this.segnalazioniService.notificaPerDocumentoAggiunto.next({
            canale: 'documento-aggiunto-segnalazione-' + this.segnalazione.id,
            documento: epdoc.documento,
          })
          // }

          // this.elencoAllegati.aggiungiNuovoDocumento(documento);
        }
      })

    // Recupero gli allegati della segnalazione corrente castando l'entità principale a Segnalazione
    // ma solo se l'entità principale è una segnalazione
    if (this.segnalazione && this.segnalazione.id) {
      this.documentiService
        .getDocumentiAssociatiASegnalazione(this.segnalazione)

        .then((esito: Esito) => {
          if (esito.esito === ESITO_OK) {
            const documenti = esito.payload ? JSON.parse(esito.payload) : []
            this.segnalazione.documenti = documenti
          } else {
            //
            console.log('Errore nel recupero degli allegati della segnalazione')
          }
        })
    }

    this.contenutiService
      .getVersioneContenuto(
        TipoContenuto.DOCUMENTO,
        SottoTipoContenuto.RICHIESTA_CONSENSO_AL_SEGNALANTE,
        this.codiceLingua
      )
      .subscribe((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          const versioneContenuto = JSON.parse(esito.payload)
          this.testoRichiestaConsenso = versioneContenuto.testo
        } else {
          console.log('Errore nel recupero della richiesta consenso')
        }
      })
  }

  private recuperaTestoRichiestaConsensoAudioLinguaUtente() {
    this.contenutiService
      .getVersioneContenuto(
        TipoContenuto.DOCUMENTO,
        this.segnalazione.segnalazioneVocale === true ?
          SottoTipoContenuto.RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_SEGNALAZIONE_VOCALE :
          SottoTipoContenuto.RICHIESTA_CONSENSO_REGISTRAZIONI_AUDIO_SEGNALAZIONE,
        this.codiceLingua
      )
      .pipe(
        filter((esito1: Esito) => esito1.esito === ESITO_OK),
        map((esito2: Esito) => esito2.payload),
        catchError((error) => {
          console.error('Errore nella richiesta del valore aggiuntivo', error)
          return of(null)
        })
      )
      .subscribe((payload) => {
        this.testoRichiestaConsensoAudio = (
          JSON.parse(payload) as VersioneContenuto
        )?.testo
        this.sessionData.configurazione.testoRichiestaConsensoAudio =
          this.testoRichiestaConsensoAudio
        if (this.segnalazione) {
          this.createForm()
        } else {
          console.log('1 in attesa dei dati....')
        }
      })
  }

  private recuperaTipiNonConformitaLinguaUtente() {
    this.configurazioneService
      .recuperaTipiNonConformitaLinguaUtente()
      .pipe(
        filter((esito1: Esito) => esito1.esito === ESITO_OK),
        map((esito2: Esito) => esito2.payload),
        catchError((error) => {
          console.error('Errore nella richiesta del valore aggiuntivo', error)
          return of(null)
        })
      )
      .subscribe((payload) => {
        // verifica che payload non sia undefined
        if (payload) {
          this.tipiNonConformita = (JSON.parse(payload) as Traduzioni)?.testo
          this.tipologiaSegnalazioneOptions = this.tipiNonConformita?.split('\n')
          this.sessionData.configurazione.tipiNonConformita =
            this.tipiNonConformita
        } else {
          this.tipiNonConformita = ""
          this.sessionData.configurazione.tipiNonConformita =
            this.tipiNonConformita
        }

        if (this.segnalazione) {
          this.createForm()
        } else {
          console.log('2 in attesa dei dati....')
        }
      })
  }

  private nuovaSegnalazione(segnalazione: Segnalazione, data) {
    if (this.authService.isLoggedIn()) {
      // SessionData.utente = data;
      // console.log('Navigo nella home.....');
      segnalazione = new Segnalazione()
      const segnal = new Segnalante()

      segnalazione.segnalante = segnal
      segnalazione.segnalante.utente = this.authService.getUser()
      segnalazione.anonima = false
      segnalazione.stato = '0'

      segnalazione.presaInCarico = false
      segnalazione.documenti = new Array<Documento>()

      this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
      // console.log('avvio creazione nuova segnalazione:');
      this.router.navigate(['/segnalazione'])
    }
    return segnalazione
  }
  private getDocumentiAssociatiASegnalazione(): Subscription {
    return this.segnalazioniService.notifichePerSegnalazioneAggiornata.subscribe(
      (segnalazione) => {
        if (segnalazione.id && segnalazione.id === this.segnalazione.id) {
          this.documentiService
            .getDocumentiAssociatiASegnalazione(this.segnalazione)

            .then((esito: Esito) => {
              if (esito.esito === ESITO_OK) {
                const documenti = esito.payload ? JSON.parse(esito.payload) : []
                this.sessionData.nuovaSegnalazioneSelezionata(!segnalazione[
                  'oggetto'
                ]
                  ? nuovaSegnalazione(this.authService.getUser())
                  : segnalazione)
                this.sessionData.getSegnalazioneSelezionata().documenti = documenti
              } else {
                //
                console.log(
                  'Errore nel recupero degli allegati della segnalazione'
                )
              }
            })
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.sottoscrizioneAggiornamentoFormLavorazione?.unsubscribe()
    this.sottoscrizioneComboCambioDestinatari?.unsubscribe()
    this.sottoscrizioneGestioneOmissis?.unsubscribe()
    this.sottoscrizioneSegnalazionePerOmissis?.unsubscribe()
    this.sottoscrizioneVisibilitaSegnalazione?.unsubscribe()
    this.sottoscrizioneAggiuntaDocumento?.unsubscribe()
    this.sottoscrizioni?.forEach((sub) => sub.unsubscribe())
    if (this.segnalazione && this.segnalazione['matData']) {
      this.segnalazione['matData'] = undefined
    }
    this.subscriptions.forEach((s) => s.unsubscribe())
    this.processoOscuramentoSubscriptions.forEach(sub => sub.unsubscribe())
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }

  readOnly() {
    const ronly =
      this.segnalazione?.id !== undefined &&
      this.segnalazione.stato !== BOZZA_INDEX.toString()
    return ronly
  }

  get segnalazioniAnonimeConsentite() {
    return this.sessionData.configurazione?.consentiSegnalazioniAnonime
  }

  get visualizzaDestinatari() {
    const gestoreOppureSegnalanteEPuoVedereGestori = this.isGestore() || (!this.gestore && this.mostraDestinatari())
    const segnalazioneConId = this.segnalazione && this.segnalazione.id !== undefined
    return gestoreOppureSegnalanteEPuoVedereGestori ||
      (!this.isGestore() && this.mostraDestinatari() && segnalazioneConId) ||
      (!this.isGestore() && this.mostraDestinatari() && !segnalazioneConId && this.puoScegliereDestinatario())
  }

  readOnlyDestinatari() {
    const esito = readOnlyDestinatari(
      this.segnalazione,
      this.sessionData.configurazione?.segnalantePuoScegliereOdv
    )
    return esito
  }

  tooltipDestinatari(destinatario: DirittiSegnalazione) {
    const result = destinatario.odvDestinatarioInConflittoDiInteressi
      ? $localize`Escluso su richiesta del segnalante per conflitto di interessi`
      : destinatario.odvCheHaInoltrato
        ? $localize`Coinvolto da ` + destinatario.odvCheHaInoltrato?.nomeOdv
        : $localize`Impostato dal sistema`
    return result
  }

  destinatariVisibili(filtro?: number) {
    const destVisibili = destinatariVisibili(
      this.segnalazione,
      this.authService.getUser(),
      filtro
    )
    return destVisibili
  }

  nonPuoiGestireOmissis() {
    this.snackBar.open($localize`Si tratta già di un omissis`, null, {
      duration: 4000,
    })
  }

  toggleOmissis(segmento: any, tutto?: boolean, inutile?: string, parola?: string) {
    // console.log('Omissis del campo ' + campo + ' parola ' + parola)

    // se non è più omissis la elimino
    if (segmento.isOmissis) {
      // se mi trovo nel processo di oscuramento
      if (this.modalitaOscuramento) {
        // solo il responsabile può modificare gli omissis
        const odvResponsabile = this.processoOscuramento.responsabile
        if (
          this.gruppoSelezionato &&
          this.gruppoSelezionato.id !== odvResponsabile.id
        ) {
          this.snackBar.open(
            // eslint-disable-next-line max-len
            $localize`Solo un membro del gruppo ${odvResponsabile.nomeOdv} che ha avviato il processo può eliminare gli omissis. Se ne possiedi la facoltà, seleziona il gruppo ${odvResponsabile.nomeOdv} e riprova.`,
            null,
            {
              duration: 4000,
            }
          )
          return
        }
        segmento.isOmissis = !segmento.isOmissis
        const omissisIndex = this.processoOscuramento.omissis.findIndex(
          (omi) =>
            omi.campo === segmento.campo &&
            omi.progressivo === segmento.progressivo &&
            omi.idEntita === segmento.idEntita
        )
        if (omissisIndex === -1) {
          return
        }
        this.oscuramentiService
          .eliminaOmissisOscuramento(
            this.processoOscuramento.omissis[omissisIndex].id
          )
          .subscribe(
            (esito) => {
              if (esito.esito === ESITO_OK) {
                const omissisEliminato = JSON.parse(esito.payload) as Omissis
                segmento.parola = omissisEliminato['parola']
                // console.log(esito);
                this.processoOscuramento.omissis.splice(omissisIndex, 1)
                this.reloadSegnalazione()
                this.recuperaProcessoOscuramentoInCorso()
                this.cd.markForCheck()
              } else {
                this.snackBar.open(
                  $localize`Aggiornamento Omissis Fallito. Errore: ` +
                  esito.payload,
                  null,
                  {
                    duration: 4000,
                  }
                )
              }
            },
            (err) => {
              this.snackBar.open(
                $localize`Aggiornamento Omissis Fallito. Errore: ` +
                err.toString(),
                null,
                { duration: 4000 }
              )
            }
          )
      } else {
        segmento.isOmissis = !segmento.isOmissis
        //  la parola è tra gli omissis la escludo
        // trovo l'omissis da eliminare tra gli omissis della segnalazione
        const omissisIndex = this.dirittiSegnalazione?.omissis.findIndex(
          (omi) =>
            omi.campo === segmento.campo &&
            omi.progressivo === segmento.progressivo &&
            omi.idEntita === segmento.idEntita
        )
        this.segnalazioniService
          .eliminaOmissisDirittiSegnalazione(
            this.dirittiSegnalazione?.omissis[omissisIndex]
          )
          .then((esito) => {
            if (esito.esito === ESITO_OK) {
              // console.log(esito);

              this.dirittiSegnalazione?.omissis.splice(omissisIndex, 1)
              this.cd.markForCheck()
            } else {
              this.snackBar.open(
                $localize`Aggiornamento Omissis Fallito. Errore: ` +
                esito.payload,
                null,
                {
                  duration: 4000,
                }
              )
            }
          })
          .catch((err) => {
            this.snackBar.open(
              $localize`Aggiornamento Omissis Fallito. Errore: ` +
              err.toString(),
              null,
              {
                duration: 4000,
              }
            )
          })
      }
    } else {
      if (this.modalitaOscuramento) {
        // solo il responsabile può modificare gli omissis
        const odvResponsabile = this.processoOscuramento.responsabile
        if (
          !this.gruppoSelezionato.id ||
          this.gruppoSelezionato.id !== odvResponsabile.id
        ) {
          this.snackBar.open(
            $localize`Solo un membro del gruppo ${odvResponsabile.nomeOdv} che ha avviato il processo può aggiungere gli omissis. Se possiedi la facoltà, seleziona il gruppo ${odvResponsabile.nomeOdv} e riprova.`,
            null,
            {
              duration: 8000,
            }
          )
          return
        }
        segmento.isOmissis = !segmento.isOmissis
        //  la parola non è tra gli omissis la includo

        const omissis = new Omissis()
        omissis.campo = segmento.campo
        omissis.progressivo = segmento.progressivo
        omissis.idEntita = segmento.idEntita
        omissis.tutto = tutto
        omissis.oscuramento = true
        omissis.oscuramentoDatiManifestamenteNonNecessari =
          this.processoOscuramento
        this.oscuramentiService.aggiungiOmissisOscuramento(omissis).subscribe(
          (esito) => {
            if (esito.esito === ESITO_OK) {
              // console.log(esito);
              this.recuperaProcessoOscuramentoInCorso()
              this.refreshView() // Forza l'aggiornamento dopo la risposta
            } else {
              this.snackBar.open(
                $localize`Aggiornamento Omissis Fallito. Errore: ` +
                esito.payload,
                null,
                {
                  duration: 4000,
                }
              )
              this.refreshView()
            }
          },
          (err) => {
            this.snackBar.open(
              $localize`Aggiornamento Omissis Fallito. Errore: ` +
              err.toString(),
              null,
              { duration: 4000 }
            )
            this.refreshView()
          }
        )
      } else {
        segmento.isOmissis = !segmento.isOmissis
        //  la parola non è tra gli omissis la includo

        const omissis = new Omissis()
        omissis.campo = segmento.campo
        omissis.dirittiSegnalazione = this.dirittiSegnalazione
        omissis.idEntita = segmento.idEntita
        omissis.tutto = tutto
        omissis.oscuramento = false
        omissis.progressivo = segmento.progressivo
        this.segnalazioniService
          .aggiungiOmissisDirittiSegnalazione(omissis)
          .subscribe(
            (esito) => {
              if (esito.esito === ESITO_OK) {
                // console.log(esito);

                const omi = JSON.parse(esito.payload) as Omissis
                omi.dirittiSegnalazione = undefined
                this.dirittiSegnalazione.omissis.push(omi)
              } else {
                this.snackBar.open(
                  $localize`Aggiornamento Omissis Fallito. Errore: ` +
                  esito.payload,
                  null,
                  {
                    duration: 4000,
                  }
                )
              }
            },
            (err) => {
              this.snackBar.open(
                $localize`Aggiornamento Omissis Fallito. Errore: ` +
                err.toString(),
                null,
                {
                  duration: 4000,
                }
              )
            }
          )
      }
    }
  }

  recuperaTestoInChiaroOmissis(segmento: Segment) {
    if (this.modalitaOscuramento) {
      // recupera omissis dal processo di oscuramento
      const omissis = this.processoOscuramento.omissis.find(
        (om) =>
          om.campo === segmento.campo &&
          om.progressivo === segmento.progressivo &&
          om.idEntita === segmento.idEntita
      )
      this.segnalazioniService.recuperaTestoInChiaroOmissis(omissis).subscribe(
        (esito) => {
          if (esito.esito === ESITO_OK) {
            // recupera omissis dal payload
            const parsedOmissis = JSON.parse(esito.payload) as Omissis
            segmento.parola = parsedOmissis['parola']
            // console.log(esito);
          } else {
            this.snackBar.open(
              $localize`Recupero del testo in chiaro per l'omissis Fallito. Errore: ` +
              esito.payload,
              null,
              {
                duration: 8000,
              }
            )
          }
        },
        (err) => {
          this.snackBar.open(
            $localize`Recupero del testo in chiaro per l'omissis Fallito. Errore: ` +
            err.toString(),
            null,
            {
              duration: 4000,
            }
          )
        }
      )
    } else {
      const omissis = this.dirittiSegnalazione.omissis.find(
        (om) =>
          om.campo === segmento.campo &&
          om.progressivo === segmento.progressivo &&
          om.idEntita === segmento.idEntita
      )
      return omissis?.omissis
    }
  }

  segmenti(campo: string, entita: object, tutto?: boolean): Segment[] {
    let attributo = ''

    // Se il campo contiene un punto, accedi all'ultimo campo annidato

    if (campo.includes('.')) {
      const campiAnnidati = campo.split('.')
      // accedi all'ultimo campo annidato
      attributo = entita[campiAnnidati[campiAnnidati.length - 1]]
    } else {
      attributo = entita[campo] || ''
    }

    // rimpiazza i ritorni a capo con la stringa '\n'
    attributo = attributo?.replace(/\n/g, ' \\n ')

    // split per uno o più spazi elimina le parole vuote
    let parole = tutto ? [attributo] : attributo?.split(/[\s]+/)

    // elimina le parole vuote
    parole = parole.filter((parola) => parola?.trim() !== '')

    const omissisData = this.getOmissisData(campo)

    const segments: Segment[] = parole?.map((parola, index) => {
      const isOmissis =
        omissisData &&
        omissisData.find(
          (o) =>
            o.progressivo === index &&
            (o.idEntita === 0 || o.idEntita === entita['id'])
        ) !== undefined
      return {
        campo: campo,
        parola: parola,
        progressivo: index, // usa un progressivo invece di start e end
        isOmissis: isOmissis || parola === 'OMISSIS' || parola === '*OMISSIS*',
        // valorizzo idEntita solo se entita è diverso dalla segnalazione
        idEntita:
          // se entita e della stessa classe della segnalazione metto l'id della segnalazione
          entita['idPerSegnalante']
            ? 0
            : entita['id'] !== undefined && entita['id'] !== null
              ? entita['id']
              : 0, // se non è la segnalazione metto l'
      } as Segment
    })

    return segments
  }

  getOmissisData(campo: string) {
    if (this.modalitaOmissis && !this.modalitaOscuramento) {
      return this.dirittiSegnalazione.omissis !== undefined
        ? this.dirittiSegnalazione.omissis.filter((om) => om.campo === campo)
        : undefined
    } else {
      return this.processoOscuramento.omissis !== undefined
        ? this.processoOscuramento.omissis.filter((om) => om.campo === campo)
        : undefined
    }
  }

  getSegments(
    field: string,
    entita: object,
    tutto?: boolean
  ): Segment[] | Segment {
    if (!entita) {
      return []
    }
    const cacheKey = `${field}-${entita['id']}-${tutto}`
    if (!this.segmentCache[cacheKey]) {
      this.segmentCache[cacheKey] = this.segmenti(field, entita, tutto)
    }
    return this.segmentCache[cacheKey]
  }

  initSegments() {
    this.segmentCache = {}
    this.oggettoSegments = this.getSegments(
      'oggetto',
      this.segnalazione
    ) as Segment[]
    this.descrizioneSegment = this.getSegments(
      'descrizione',
      this.segnalazione,
      true
    ) as Segment
    this.aziendaInteressataSegment = this.getSegments(
      'aziendaInteressata.nomeAzienda',
      this.segnalazione.aziendaInteressata,
      true
    ) as Segment
    this.hashAudioSegment = this.getSegments(
      'hashAudio',
      this.segnalazione
    ) as Segment
    this.descrizioneFattoSegments = this.getSegments(
      'descrizioneFatto',
      this.segnalazione
    ) as Segment[]
    this.segnalanteNomeSegment = this.getSegments(
      'segnalante.nome',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalanteCognomeSegment = this.getSegments(
      'segnalante.cognome',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalanteEmailSegment = this.getSegments(
      'segnalante.email',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalanteTelefonoSegment = this.getSegments(
      'segnalante.telefono',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalanteCodiceFiscaleSegment = this.getSegments(
      'segnalante.codiceFiscale',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalanteIndirizzoDiResidenzaSegment = this.getSegments(
      'segnalante.indirizzoDiResidenza',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalanteCittaSegment = this.getSegments(
      'segnalante.citta',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalanteCapSegment = this.getSegments(
      'segnalante.cap',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalanteRegioneSegment = this.getSegments(
      'segnalante.regione',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalanteStatoSegment = this.getSegments(
      'segnalante.stato',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalanteIncaricoSegment = this.getSegments(
      'segnalante.incarico',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalanteOrgUnitAndSiteSegment = this.getSegments(
      'segnalante.orgUnitAndSite',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalanteQualificaSegment = this.getSegments(
      'segnalante.qualifica',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalanteIncaricoT0Segment = this.getSegments(
      'segnalante.incaricoT0',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalanteRapportoConLaSocietaSegment = this.getSegments(
      'segnalante.rapportoConLaSocieta',
      this.segnalazione.segnalante,
      true
    ) as Segment
    this.segnalazionePeriodoSegment = this.getSegments(
      'periodo',
      this.segnalazione,
      true
    ) as Segment
    this.segnalazioneDataSegment = this.getSegments(
      'data',
      this.segnalazione,
      true
    ) as Segment
    // ora ciclo sui segnalati
    // prepara gli array segnalatoNomeSegments, segnalatoCognomeSegments, segnalatoQualificaSegments
    this.segnalatoNomeSegments = []
    this.segnalatoCognomeSegments = []
    this.segnalatoQualificaSegments = []

    if (this.segnalazione.segnalati) {
      this.segnalazione.segnalati.forEach((segnalato) => {
        this.segnalatoNomeSegments.push(
          this.getSegments('segnalati.nome', segnalato, true) as Segment
      )
      this.segnalatoCognomeSegments.push(
        this.getSegments('segnalati.cognome', segnalato, true) as Segment
      )
      this.segnalatoQualificaSegments.push(
        this.getSegments('segnalati.qualifica', segnalato, true) as Segment
      )
      })
    }

    this.segnalazioneAltreImpreseSegments = this.getSegments(
      'altreImprese',
      this.segnalazione,
      false
    ) as Segment[]
    this.segnalazioneAltriSoggettiSegments = this.getSegments(
      'altriSoggetti',
      this.segnalazione,
      false
    ) as Segment[]
    this.segnalazioneModalitaConoscenzaFattoSegments = this.getSegments(
      'mod',
      this.segnalazione,
      false
    ) as Segment[]

    this.segnalazioneAltroSegments = this.getSegments(
      'others',
      this.segnalazione,
      false
    ) as Segment[]
  }

  recuperaTuttiIDestinatari() {
    this.destinatariSegnalazione = []
    this.segnalazioniService
      .recuperaGruppiGestoriPerAperturaSegnalazione()
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          const gruppi = JSON.parse(esito.payload)
          this.destinatariSegnalazione = gruppi
          this.destinatariSegnalazioneFiltrati = gruppi
          //  Quindi elimina i gruppi già presenti
          this.destinatariSegnalazioneFiltrati =
            this.destinatariSegnalazioneFiltrati.filter((destinatario) => {
              let res = true
              if (
                this.segnalazione &&
                this.segnalazione.dirittiSegnalazioneOdv
              ) {
                res =
                  this.segnalazione.dirittiSegnalazioneOdv.find(
                    (odvtmp) =>
                      odvtmp.odvDestinatario.nomeOdv === destinatario.nomeOdv
                  ) === undefined
              }
              return res
            })
        } else {
          this.snackBar.open(
            $localize`Recupero elenco destinatari Fallito. Errore: ` +
            esito.payload,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Recupero elenco destinatari Fallito. Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
      })
      .finally(() => {
        this.loading = false
      })
  }

  get visualizzaCampoAziendaInteressata() {
    return this.elencoAziende?.length > 1
  }

  recuperaTutteLeAziende() {
    this.elencoAziende = []

    this.aziendaService
      .recuperaAziende()
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          const aziende = JSON.parse(esito.payload)
          this.elencoAziende = aziende
          // se la segnalazione ha una azienda interessata, la sostituisco con la versione completa
          if (
            this.segnalazione &&
            this.segnalazione.aziendaInteressata &&
            this.elencoAziende.length > 0 &&
            this.segnalazione.aziendaInteressata.nomeAzienda !== 'OMISSIS' &&
            this.segnalazione.aziendaInteressata.nomeAzienda !== '*OMISSIS*'
          ) {
            const azienda = this.elencoAziende.find(
              (az) =>
                az.nomeAzienda ===
                this.segnalazione.aziendaInteressata.nomeAzienda
            )
            this.segnalazione.aziendaInteressata = azienda
            this.createForm()
          }
          // se la segnalazione non ha un'azienda interessata, preseleziona la prima azienda
          if (
            this.segnalazione &&
            !this.segnalazione.aziendaInteressata &&
            this.elencoAziende.length === 1 &&
            this.elencoAziende[0].nomeAzienda !== 'OMISSIS' &&
            this.elencoAziende[0].nomeAzienda !== '*OMISSIS*'
          ) {
            this.segnalazione.aziendaInteressata = this.elencoAziende[0]
            this.createForm()
          }
          this.elencoAziendeFiltrato = aziende
          //  Quindi elimina le aziende già presenti
          this.elencoAziendeFiltrato = this.elencoAziendeFiltrato.filter(
            (azienda) => {
              let res = true
              if (this.segnalazione && this.segnalazione.aziendaInteressata) {
                res =
                  [this.segnalazione.aziendaInteressata].find(
                    (az) => az.nomeAzienda === azienda.nomeAzienda
                  ) === undefined
              }
              return res
            }
          )
        } else {
          this.snackBar.open(
            $localize`Recupero elenco aziende Fallito. Errore: ` +
            esito.payload,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Recupero elenco aziende Fallito. Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
      })
      .finally(() => {
        this.loading = false
      })
  }

  recuperaSegnalazione(idSegnalazione) {
    if (idSegnalazione === undefined) {
      return
    }
    this.segnalazioniService.recuperaSegnalazione(idSegnalazione).subscribe(
      (esito) => {
        if (esito.esito === ESITO_OK) {
          if (esito.payload) {
            this.sessionData.nuovaSegnalazioneSelezionata(
              JSON.parse(esito.payload)
            )
          }
        } else {
          this.snackBar.open(
            $localize`Non è stato possibile recuperare la segnalazione ` +
            JSON.stringify(esito.descrizioneEsito),
            null,
            {
              duration: 4000,
            }
          )
        }
        if (this.segnalazione) {
          this.createForm()
        } else {
          console.log('3 in attesa dei dati....')
        }

        this.loadingSegnalazione = false
      },
      () => {
        this.snackBar.open(
          $localize`Non è stato possibile recuperare la segnalazione ` +
          idSegnalazione,
          null,
          {
            duration: 4000,
          }
        )
      },
      () => {
        this.loading = false
        this.loadingSegnalazione = false
      }
    )
  }

  recuperaPersonaSegnalante(idSegnalazione: number) {
    this.destinatariSegnalazione = []
    this.segnalazioniService
      .recuperaPersonaSegnalante(idSegnalazione)
      .then((result: Esito) => {
        if (result.esito === ESITO_OK) {
          const segnalante1 = JSON.parse(result.payload)
          this.segnalazione.segnalante = segnalante1
          this.createForm()
        } else {
          this.saving = false
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Recupero Persona segnalante Fallito. Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
      })
      .finally(() => {
        this.loadingSegnalante = false
      })
  }

  recuperaDatiArchiviazione(idSegnalazione: number) {
    this.destinatariSegnalazione = []
    this.segnalazioniService
      .recuperaDatiArchiviazione(idSegnalazione)
      .then((result: Esito) => {
        if (result.esito === ESITO_OK) {
          this.datiArchiviazione = JSON.parse(result.payload)
        } else {
          this.saving = false
          this.datiArchiviazione = undefined
          this.snackBar.open(
            $localize`Recupero Dati Archiviazione Fallito. Codice Errore: `,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Recupero Dati Archiviazione Fallito. Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
      })
      .finally(() => {
        this.loading = false
      })
  }

  recuperaDirittiSegnalazione(idSegnalazione: number) {
    this.destinatariSegnalazione = []
    if (isNaN(+idSegnalazione)) {
      return
    }
    this.segnalazioniService
      .recuperaDirittiSegnalazioneOdv(idSegnalazione)
      .then((result: Esito) => {
        if (result.esito === ESITO_OK) {
          const dirittiSegnalazione = JSON.parse(result.payload)
          this.segnalazione.dirittiSegnalazioneOdv = dirittiSegnalazione
        } else {
          this.saving = false
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Recupero diritti segnalazione Fallito. Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
      })
      .finally(() => {
        this.loading = false
      })
  }

  elencoDestinatariClicked(event) {
    if (!this.readOnly()) {
      this.recuperaTuttiIDestinatari()
    } else {
      console.warn('modifica negata!!!')
    }
  }

  elencoAziendeClicked(event) {
    if (!this.readOnly()) {
      this.recuperaTutteLeAziende()
    }
  }

  public displayNull(event) {
    event.nativeElement.blur()
    return null
  }

  rimuoviSegnalato(segnalato, ctrlSegnalato) {
    this.segnalazione.segnalati.splice(ctrlSegnalato, 1)
    ctrlSegnalato++
    this.segnalazioneForm.removeControl('nome_segnalato_' + ctrlSegnalato)
    this.segnalazioneForm.removeControl('cognome_segnalato_' + ctrlSegnalato)
    this.segnalazioneForm.removeControl('qualifica_segnalato_' + ctrlSegnalato)
  }

  aggiungiSegnalato(event) {
    const segnalato: Segnalato = new Segnalato()

    if (!this.segnalazione.segnalati) {
      this.segnalazione.segnalati = new Array<Segnalato>()
    }
    this.segnalazione.segnalati.push(segnalato)

    const indice = this.segnalazione.segnalati.length - 1

    this.segnalazioneForm.addControl(
      'nome_segnalato_' + indice,
      new UntypedFormControl({
        value: '',
        disabled: this.readOnly(),
      })
    )
    this.segnalazioneForm.addControl(
      'cognome_segnalato_' + indice,
      new UntypedFormControl({
        value: '',
        disabled: this.readOnly(),
      })
    )

    this.segnalazioneForm.addControl(
      'qualifica_segnalato_' + indice,
      new UntypedFormControl({
        value: '',
        disabled: this.readOnly(),
      })
    )

    this.segnalazioneForm
      .get('nome_segnalato_' + indice)
      .valueChanges.subscribe((value) =>
        this.aggiornaCampo('nome', value, 'segnalato', indice)
      )
    this.segnalazioneForm
      .get('cognome_segnalato_' + indice)
      .valueChanges.subscribe((value) =>
        this.aggiornaCampo('cognome', value, 'segnalato', indice)
      )
    this.segnalazioneForm
      .get('qualifica_segnalato_' + indice)
      .valueChanges.subscribe((value) =>
        this.aggiornaCampo('qualifica', value, 'segnalato', indice)
      )

    // mi assicuro che i nuovi campi siano tutti vuoti
    this.segnalazioneForm.get('nome_segnalato_' + indice).setValue('')
    this.segnalazioneForm.get('cognome_segnalato_' + indice).setValue('')
    this.segnalazioneForm.get('qualifica_segnalato_' + indice).setValue('')
  }

  salvaSegnalazione() {
    // mi assicuro che le obbligatorietà legate all'anonimato siano rispettate
    this.modificaObbligatorietaSuAnonimatoSegnalazione(this.segnalazioneForm.get('anonima').value)
    if (!this.saving) {
      this.saving = true
      // se non c'è un audio il campo oggetto è obbligatorio
      if (!this.segnalazioneForm.get('oggetto').value && !this.segnalazione.hashAudio) {
        this.snackBar.open($localize`Il campo oggetto è obbligatorio!`, null, {
          duration: 6000,
        })
        this.saving = false
        return
      }
      if (
        !this.segnalazioneForm.get('descrizione').value &&
        this.mostraTipiNonConformita && !this.segnalazione.hashAudio
      ) {
        this.snackBar.open(
          $localize`Il campo tipologia è obbligatorio!`,
          null,
          {
            duration: 6000,
          }
        )
        this.saving = false
        return
      }
      if (!this.segnalazioneForm.get('societa').value) {
        if (this.elencoAziende.length === 1) {
          this.segnalazione.aziendaInteressata = this.elencoAziende[0]
        } else if (this.elencoAziende.length > 1 && !this.segnalazione.hashAudio) {
          this.snackBar.open(
            $localize`Il campo azienda a cui si riferisce il fatto è obbligatorio!`,
            null,
            {
              duration: 6000,
            }
          )
          this.saving = false
          return
        } else {
          this.snackBar.open(
            $localize`Non sono disponibili aziende alle quali associare la segnalazione, potrebbe essere un problema temporaneo!`,
            null,
            {
              duration: 12000,
            }
          )
          this.saving = false
          return
        }
      }
      let nuovaSegnalazione1 = false
      if (this.segnalazione.id == null || this.segnalazione.id.length === 0) {
        nuovaSegnalazione1 = true
        if (this.inviaSegnalazione) {
          this.segnalazione.stato =
            NESSUNA_LAVORAZIONE_IN_CORSO_INDEX.toString()
        } else {
          this.segnalazione.stato = BOZZA_INDEX.toString()
        }
      }

      if (this.inviaSegnalazione) {
        this.segnalazione.stato = NESSUNA_LAVORAZIONE_IN_CORSO_INDEX.toString()
      }

      // verifica che eventuali segnalati presenti abbiano almeno un campo non vuoto e non nullo
      if (this.segnalazione.segnalati) {
        // tengo solo i segnalati per i quali almeno un campo nome o cognome sia valorizzato quindi non null e non vuoto
        this.segnalazione.segnalati = this.segnalazione.segnalati.filter(
          (segnalato) =>
            (segnalato.nome && segnalato.nome.length > 0) ||
            (segnalato.cognome && segnalato.cognome.length > 0)
        )
      }

      // aggiorna il form relativamente ai controller dei segnalati
      if (this.segnalazione.segnalati) {
        this.segnalazione.segnalati.forEach((segnalato, index) => {
          this.segnalazioneForm
            .get('nome_segnalato_' + index)
            .setValue(segnalato.nome)
          this.segnalazioneForm
            .get('cognome_segnalato_' + index)
            .setValue(segnalato.cognome)
          this.segnalazioneForm
            .get('qualifica_segnalato_' + index)
            .setValue(segnalato.qualifica)
        })
      }


      // const allegati = this.sessionData.getSegnalazioneSelezionata().documenti;

      const clonedSegnalazione = {
        ...this.sessionData.getSegnalazioneSelezionata(),
      }
      clonedSegnalazione['matData'] = undefined

      this.segnalazioniService
        .salvaSegnalazione(clonedSegnalazione)
        .then((result: Esito) => {
          // console.log(result);
          // rivalida i dati del segnalante per evitare problemi di validazione

          this.rivalidaCampiSegnalante()

          if (result.esito === ESITO_OK) {

            this.datiSegnalanteInModifica = false
            const segnalazioneRicevuta = JSON.parse(result.payload)
            if (nuovaSegnalazione1) {
              const allegati = segnalazioneRicevuta.documenti
              if (allegati && allegati.length > 0) {
                this.segnalazioniService.notificaSalvataggioDocumenti.next(
                  allegati
                )
                this.sessionData.getSegnalazioneSelezionata().documenti = allegati
              }
              //  elimino le segnalazioni senza id
              this.sessionData.segnalazioni =
                this.sessionData.segnalazioni.filter(
                  (segna) => segna.id !== undefined
                )
              // verifica se la segnalazione è già presente
              const indice = this.sessionData.segnalazioni.findIndex(
                (segna) => segna.id === segnalazioneRicevuta.id
              )
              if (indice !== -1) {
                this.sessionData.segnalazioni.splice(
                  indice,
                  1,
                  segnalazioneRicevuta
                )
              } else {
                this.sessionData.segnalazioni.push(segnalazioneRicevuta)
              }

              // this.tabellaSegnalazioni.data = this.sessionData.segnalazioni;
            }
            this.segnalazioneForm.markAsPristine()
            this.segnalazioneForm.markAsUntouched()
            this.segnalazioneForm.updateValueAndValidity()
            if (segnalazioneRicevuta.stato !== BOZZA_INDEX.toString()) {
              this.segnalazioneForm.disable()
            }
            // this.segnalazioneForm.disable();
            this.saving = false
            // this.createForm();
            if (this.inviaSegnalazione) {
              this.snackBar.open($localize`Segnalazione inviata`, null, {
                duration: 2000,
              })
              this.messageStore.initMessageStore()
            } else {
              this.snackBar.open($localize`Segnalazione salvata`, null, {
                duration: 2000,
              })
            }
            this.inviaSegnalazione = false
          } else {
            this.datiSegnalanteInModifica = false
            this.saving = false
            if (this.inviaSegnalazione) {
              this.snackBar.open($localize`Invio Fallito`, null, {
                duration: 4000,
              })
            } else {
              this.snackBar.open($localize`Salvataggio Fallito`, null, {
                duration: 4000,
              })
            }
            this.inviaSegnalazione = false
          }
        })
        .catch((err) => {
          this.datiSegnalanteInModifica = false
          this.saving = false
          if (this.inviaSegnalazione) {
            this.snackBar.open($localize`Invio Fallito. Codice Errore: `, err, {
              duration: 4000,
            })
          } else {
            this.snackBar.open(
              $localize`Salvataggio Fallito. Codice Errore: `,
              err,
              {
                duration: 4000,
              }
            )
          }
          this.inviaSegnalazione = false
        })
    } else {
      this.snackBar.open($localize`Salvataggio in corso!`, null, {
        duration: 2000,
      })
    }
  }

  rivalidaCampiSegnalante() {
    this.campiSegnalante.forEach(campo => {
      const control = this.segnalazioneForm.get(campo.campo);
      if (control) {
        control.setValidators(campo.customValidators);
        control.updateValueAndValidity();
      }
    });
  }

  salvaSegnalazioneInLocalStorage() {

    // se non c'è un audio il campo oggetto è obbligatorio

    let nuovaSegnalazione1 = false
    if (this.segnalazione.id == null || this.segnalazione.id.length === 0) {
      nuovaSegnalazione1 = true
      if (this.inviaSegnalazione) {
        this.segnalazione.stato =
          NESSUNA_LAVORAZIONE_IN_CORSO_INDEX.toString()
      } else {
        this.segnalazione.stato = BOZZA_INDEX.toString()
      }
    }

    // se non è una nuova segnalazione e non è in bozza non permette di salvare
    if (!nuovaSegnalazione1) {
      return
    }

    console.log('Salvo la segnalazione nel local storage poichè è in corso di modifica e non ancora salvata')

    if (this.inviaSegnalazione) {
      this.segnalazione.stato = NESSUNA_LAVORAZIONE_IN_CORSO_INDEX.toString()
    }

    // verifica che eventuali segnalati presenti abbiano almeno un campo non vuoto e non nullo
    if (this.segnalazione.segnalati) {
      // tengo solo i segnalati per i quali almeno un campo nome o cognome sia valorizzato quindi non null e non vuoto
      this.segnalazione.segnalati = this.segnalazione.segnalati.filter(
        (segnalato) =>
          (segnalato.nome && segnalato.nome.length > 0) ||
          (segnalato.cognome && segnalato.cognome.length > 0)
      )
    }

    // aggiorna il form relativamente ai controller dei segnalati
    if (this.segnalazione.segnalati) {
      this.segnalazione.segnalati.forEach((segnalato, index) => {
        this.segnalazioneForm
          .get('nome_segnalato_' + index)
          .setValue(segnalato.nome)
        this.segnalazioneForm
          .get('cognome_segnalato_' + index)
          .setValue(segnalato.cognome)
        this.segnalazioneForm
          .get('qualifica_segnalato_' + index)
          .setValue(segnalato.qualifica)
      })
    }


    // const allegati = this.sessionData.getSegnalazioneSelezionata().documenti;

    const clonedSegnalazione = {
      ...this.sessionData.getSegnalazioneSelezionata(),
    }
    clonedSegnalazione['matData'] = undefined

    // salva anche i dati di sessione
    const sessionDataString = JSON.stringify(this.sessionData)
    const sessionDataBase64 = btoa(sessionDataString)
    localStorage.setItem('sessionData', sessionDataBase64)
    console.log('Sessione salvata in local storage')

    // salva eventuali segnalati
    if (this.segnalazione.segnalati) {
      const segnalatiString = JSON.stringify(this.segnalazione.segnalati)
      const segnalatiBase64 = btoa(segnalatiString)
      localStorage.setItem('segnalati', segnalatiBase64)
      console.log('Segnalati salvati in local storage')
    }

    // salva in local storage codificata in base 64

    const segnalazioneString = JSON.stringify(clonedSegnalazione)
    const segnalazioneBase64 = btoa(segnalazioneString)
    localStorage.setItem('segnalazione', segnalazioneBase64)
    console.log('Segnalazione salvata in local storage')

    // salva pincode in local storage
    // prima lo codifica base64
    const pinCodeBase64 = btoa(this.pinCode)
    localStorage.setItem('pincode', pinCodeBase64)
    console.log('Pincode salvato in local storage')

  }

  persistente() {
    // console.log('this.sessionData.getSegnalazioneSelezionata().id:' + this.sessionData.getSegnalazioneSelezionata().id);
    return (
      this.sessionData.getSegnalazioneSelezionata() &&
      this.sessionData.getSegnalazioneSelezionata().id != null &&
      +this.sessionData.getSegnalazioneSelezionata().id > 0
    )
  }

  _convertiStatoSegnalazione(segnalazione): string {
    return statoSegnalazioneLeggibile(this.authService.getUser().odv ? segnalazione.stato : segnalazione.statoPerSegnalante ?
      segnalazione.statoPerSegnalante : segnalazione.stato,
      !this.authService.getUser().odv)
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    // console.log('${type}: ${event.value}' + event.value);
    // console.log('moment:' + moment(event.value, 'DD/MM/YYYY'));
    // prende ora e minuti dai due campi
    this.segnalazione.data = moment(event.value, 'DD/MM/YYYY').toDate()
    const ora = this.segnalazioneForm.get('ora').value
    const minuti = this.segnalazioneForm.get('minuti').value
    // assegna a oraInt e minutiInt i valori numerici se undefined li assegna a 0
    const oraInt = ora ? +ora : 0
    const minutiInt = minuti ? +minuti : 0
    this.segnalazione.data.setTime(
      new Date(
        this.segnalazione.data.getFullYear(),
        this.segnalazione.data.getMonth(),
        this.segnalazione.data.getDate(),
        oraInt,
        minutiInt
      ).getTime()
    )
  }

  mittenteSegnalazione(): boolean {
    return mittenteSegnalazione(this.segnalazione, this.authService.getUser())
  }

  puoEssereAbilitatoAllaConsultazioneSegnalante(): boolean {
    return puoEssereAbilitatoAllaConsultazioneDelSegnalante(
      this.segnalazione,
      this.authService.getUser()
    )
  }

  ricevutoConsensoDaSegnalante(diritto: DirittiSegnalazione) {
    if (diritto.sceltoDaSegnalante) {
      return true
    }
    return odvHaRicevutoConsensoDaSegnalante(
      diritto.odvCheHaInoltrato,
      this.segnalazione
    )
  }

  consensoNecessario(diritto: DirittiSegnalazione) {
    return (
      this.sessionData.configurazione?.consensiSegnalante &&
      !diritto.sceltoDaSegnalante
    )
  }

  richiestaSbloccoSegnalanteInEsame(diritto: DirittiSegnalazione): boolean {
    let richiesteInCorso = []

    richiesteInCorso =
      isMembroDiGruppo(this.authService.getUser(), diritto.odvDestinatario) &&
        diritto.dataRichiestaSbloccoVisibilitaSegnalante &&
        ((!diritto.richiestaSbloccoVisibilitaSegnalanteAccolta &&
          !diritto.richiestaSbloccoVisibilitaSegnalanteRespinta) ||
          (diritto.richiestaSbloccoVisibilitaSegnalanteAccolta !==
            diritto.richiestaSbloccoVisibilitaSegnalanteRespinta &&
            diritto.dataRichiestaSbloccoVisibilitaSegnalante >
            diritto.dataRispostaARichiestaSbloccoVisibilitaSegnalante))
        ? [diritto]
        : []

    return richiesteInCorso?.length > 0
  }

  esitoRichestaSbloccoSegnalante(diritto: DirittiSegnalazione): boolean {
    const richiesteInCorso = []

    if (
      isMembroDiGruppo(this.authService.getUser(), diritto.odvDestinatario) &&
      diritto.dataRichiestaSbloccoVisibilitaSegnalante &&
      diritto.richiestaSbloccoVisibilitaSegnalanteAccolta !==
      diritto.richiestaSbloccoVisibilitaSegnalanteRespinta
    ) {
      richiesteInCorso.push(diritto)
    }
    return richiesteInCorso?.[0]?.richiestaSbloccoVisibilitaSegnalanteAccolta
  }

  motivazioneRispostaRichiestaDiSbloccoSegnalante(
    diritto: DirittiSegnalazione
  ): string {
    let motivazione = ''

    if (
      isMembroDiGruppo(this.authService.getUser(), diritto.odvDestinatario) &&
      diritto.dataRichiestaSbloccoVisibilitaSegnalante &&
      diritto.richiestaSbloccoVisibilitaSegnalanteAccolta !==
      diritto.richiestaSbloccoVisibilitaSegnalanteRespinta
    ) {
      motivazione =
        diritto.motivazioneRispostaARichiestaSbloccoVisibilitaSegnalante
    }
    return motivazione
  }

  puoConsultareSegnalante(diritto): boolean {
    return puoConsultareNomeSegnalante(
      diritto,
      this.segnalazione,
      this.authService.getUser()
    )
  }

  mostraDatiSegnalante(): boolean {
    return this.segnalanteVisibile
  }

  custodiaAbilitata(): boolean {
    return this.sessionData.configurazione?.custodiaIdentita
  }

  // necessariaLavorazionePerConsultareSegnalati(): boolean {
  //   return (
  //     puoConsultareNomeSegnalatiAlNettoLavorazione(
  //       this.segnalazione,
  //       this.authService.getUser()
  //     ) &&
  //     !puoConsultareNomeSegnalati(this.segnalazione, this.authService.getUser())
  //   )
  // }

  puoConsultareSegnalati(): boolean {
    return this.isMiaSegnalazione() || puoConsultareNomeSegnalati(
      this.segnalazione,
      this.authService.getUser()
    )
  }

  isMiaSegnalazione() {
    return (
      this.segnalazione?.segnalante?.utente?.id ===
      this.authService.getUser()?.id
    )
  }

  esisteId(): boolean {
    return this.segnalazione != null && this.segnalazione.id != null
      ? true
      : false
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      //  this.salvaSegnalazione();
      // rest of your code
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: BeforeUnloadEvent) {
    if (this.segnalazioneForm.dirty) { // Condizione da verificare
      event.returnValue = $localize`Hai dei cambiamenti non salvati. Sei sicuro di voler lasciare la pagina?`;
    }
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number =
      this.window.scrollY ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0
    if (number >= 100) {
      this.navIsFixed = true
    } else if (this.navIsFixed && number < 100) {
      this.navIsFixed = false
    }
    // console.log('scroll:' + number)
  }

  openChat() {
    this.router.navigate(['chat-manager'], {
      queryParams: { chatSegnalanteGestore: true },
    })
  }

  segnalazioneSottomessa(segnalazione: Segnalazione) {
    return segnalazione?.stato !== '1'
  }

  selectedDestinatario(destinatario: Odv) {
    // console.log('destinatario selezionato ' + destinatario.nomeOdv);
    if (
      this.segnalazione.dirittiSegnalazioneOdv &&
      this.segnalazione.dirittiSegnalazioneOdv.find(
        (tmpdestinatario) =>
          tmpdestinatario.odvDestinatario.nomeOdv === destinatario.nomeOdv
      )
    ) {
      // console.log('destinatario già incluso');
    } else {
      if (!this.segnalazione.dirittiSegnalazioneOdv) {
        this.segnalazione.dirittiSegnalazioneOdv =
          new Array<DirittiSegnalazione>()
      }
      const dirittiSegnalazione = new DirittiSegnalazione()
      dirittiSegnalazione.aperturaLavorazioni = true
      dirittiSegnalazione.comunicazioneConSegnalante = true
      dirittiSegnalazione.consultazioneSegnalazione = true
      dirittiSegnalazione.odvCheHaInoltrato = undefined
      dirittiSegnalazione.odvDestinatario = destinatario
      dirittiSegnalazione.sceltoDaSegnalante = true
      dirittiSegnalazione.puoVedereSegnalante = true
      dirittiSegnalazione.puoVedereSegnalati = true
      dirittiSegnalazione.consultazioneSegnalazioneSospesa = false
      this.segnalazione.dirittiSegnalazioneOdv.push(dirittiSegnalazione)
      this.segnalazioneForm
        .get('destinatari')
        .setValue(this.segnalazione.dirittiSegnalazioneOdv)
    }
    this.recuperaTuttiIDestinatari()
    this.inputDestinatari.nativeElement.blur()
  }

  rimuoviDestinatario(destinatario: DirittiSegnalazione) {
    const destIndex = this.segnalazione.dirittiSegnalazioneOdv?.findIndex(
      (dsegna) =>
        +dsegna.odvDestinatario?.id === +destinatario?.odvDestinatario.id
    )
    this.segnalazione.dirittiSegnalazioneOdv.splice(destIndex, 1)
  }

  addDestinatario(event: MatChipInputEvent): void {
    const input: MatChipInput = event.chipInput
    const value = input.inputElement.value
    this.inputDestinatari.nativeElement.value = ''
    if (this.destinatariSegnalazioneFiltrati.length === 1) {
      this.selectedDestinatario(this.destinatariSegnalazioneFiltrati.pop())
    }

    // Reset the input value
    if (input) {
      input.inputElement.value = ''
      this.recuperaTuttiIDestinatari()
    }
  }

  aggiornaDocumento(documento: Documento) {
    // ricarica i documenti
    this.reloadSegnalazione()
  }

  aggiungiDocumento(documento: Documento) { }

  salvaDocumento(documento: Documento) {
    this.loadingDocumenti = true;
    //  this.sessionData.getSegnalazioneSelezionata().documenti.push(documento);
    this.documentiService
      .collegaDocumentoASegnalazione(documento, this.segnalazione)
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          const tempDoc = JSON.parse(esito.payload)
          const indice = this.elencoAllegati.documenti.findIndex(
            (docu) => docu.id === tempDoc.id
          )
          if (indice !== -1) {
            this.elencoAllegati.documenti.splice(indice, 1, tempDoc)
          } else {
            this.elencoAllegati.documenti.push(tempDoc)
          }

          this.elencoAllegati.refresh()
        } else {
          this.snackBar.open(
            $localize`Il salvataggio del documento è fallito!` +
            esito.descrizioneEsito,
            null,
            {
              duration: 6000,
            }
          )
        }
        this.loadingDocumenti = false;
      })
      .catch((errore) => {
        this.loadingDocumenti = false;
        // console.log(errore);
        this.snackBar.open($localize`Il salvataggio è fallito!`, null, {
          duration: 6000,
        })
      })
  }

  doLoadingDocumenti(loading: boolean) {
    // se loading è false attendo 5 secondi e poi lo setto a false
    if (!loading) {
      setTimeout(() => {
        this.loadingDocumenti = false;
      }, 5000);
    } else {
      this.loadingDocumenti = loading;
    }
  }

  scollegaDocumento(documento: Documento) {
    this.sessionData.getSegnalazioneSelezionata().documenti =
      this.sessionData.getSegnalazioneSelezionata().documenti.filter(
        (documentotemp) => documentotemp.nome !== documento.nome
      )
    if (this.sessionData.getSegnalazioneSelezionata().id !== undefined) {
      this.documentiService
        .scollegaDocumentoDaSegnalazione(
          documento,
          this.sessionData.getSegnalazioneSelezionata()
        )
        .then(() => {
          // console.log('eliminazione fisica del documento sul server eseguita');
          this.elencoAllegati.documenti =
            this.sessionData.getSegnalazioneSelezionata().documenti
          this.elencoAllegati.refresh()
        })
        .catch((errore) => {
          // console.log(errore);
          this.snackBar.open(
            $localize`Eliminazione fisica del file sul server fallita!`,
            null,
            {
              duration: 6000,
            }
          )
        })
    } else {
      this.elencoAllegati.documenti =
        this.sessionData.getSegnalazioneSelezionata().documenti
      this.elencoAllegati.refresh()
    }
  }

  abilitaCondivisioneDocumento(documento) {
    console.log('abilitaCondivisioneDocumento', documento)
  }

  disabilitaCondivisioneDocumento(documento) {
    console.log('disabilitaCondivisioneDocumento', documento)
  }

  sbloccaSegnalato(): void {
    const dialogRef = this.dialog.open(ConfermaSbloccoDatiDialogComponent, {
      width: '500px',
      data: { tipoDati: 'del segnalato' },
      disableClose: true,
      hasBackdrop: true,
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.segnalazioniService
          .sbloccaSegnalato(this.segnalazione)
          .then((esito: Esito) => {
            if ((esito.esito == ESITO_OK)) {
              // console.log('Segnalazione ricevuta dal backend:', esito.payload)
              if (esito.payload) {
                this.sessionData.nuovaSegnalazioneSelezionata(
                  JSON.parse(esito.payload)
                )
              }

              this.segnalazione.segnalatoSbloccato = true
              // this.initSegments()
              // this.cd.detectChanges();
              this.snackBar.open($localize`Segnalato Sbloccato`, null, {
                duration: 2000,
              })
            }
          })
          .catch((errore) => {
            this.snackBar.open(
              $localize`Errore nello sblocco:` + errore,
              null,
              {
                duration: 4000,
              }
            )
          })
      }
    })
  }

  richiediAccessoAlCustode(diritto: DirittiSegnalazione): void {
    if (this.dialogRefRichiestaSbloccoSegnalante === undefined) {
      this.dialogRefRichiestaSbloccoSegnalante = this.dialog.open(
        RichiestaSbloccoSegnalanteDialogComponent,
        {
          width: '800px',
          data: {
            diritti: [diritto],
          },
          hasBackdrop: true,
        }
      )
      this.dialogRefRichiestaSbloccoSegnalante.afterClosed().subscribe(() => {
        this.dialogRefRichiestaSbloccoSegnalante = undefined
      })
    }
  }

  get dirittiUtenteSullaSegnalazione() {
    return dirittiUtenteSullaSegnalazione(
      this.authService.getUser(),
      this.segnalazione
    )
  }

  sbloccaSegnalante(diritto: DirittiSegnalazione): void {
    this.dirittoSegnalazioneSelezionato = diritto
    this.segnalazione['dirittoOdvImpersonato'] = diritto
    const dialogRef = this.dialog.open(ConfermaSbloccoDatiDialogComponent, {
      width: '500px',
      data: { tipoDati: 'del segnalante' },
      disableClose: true,
      hasBackdrop: true,
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.segnalanteVisibile = true
        this.logOperativoService
          .saveLogOperativo(
            Operazioni.PresaVisioneDatiSegnalante,
            this.segnalazione,
            undefined,
            this.dirittoSegnalazioneSelezionato
          )
          .subscribe((esito: Esito) => {
            if (esito.esito === ESITO_OK) {
              console.log('log operativo salvato')
            } else {
              console.error(
                'log operativo non salvato',
                esito.descrizioneEsito,
                esito.payload
              )
            }
          })
      }
    })
  }

  datiSegnalanteVisibili(diritto?: DirittiSegnalazione) {
    if (this.custodiaAbilitata && this.isCustode && this.segnalanteVisibile) {
      return true
    }
    if (
      (this.isMiaSegnalazione() || this.segnalazione.id === undefined) &&
      !this.segnalazione.anonima
    ) {
      return true
    }
    if (!diritto) {
      return false
    }
    const result =
      this.segnalanteVisibile &&
      !this.segnalazioneForm.controls.anonima.value &&
      this.isCustode() &&
      ((this.custodiaAbilitata() &&
        !this.richiestaSbloccoSegnalanteInEsame(diritto) &&
        this.esitoRichestaSbloccoSegnalante(diritto)) ||
        !this.custodiaAbilitata()) &&
      (!this.sessionData.configurazione?.consensiSegnalante ||
        (this.sessionData.configurazione?.consensiSegnalante &&
          dirittiUtenteSullaSegnalazione(
            this.authService.getUser(),
            this.segnalazione
          )?.find((diritto1) => this.ricevutoConsensoDaSegnalante(diritto1))) ||
        (this.sessionData.configurazione?.consensiSegnalante &&
          diritto.sceltoDaSegnalante))
    return result
  }

  gestoreSceltoDaSegnalanteSenzaLavorazioni(diritto: DirittiSegnalazione) {
    const sceltoDaSegnalante =
      diritto.sceltoDaSegnalante &&
      isMembroDiGruppo(this.authService.getUser(), diritto.odvDestinatario)
    // const sceltoDaSegnalante = isUtenteMembrodiOdvSceltoDaSegnalante(this.segnalazione, this.authService.getUser());
    if (sceltoDaSegnalante) {
      return (
        !isUtenteGestoreDiAlmenoUnaLavorazione(this.segnalazione, diritto) &&
        !this.segnalazione.anonima
      )
    } else {
      return true
    }
  }

  // gestoreSenzaLavorazioni(diritto: DirittiSegnalazione) {
  //   return (
  //     !isUtenteGestoreDiAlmenoUnaLavorazione(this.segnalazione, diritto) &&
  //     !this.segnalazione.anonima
  //   )
  // }

  puoInoltrareRichiestaAlCustode(diritto: DirittiSegnalazione) {
    const result =
      this.custodiaAbilitata() &&
      !this.segnalazione.anonima &&
      this.odv &&
      this.segnalazione.id &&
      !this.mittenteSegnalazione() &&
      !this.puoConsultareSegnalante(diritto) &&
      this.puoEssereAbilitatoAllaConsultazioneSegnalante() &&
      !this.richiestaSbloccoSegnalanteInEsame(diritto)
    return result
  }

  consultazioneSegnalanteAbilitabile(diritto: DirittiSegnalazione) {
    const result =
      this.segnalazione.id &&
      // !this.gestoreSenzaLavorazioni(diritto) &&
      !this.datiSegnalanteVisibili(diritto) &&
      !this.segnalazione.anonima &&
      ((this.custodiaAbilitata() &&
        this.odv &&
        !this.mittenteSegnalazione() &&
        this.puoConsultareSegnalante(diritto) &&
        this.puoEssereAbilitatoAllaConsultazioneSegnalante() &&
        !this.richiestaSbloccoSegnalanteInEsame(diritto) &&
        this.esitoRichestaSbloccoSegnalante(diritto) === true) ||
        (!this.custodiaAbilitata() &&
          this.puoEssereAbilitatoAllaConsultazioneSegnalante())) &&
      (!this.custodiaAbilitata() ||
        this.motivazioneRispostaRichiestaDiSbloccoSegnalante(
          diritto
        )?.trim() !== '') &&
      (!this.sessionData.configurazione?.consensiSegnalante ||
        (this.sessionData.configurazione?.consensiSegnalante &&
          this.ricevutoConsensoDaSegnalante(diritto)) ||
        (this.sessionData.configurazione?.consensiSegnalante &&
          diritto.sceltoDaSegnalante))
    return result
  }

  nonAbilitatoAllaConsultazioneDatiSegnalante(diritto: DirittiSegnalazione) {
    const result =
      !this.datiSegnalanteVisibili(diritto) &&
      this.segnalazione.id &&
      !this.segnalazione.anonima &&
      !this.puoEssereAbilitatoAllaConsultazioneSegnalante()
    return result
  }

  isCustode() {
    return (
      this.authService.getUser()?.custode &&
      this.sessionData.configurazione?.custodiaIdentita
    )
  }

  get marginTop() {
    return this.condivisioneSegnalazioneNellaSchedaSegnalazione
      ? '104px'
      : '3px'
  }

  get mostraSocietaRiferimentoFatto() {
    return (
      this.elencoAziende?.length > 1 && this.segnalazione.aziendaInteressata
    )
  }

  get mostraTipiNonConformita() {
    return this.sessionData.configurazione
      ?.mostraTipiNonConformitaSuDettaglioSegnalazione
  }

  get eliminazionePianificata() {
    return (
      this.datiArchiviazione?.dataEliminazioneGestore !== undefined ||
      this.datiArchiviazione?.dataEliminazioneSistema !== undefined
    )
  }

  get messaggioPerSegnalazioneDaCancellare() {
    if (this.segnalazione.sospendiCancellazione) {
      return $localize`Termini di cancellazione sospesi`
    }
    // se dataEliminazionePiuVicina è nel passato allora il messaggio deve dire che sta per essere cancellata
    if (this.dataEliminazionePiuVicina < new Date()) {
      return $localize`Questa segnalazione e tutti i dati collegati saranno cancellati nelle prossime ore ${this.calcolaDecisore}`
    }
    // eslint-disable-next-line max-len
    return $localize`Questa segnalazione e tutti i dati collegati saranno eliminati in data ${new DataPipe().transform(
      this.dataEliminazionePiuVicina
    )} ${this.calcolaDecisore}`
  }

  get testoPerSegnalazioneDaCancellare() {
    if (this.segnalazione.sospendiCancellazione) {
      return $localize`Termini di cancellazione sospesi`
    }
    return $localize`Avviso di prossima cancellazione`
  }

  get terminiCancellazioneSospesi() {
    return this.segnalazione.sospendiCancellazione
  }

  get dataEliminazionePiuVicina() {
    if (
      this.datiArchiviazione.dataEliminazioneGestore &&
      (!this.datiArchiviazione.dataEliminazioneSistema ||
        this.datiArchiviazione.dataEliminazioneSistema >
        this.datiArchiviazione.dataEliminazioneGestore)
    ) {
      return new Date(this.datiArchiviazione.dataEliminazioneGestore)
    } else {
      return new Date(this.datiArchiviazione.dataEliminazioneSistema)
    }
  }

  get calcolaDecisore() {
    if (
      this.datiArchiviazione.dataEliminazioneGestore &&
      (!this.datiArchiviazione.dataEliminazioneSistema ||
        this.datiArchiviazione.dataEliminazioneSistema >
        this.datiArchiviazione.dataEliminazioneGestore)
    ) {
      return $localize`per decisione del gruppo ${this.datiArchiviazione.odv.nomeOdv}`
    } else {
      return $localize`per scadenza dei termini di permanenza sul sistema`
    }
  }

  convertiStatoLavorazione(indice): string {
    return indice ? statoLavorazioneLeggibile(indice) : $localize`Indefinito`
  }

  gestore(lavorazione: Lavorazione): any {
    const dirittogestore = lavorazione.dirittiOdv.find(
      (diritto) => diritto.gestore
    )
    return dirittogestore ? dirittogestore.odv.nomeOdv : $localize`Nessuno`
  }

  mostraDestinatari(): any {
    return this.sessionData.configurazione?.segnalanteVedeOdv
  }

  puoScegliereDestinatario(): any {
    return this.sessionData.configurazione?.segnalantePuoScegliereOdv
  }

  isGestore(): boolean {
    return this.authService.getUser()?.odv && !this.isBozza()
  }

  isBozza(): boolean {
    const isBozza =
      this.segnalazione?.stato === undefined ||
      this.segnalazione?.stato === BOZZA_INDEX.toString() ||
      this.segnalazione?.stato === '0'
    return isBozza
  }

  get dataEliminazioneBozza() {
    // calcola la data eliminazione della bozza sommando alla data di inserimento
    // il numero di giorni indicati dal paramentro di configurazione limiteGiorniSottomissioneSegnalazione
    if (!this.segnalazione || !this.segnalazione.dataInserimento) {
      return undefined
    }
    const dataEliminazioneBozza = new Date(this.segnalazione.dataInserimento)
    dataEliminazioneBozza.setDate(
      dataEliminazioneBozza.getDate() +
      this.sessionData.configurazione?.limiteGiorniSottomissioneSegnalazione
    )
    return dataEliminazioneBozza
  }

  get dataCompletamentoIndagine() {
    // calcola la data completamento indagine sommando alla data di invio
    // il numero di giorni indicati dal paramentro di configurazione limiteGiorniChiusuraIndagineSegnalazione
    if (!this.segnalazione.dataInserimento) {
      return undefined
    }
    // ricava la data di scadenza sommando alla data invio il numero di giorni presenti nel campo
    return helperSegnalazioni.scadenza(
      this.segnalazione,
      this.sessionData.configurazione
    )
  }

  get condivisioneSegnalazioneNellaSchedaSegnalazione() {
    return this.sessionData.configurazione
      ?.condivisioneSegnalazioneNellaSchedaSegnalazione
  }

  recuperaPoiMostraChatConSegnalanteOGestore(lavorazione: Lavorazione) {
    if (
      lavorazione.segnalazione === undefined ||
      lavorazione.segnalazione.id === undefined
    ) {
      lavorazione.segnalazione = this.segnalazione
    }
    lavorazione.segnalazione = this.segnalazione
    apriChatLavorazione(
      lavorazione,
      this.chatService,
      this.authService,
      this.router,
      this.snackBar,
      this.sessionData
    )
  }

  hoAccessoAllaLavorazione(lavorazione: Lavorazione) {
    return (
      lavorazione.chatConSegnalante &&
      lavorazione.dirittiOdv.find(
        (diritto) =>
          isMembroDiGruppo(this.authService.getUser(), diritto.odv) &&
          diritto.gestore
      ) !== undefined
    )
  }

  openPanel(): void {
    setTimeout(() => {
      this.autoTrigger.openPanel()
    }, 1)
  }

  openPanelAziende(): void {
    setTimeout(() => {
      this.autoTrigger2.openPanel()
    }, 1)
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      this.segnalazioneForm &&
      this.segnalazioneForm.dirty &&
      !this.authService.scaduta
    ) {
      return confirm(
        'Hai delle modifiche non salvate. Sei sicuro di voler uscire?'
      )
    } else if (
      !this.authService.scaduta &&
      this.formLavorazione &&
      this.formLavorazione.some((form) => form.dirty)
    ) {
      return confirm(
        'Hai delle modifiche non salvate. Sei sicuro di voler uscire?'
      )
    }
    return true
  }

  editAbilitato() {
    return !this.readOnly()
  }

  togliObbligatorieta() {
    // tolgo l'obbligatorietà al campo con form control descrizioneFatto
    this.segnalazioneForm.get('descrizioneFatto').clearValidators()
    this.segnalazioneForm.get('descrizioneFatto').updateValueAndValidity()
    this.segnalazioneForm.get('descrizioneFatto').setErrors(null)
    this.segnalazioneForm.get('descrizioneFatto').markAsPristine()
    this.segnalazioneForm.get('descrizioneFatto').markAsUntouched()

    // tolgo l'obbligatorietà all'oggetto
    // se la segnalazione è solo vocale allora togli l'obbligatorietà dell'oggetto
    if (this.segnalazioneForm.get('segnalazioneVocale').value === true) {
      this.segnalazioneForm.get('oggetto').clearValidators()
      this.segnalazioneForm.get('oggetto').updateValueAndValidity()
      this.segnalazioneForm.get('oggetto').setErrors(null)
      this.segnalazioneForm.get('oggetto').markAsPristine()
      this.segnalazioneForm.get('oggetto').markAsUntouched()
    }
    // ricarica il form
    this.segnalazioneForm.updateValueAndValidity()
  }

  ripristinaObbligatorieta() {
    // ripristino l'obbligatorietà al campo con form control descrizioneFatto ma solo se si tratta di una segnalazione vocale e scritta
    if (this.segnalazioneForm.get('segnalazioneVocale').value === false) {

      this.segnalazioneForm
        .get('descrizioneFatto')
        .setValidators([Validators.required])
      this.segnalazioneForm.get('descrizioneFatto').updateValueAndValidity()

      // ripristino l'obbligatorietà all'oggetto
      this.segnalazioneForm.get('oggetto').setValidators([Validators.required])
      this.segnalazioneForm.get('oggetto').updateValueAndValidity()

      // ricarica il form
      this.segnalazioneForm.updateValueAndValidity()
    }
  }

  checkPreSave() {
    // se è una segnalazione vocale precompilo il campo oggetto con un testo fisso
    if (this.segnalazioneForm.get('segnalazioneVocale').value === true) {
      this.segnalazioneForm.get('oggetto').setValue(
        $localize`Segnalazione vocale`
      )
      this.segnalazioneForm.get('descrizioneFatto').setValue("")
      this.onAnonimaClick(false)
      this.eliminaDatiSegnalante()

      if (!this.segnalazione.hashAudio && !this.registrazioneVocaleDaSalvare) {
        // e' necessario inserire una registrazione vocale
        this.segnalazioneForm.controls['hashAudio'].setValidators(
          [Validators.required]
        )
        this.segnalazioneForm.controls['hashAudio'].updateValueAndValidity()
      } else {
        this.segnalazioneForm.controls['hashAudio'].clearValidators()
        this.segnalazioneForm.controls['hashAudio'].updateValueAndValidity()
      }
    } else {
      // se la segnalazione è anonima mi assicuro che i dati del segnalante siano cancellati
      if (this.segnalazioneForm.get('anonima').value === true) {
        this.eliminaDatiSegnalante()
      }

      // hashAudio non è più obbligatorio
      this.segnalazioneForm.controls['hashAudio'].clearValidators()
      this.segnalazioneForm.controls['hashAudio'].updateValueAndValidity()
      if (this.segnalazione.hashAudio || this.registrazioneVocaleDaSalvare) {
        this.segnalazioneForm.controls['descrizioneFatto'].clearValidators()
        this.segnalazioneForm.controls['descrizioneFatto'].updateValueAndValidity()
      } else {
        this.segnalazioneForm.controls['descrizioneFatto'].setValidators(
          [Validators.required]
        )
        this.segnalazioneForm.controls['descrizioneFatto'].updateValueAndValidity()
      }
    }
  }

  preSaveSegnalazione() {
    this.checkPreSave()

    // se il form non è valido non procedo
    if (!this.segnalazioneForm.valid) {

      openDialogGenerica(
        this.dialog,
        $localize`Impossibile procedere`,
        '',
        $localize`Inserire tutti i dati obbligatori contrassegnati con * prima di procedere con l'invio o il salvataggio della segnalazione.`,
        () => { },
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      )
      return
    }
    if (this.segnalazione.id === undefined) {
      this.apriDialogInformativa()
    } else {
      this.salvaSegnalazione()
    }
  }

  sottomettiSegnalazione() {
    // mi assicuro che le obbligatorietà legate all'anonimato siano rispettate
    this.modificaObbligatorietaSuAnonimatoSegnalazione(this.segnalazioneForm.get('anonima').value)

    this.checkPreSave()

    // se il form non è valido non procedo
    if (!this.segnalazioneForm.valid) {
      openDialogGenerica(
        this.dialog,
        $localize`Impossibile procedere`,
        '',
        $localize`Inserire tutti i dati obbligatori contrassegnati con * prima di procedere con l'invio della segnalazione.`,
        () => { },
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      )
      return
    }
    this.apriDialogInformativa(() => this.confermaInvioSegnalazione(), false)
  }

  confermaInvioSegnalazione() {
    const dialogRef = this.dialog.open(
      ConfermaInvioSegnalazioneDialogComponent,
      {
        width: '800px',
        data: { segnalante: this.segnalazione.segnalante },
      }
    )

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.inviaSegnalazione = true
        this.salvaSegnalazione()

      }
    })
  }

  apriDialogInformativa(
    azioneDopoLettura = () => this.salvaSegnalazione(),
    soloSalvataggio = true
  ) {
    const dialogRef = this.dialog.open(InformativaComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: { pinCode: this.pinCode, soloSalvataggio: soloSalvataggio },
    })
    dialogRef.afterClosed().subscribe((res) => {
      // console.log(res)
      if (res) {
        azioneDopoLettura()
      } else {
        const snackbarRef = this.snackBar.open(
          $localize`In assenza di un tuo consenso non è possibile procedere al salvataggio o invio della segnalazione`,
          'Chiudi'
        )
        snackbarRef.onAction().subscribe(() => {
          snackbarRef.dismiss()
        })
        this.rivalidaCampiSegnalante()
      }
    })
  }

  public registrazioneVocaleNonSalvata() {
    // marca il controllo sul form registrazione vocale come dirty
    this.loading = true
    this.segnalazioneForm.controls.registrazioneVocale.markAsDirty()
    this.registrazioneVocaleDaSalvare = true
    this.togliObbligatorieta()
  }

  public registrazioneVocalePresente() {
    this.togliObbligatorieta()
  }

  public registrazioneVocaleNonPresente() {
    this.segnalazione.hashAudio = null
    // ripulisce il form
    this.segnalazioneForm.controls['hashAudio'].setValue(null)
    this.registrazioneVocaleDaSalvare = false
    this.segnalazioneForm.controls.registrazioneVocale.markAsPristine()
    this.ripristinaObbligatorieta()
  }

  public stopLoading() {
    // marca il controllo sul form registrazione vocale come pristine
    // this.segnalazioneForm.controls.registrazioneVocale.markAsPristine();
    this.loading = false
  }

  public registrazioneVocaleSalvata(hashAudio) {
    this.registrazioneVocaleDaSalvare = false
    // marca il controllo sul form registrazione vocale come pristine
    if (hashAudio) {
      this.segnalazione.hashAudio = hashAudio
      this.segnalazioneForm.controls['hashAudio'].setValue(hashAudio)
    }
    this.segnalazioneForm.controls.registrazioneVocale.markAsPristine()
    this.loading = false
  }

  private createForm() {
    this.anonimaControl = new UntypedFormControl({
      value: this.segnalazione.anonima,
      disabled: this.readOnly(),
    })
    this.segnalazioneForm = new UntypedFormGroup({
      id: new UntypedFormControl({
        value: this.segnalazione.id,
        disabled: this.readOnly(),
      }),
      registrazioneVocale: new UntypedFormControl({
        value: this.segnalazione.audio,
        disabled: this.readOnly(),
      }),
      segnalazioneVocale: new UntypedFormControl({
        value: this.segnalazione.segnalazioneVocale,
        disabled: this.readOnly(),
      }),
      oggetto: new UntypedFormControl(
        {
          value: this.segnalazione.oggetto,
          disabled: this.readOnly(),
        },
        Validators.compose([Validators.required])
      ),
      descrizione: new UntypedFormControl(
        {
          value: this.segnalazione.descrizione,
          disabled: this.readOnly(),
        },
        Validators.compose([
          this.mostraTipiNonConformita
            ? Validators.required
            : Validators.nullValidator,
        ])
      ),
      descrizioneFatto: new UntypedFormControl(
        {
          value: this.segnalazione.descrizioneFatto,
          disabled: this.readOnly(),
        },
        Validators.compose([Validators.required])
      ),
      anonima: new UntypedFormControl({
        value: this.segnalazione.anonima,
        disabled: this.readOnly(),
      }),
      dataInserimento: new UntypedFormControl({
        value: this.segnalazione.dataInserimento,
        disabled: true,
      }),
      destinatari: new UntypedFormControl({
        value: this.segnalazione.dirittiSegnalazioneOdv,
        disabled:
          this.segnalazione.id !== null && this.segnalazione.id !== undefined
            ? true
            : false,
      }),
      societa: new UntypedFormControl(
        {
          value: this.segnalazione.aziendaInteressata,
          disabled: this.readOnly(),
        },
        Validators.compose([Validators.required])
      ),
      stato: new UntypedFormControl({
        value: this.segnalazione.stato,
        disabled: this.readOnly(),
      }),
      nomeSegnalante: new UntypedFormControl(
        {
          value: this.segnalazione.segnalante?.nome,
          disabled: this.readOnly(),
        },
        Validators.compose(
          this.segnalazione.anonima ? [] : [Validators.required]
        )
      ),
      cognomeSegnalante: new UntypedFormControl(
        {
          value: this.segnalazione.segnalante?.cognome,
          disabled: this.readOnly(),
        },
        Validators.compose(
          this.segnalazione.anonima ? [] : [Validators.required]
        )
      ),
      genereSegnalante: new UntypedFormControl({
        value: this.segnalazione.segnalante?.genere,
        disabled: this.readOnly(),
      }),
      codiceFiscaleSegnalante: new UntypedFormControl(
        {
          value: this.segnalazione.segnalante?.codiceFiscale,
          disabled: this.readOnly(),
        },
        Validators.compose(
          this.segnalazione.anonima ? [] : []
        )
      ),
      dataDiNascitaSegnalante: new UntypedFormControl({
        value: this.segnalazione.segnalante?.dataDiNascita,
        disabled: this.readOnly(),
      }),
      luogoDiNascitaSegnalante: new UntypedFormControl({
        value: this.segnalazione.segnalante?.luogoDiNascita,
        disabled: this.readOnly(),
      }),
      indirizzoDiResidenzaSegnalante: new UntypedFormControl(
        {
          value: this.segnalazione.segnalante?.indirizzoDiResidenza,
          disabled: this.readOnly(),
        },
        Validators.compose(
          this.segnalazione.anonima ? [] : [Validators.required]
        )
      ),
      capSegnalante: new UntypedFormControl(
        {
          value: this.segnalazione.segnalante?.cap,
          disabled: this.readOnly(),
        },
        Validators.compose(
          this.segnalazione.anonima
            ? []
            : [
              Validators.required,
              Validators.pattern(/^[a-zA-Z0-9-]{3,9}$/),
            ]
        )
      ),
      cittaSegnalante: new UntypedFormControl(
        {
          value: this.segnalazione.segnalante?.citta,
          disabled: this.readOnly() && !this.datiSegnalanteInModifica,
        },
        Validators.compose(
          this.segnalazione.anonima
            ? []
            : [Validators.required, CustomValidators.validaCitta]
        )
      ),
      regioneSegnalante: new UntypedFormControl({
        value: this.segnalazione.segnalante?.regione,
        disabled: true,
      }),
      statoSegnalante: new UntypedFormControl({
        value: this.segnalazione.segnalante?.stato,
        disabled: true,
      }),
      telefonoSegnalante: new UntypedFormControl(
        {
          value: this.segnalazione.segnalante?.telefono,
          disabled: this.readOnly(),
        },
        Validators.compose(
          this.segnalazione.anonima
            ? []
            : [
              Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{7,15}$/),
            ]
        )
      ),
      emailSegnalante: new UntypedFormControl(
        {
          value: this.segnalazione.segnalante?.email,
          disabled: this.readOnly(),
        },
        Validators.compose(
          this.segnalazione.anonima
            ? []
            : [
              Validators.required,
              CustomValidators.validaEmail,
              CustomValidators.validateEmailPersonale(
                this.sessionData.configurazione?.dominiAziendali
              ),
            ]
        )
      ),
      rapportoConLaSocietaSegnalante: new UntypedFormControl(
        {
          value: this.segnalazione.segnalante?.rapportoConLaSocieta,
          disabled: this.readOnly(),
        },
        Validators.compose(
          this.segnalazione.anonima ? [] : [Validators.required]
        )
      ),
      incarico: new UntypedFormControl(
        {
          value: this.segnalazione.segnalante?.incarico,
          disabled: this.readOnly(),
        },
        Validators.compose(this.segnalazione.anonima ? [] : [])
      ),
      orgUnitAndSite: new UntypedFormControl({
        value: this.segnalazione.segnalante?.orgUnitAndSite,
        disabled: this.readOnly(),
      }),
      qualificaSegnalante: new UntypedFormControl({
        value: this.segnalazione.segnalante?.qualifica,
        disabled: this.readOnly(),
      }),
      incaricoT0: new UntypedFormControl({
        value: this.segnalazione.segnalante?.incaricoT0,
        disabled: this.readOnly(),
      }),
      orgUnitAndSiteT0: new UntypedFormControl({
        value: this.segnalazione.segnalante?.orgUnitAndSiteT0,
        disabled: this.readOnly(),
      }),
      qualificaSegnalanteT0: new UntypedFormControl({
        value: this.segnalazione.segnalante?.qualificaT0,
        disabled: this.readOnly(),
      }),
      ente: new UntypedFormControl({
        value: this.segnalazione.ente,
        disabled: this.readOnly(),
      }),
      periodo: new UntypedFormControl({
        value: this.segnalazione.periodo,
        disabled: this.readOnly(),
      }),
      data: new UntypedFormControl({
        value: this.segnalazione.data,
        disabled: this.readOnly(),
      }),
      ora: new UntypedFormControl({
        value: new OraPipe().transform(this.segnalazione.data)?.split(':')[0],
        disabled: this.readOnly(),
      }),
      minuti: new UntypedFormControl({
        value: new OraPipe().transform(this.segnalazione.data)?.split(':')[1],
        disabled: this.readOnly(),
      }),
      luogo: new UntypedFormControl({
        value: this.segnalazione.luogo,
        disabled: this.readOnly(),
      }),
      altriSoggetti: new UntypedFormControl({
        value: this.segnalazione.altriSoggetti,
        disabled: this.readOnly(),
      }),
      altreImprese: new UntypedFormControl({
        value: this.segnalazione.altreImprese,
        disabled: this.readOnly(),
      }),
      motiviCondottaIllecita: new UntypedFormControl({
        value: this.segnalazione.motiviCondottaIllecita,
        disabled: this.readOnly(),
      }),
      altroCondottaIllecita: new UntypedFormControl({
        value: this.segnalazione.altroCondottaIllecita,
        disabled: this.readOnly(),
      }),
      mod: new UntypedFormControl({
        value: this.segnalazione.mod,
        disabled: this.readOnly(),
      }),
      others: new UntypedFormControl({
        value: this.segnalazione.others,
        disabled: this.readOnly(),
      }),
      area: new UntypedFormControl({
        value: this.segnalazione.area,
        disabled: this.readOnly(),
      }),
      altro: new UntypedFormControl({
        value: this.segnalazione.altro,
        disabled: this.readOnly(),
      }),
      settore: new UntypedFormControl({
        value: this.segnalazione.settore,
        disabled: this.readOnly(),
      }),
      altroSettore: new UntypedFormControl({
        value: this.segnalazione.altroSettore,
        disabled: this.readOnly(),
      }),
      hashAudio: new UntypedFormControl({
        value: this.segnalazione.hashAudio,
        disabled: this.readOnly(),
      })
    })

    if (this.sessionData.configurazione?.abilitaGestioneConflittoInteressi) {
      this.segnalazioneForm.addControl(
        'segnalazioneRiferibileAlGestorePredefinito',
        new UntypedFormControl({
          value: this.segnalazione.segnalazioneRiferibileAlGestorePredefinito,
          disabled: this.readOnly(),
        })
      )
    }

    if (this.segnalazione && this.segnalazione.segnalati) {
      for (
        let indice = 0;
        indice < this.segnalazione.segnalati.length;
        indice++
      ) {
        const segnalato = this.segnalazione.segnalati[indice]
        if (segnalato !== null) {
          this.segnalazioneForm.addControl(
            'nome_segnalato_' + indice,
            new UntypedFormControl({
              value:
                this.segnalazione.segnalati && this.segnalazione.segnalati[0]
                  ? segnalato.nome
                  : '',
              disabled: this.readOnly(),
            })
          )
          this.segnalazioneForm.addControl(
            'cognome_segnalato_' + indice,
            new UntypedFormControl({
              value:
                this.segnalazione.segnalati && this.segnalazione.segnalati[0]
                  ? segnalato.cognome
                  : '',
              disabled: this.readOnly(),
            })
          )

          this.segnalazioneForm.addControl(
            'qualifica_segnalato_' + indice,
            new UntypedFormControl({
              value:
                this.segnalazione.segnalati && this.segnalazione.segnalati[0]
                  ? segnalato.qualifica
                  : '',
              disabled: this.readOnly(),
            })
          )
        }
      }
    }

    // if (true) {
    this.segnalazioneForm.controls.cittaSegnalante.valueChanges
      .pipe(debounceTime(300))
      .subscribe((data) => {
        if (data && data['nome'] === undefined) {
          if (typeof data === 'string' || data instanceof String) {
            this.filteredComuni = comuni.filter((comune) =>
              comune.nome.toLowerCase().startsWith(data?.toLowerCase())
            )
          }
        } else {
          this.segnalazioneForm.controls.regioneSegnalante.setValue(
            data ? data['regione'] : null
          )
          this.segnalazioneForm.controls.statoSegnalante.setValue('Italia')
        }
      })
    this.sottoscrizioneComboCambioDestinatari =
      this.segnalazioneForm.controls.destinatari.valueChanges
        .pipe(debounceTime(400))
        .subscribe((data) => {
          // tratta data solo se è una stringa
          if (data && typeof data === 'string' && data.length > 0) {
            this.segnalazioniService
              .recuperaGruppiGestoriByName(data.toString())
              .then((esito: Esito) => {
                if (esito.esito === ESITO_OK) {
                  this.destinatariSegnalazioneFiltrati = JSON.parse(
                    esito.payload
                  )
                  //  Quindi elimina i gruppi già presenti
                  this.destinatariSegnalazioneFiltrati =
                    this.destinatariSegnalazioneFiltrati.filter(
                      (destinatario) => {
                        let res = true
                        if (
                          this.segnalazione &&
                          this.segnalazione.dirittiSegnalazioneOdv
                        ) {
                          res =
                            this.segnalazione.dirittiSegnalazioneOdv.find(
                              (odvtmp) =>
                                odvtmp.odvDestinatario.nomeOdv ===
                                destinatario.nomeOdv
                            ) === undefined
                        }
                        return res
                      }
                    )
                } else {
                  this.snackBar.open(
                    $localize`Recupero elenco destinatari Fallito. Errore: ` +
                    esito.payload,
                    null,
                    {
                      duration: 4000,
                    }
                  )
                }
              })
          } else {
            this.recuperaTuttiIDestinatari()
          }
        })
    // }



    this.ascoltaCambiamenti()

    if (this.readOnly()) {
      this.segnalazioneForm.disable()
      //this.validateCodiceFiscale(this.segnalazione?.segnalante?.codiceFiscale)
    }
  }

  private ascoltaCambiamenti() {
    for (const key in this.segnalazioneForm.controls) {
      // Aggiungi questa riga per verificare se l'oggetto possiede la proprietà
      if (
        Object.prototype.hasOwnProperty.call(
          this.segnalazioneForm.controls,
          key
        )
      ) {
        const entita = key.split('_')
        this.segnalazioneForm
          .get(key)
          .valueChanges.subscribe((value) =>
            this.aggiornaCampo(entita[0], value, entita[1], +entita[2])
          )
      }
    }
  }

  private aggiornaCampo(
    key: string,
    value: any,
    entita?: string,
    indice?: number
  ) {
    // console.log('Aggiornamento necessario sulla chiave', key, 'valore', value, 'entita', entita, 'indice', indice);
    if (!Number.isNaN(indice)) {
      switch (entita) {
        case 'segnalato':
          switch (key) {
            case 'nome':
              this.segnalazione.segnalati[indice].nome = value
              break
            case 'cognome':
              this.segnalazione.segnalati[indice].cognome = value
              break
            case 'qualifica':
              this.segnalazione.segnalati[indice].qualifica = value
              break
          }
          break
      }
    } else {
      switch (key) {
        case 'oggetto':
          this.segnalazione.oggetto = value
          break
        case 'segnalazioneVocale':
          this.segnalazione.segnalazioneVocale = value
          break
        case 'descrizione':
          this.segnalazione.descrizione = value
          break
        case 'descrizioneFatto':
          this.segnalazione.descrizioneFatto = value
          break
        case 'anonima':
          this.segnalazione.anonima = value
          break
        case 'societa':
          this.segnalazione.aziendaInteressata = value
          break
        case 'destinatari':
          // console.log(key, 'gestita a parte')
          break
        case 'nomeSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.nome = value
          }
          break
        case 'cognomeSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.cognome = value
          }
          break
        case 'genereSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.genere = value
          }
          break
        case 'codiceFiscaleSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.codiceFiscale = value
          }
          break
        case 'dataDiNascitaSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.dataDiNascita = value
          }
          break
        case 'luogoDiNascitaSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.luogoDiNascita = value
          }
          break
        case 'indirizzoDiResidenzaSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.indirizzoDiResidenza = value
          }
          break
        case 'capSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.cap = value
          }
          break
        case 'cittaSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.citta = value ? value : null
          }
          break
        case 'regioneSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.regione = value
          }
          break
        case 'statoSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.stato = value
          }
          break
        case 'telefonoSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.telefono = value
          }
          break
        case 'emailSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.email = value
          }
          break
        case 'rapportoConLaSocietaSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.rapportoConLaSocieta = value
          }
          break
        case 'incarico':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.incarico = value
          }
          break
        case 'orgUnitAndSite':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.orgUnitAndSite = value
          }
          break
        case 'qualificaSegnalante':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.qualifica = value
          }
          break
        case 'incaricoT0':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.incaricoT0 = value
          }
          break
        case 'orgUnitAndSiteT0':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.orgUnitAndSiteT0 = value
          }
          break
        case 'qualificaSegnalanteT0':
          if (this.segnalazione.segnalante) {
            this.segnalazione.segnalante.qualificaT0 = value
          }
          break
        case 'ente':
          this.segnalazione.ente = value
          break
        case 'periodo':
          this.segnalazione.periodo = value
          break
        case 'data':
          // console.log(key, 'gestita a parte')
          break
        case 'ora':
          // console.log(key, 'gestita a parte')
          break
        case 'minuti':
          // console.log(key, 'gestita a parte')
          break
        case 'luogo':
          this.segnalazione.luogo = value
          break
        case 'altriSoggetti':
          this.segnalazione.altriSoggetti = value
          break
        case 'altreImprese':
          this.segnalazione.altreImprese = value
          break
        case 'motiviCondottaIllecita':
          this.segnalazione.motiviCondottaIllecita = value
          break
        case 'altroCondottaIllecita':
          this.segnalazione.altroCondottaIllecita = value
          break
        case 'mod':
          this.segnalazione.mod = value
          break
        case 'others':
          this.segnalazione.others = value
          break
        case 'area':
          this.segnalazione.area = value
          break
        case 'altro':
          this.segnalazione.altro = value
          break
        case 'settore':
          this.segnalazione.settore = value
          break
        case 'altroSettore':
          this.segnalazione.altroSettore = value
          break
        case 'segnalazioneRiferibileAlGestorePredefinito':
          this.segnalazione.segnalazioneRiferibileAlGestorePredefinito = value
          break

        default:
        // console.warn(key, 'non trovata')
      }
    }
  }

  displayFn(comune: any) {
    if (comune) {
      return comune['nome']
    }
  }

  openDialogRispostaCustode(diritto: DirittiSegnalazione): void {
    const dialogRef = this.dialog.open(RispostaCustodeDialogComponent, {
      width: '800px',
      height: '600px',
      data: {
        testo:
          this.motivazioneRispostaRichiestaDiSbloccoSegnalante(diritto)?.trim(),
        editable: false,
        showToolbar: false,
      },
      disableClose: false,
      hasBackdrop: true,
    })
    dialogRef.afterClosed().subscribe((result) => { })
  }

  gruppiAiQualiPossoInoltrare(azienda: Azienda) {
    return azienda.odvAzienda.filter(
      (odvAzienda) =>
        this.segnalazione.dirittiSegnalazioneOdv?.find(
          (dirsegna) => dirsegna.odvDestinatario.id === odvAzienda.odv?.id
        ) === undefined
    )
  }

  omissisInModifica(dirittiSegnalazione: DirittiSegnalazione) {
    return (
      dirittiSegnalazione.odvDestinatario.nomeOdv ===
      this.nomeOdvOmissisInModifica
    )
  }

  condivisioneSelezionata(dirittiSegnalazione: DirittiSegnalazione) {
    if (
      this.nomeOdvOmissisInModifica ===
      dirittiSegnalazione.odvDestinatario.nomeOdv
    ) {
      //  disabilita pannello di modifica omissis
      this.disattivaModalitaOmissis()
      return
    }
    this.nomeOdvOmissisInModifica =
      dirittiSegnalazione.odvDestinatario.nomeOdv?.toString()

    this.segnalazioniService
      .recuperaOmissisByDirittoSegnalazione(dirittiSegnalazione.id)
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.modalitaOmissis = true
          this.omissis = JSON.parse(esito.payload)
          dirittiSegnalazione.omissis = this.omissis
          this.dirittiSegnalazione = dirittiSegnalazione
          this.initSegments()
          this.nomeOdvOmissis =
            dirittiSegnalazione.odvDestinatario.nomeOdv.toString()
        } else {
          this.snackBar.open(
            $localize`Recupero segnalazione Fallito. Errore: ` + esito.payload,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((errore) => {
        this.snackBar.open(
          $localize`Recupero segnalazione Fallito. Errore: ` + errore,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  private disattivaModalitaOmissis() {
    this.nomeOdvOmissisInModifica = undefined
    this.modalitaOmissis = false
    this.dirittiSegnalazione = undefined
    this.omissis = []
  }

  getIdSegnalazione() {
    return getIdSegnalazione(this.segnalazione, this.utente)
  }

  onAnonimaClick(isAnonima: boolean) {
    this.modificaObbligatorietaSuAnonimatoSegnalazione(!isAnonima)
  }

  private modificaObbligatorietaSuAnonimatoSegnalazione(anonima: boolean) {
    this.campiSegnalante.forEach((campo) => {
      if (!anonima && !this.segnalazioneVocale) {
        if (
          campo.campo === 'telefonoSegnalante' ||
          campo.campo === 'incarico' ||
          campo.campo === 'codiceFiscaleSegnalante'
        ) {
          // non debbono essere obbligatori

          this.segnalazioneForm.controls[campo.campo].setValidators([
            ...campo.customValidators,
          ])
        } else {
          this.segnalazioneForm.controls[campo.campo].setValidators([
            ...campo.customValidators,
            Validators.required,
          ])
        }
        // this.segnalazioneForm.controls[campo.campo].setValue(null)
      } else {
        this.segnalazioneForm.controls[campo.campo].setValidators(
          campo.customValidators
        )
      }
      this.segnalazioneForm.controls[campo.campo].updateValueAndValidity()
      if (!anonima) {
        setTimeout(() => {
          if (
            campo.campo !== 'telefonoSegnalante' &&
            campo.campo !== 'incarico' &&
            campo.campo !== 'codiceFiscaleSegnalante'
          ) {
            this.segnalazioneForm.controls[campo.campo].setErrors({

              required: true,
            })
          }
          this.segnalazioneForm.controls[campo.campo].markAsTouched()
        }, 0)
      }
    })
    // se è presente un vocale allora descrizioneFatto non è obbligatoria
    if (this.segnalazione.hashAudio || this.registrazioneVocaleDaSalvare) {
      this.segnalazioneForm.controls['descrizioneFatto'].clearValidators()
      this.segnalazioneForm.controls['descrizioneFatto'].updateValueAndValidity()
    } else {
      this.segnalazioneForm.controls['descrizioneFatto'].setValidators(
        [Validators.required]
      )
      this.segnalazioneForm.controls['descrizioneFatto'].updateValueAndValidity()
    }
  }

  eliminaDatiSegnalante() {
    this.campiSegnalante.forEach((campo) => {
      this.segnalazioneForm.controls[campo.campo].setValue(null)
    })
  }

  onSegnalazioneVocaleClick() {
    this.segnalazioneForm.controls['segnalazioneVocale'].setValue(!this.segnalazioneForm.controls['segnalazioneVocale'].value)
    const isSegnalazioneVocale = this.segnalazioneForm.controls['segnalazioneVocale'].value

    // se è una segnalazione vocale precompilo il campo oggetto con un testo fisso
    if (this.segnalazioneForm.get('segnalazioneVocale').value === true) {
      this.togliObbligatorieta()
      this.onAnonimaClick(false)
    }

    // se true allora non sono più obbligatori i campi oggetto e descrizione
    if (!isSegnalazioneVocale) {
      // i dati identificativi sono obbligatori
      this.onAnonimaClick(true)
      this.segnalazioneForm.controls['oggetto'].setValidators(
        [Validators.required]
      )
      this.segnalazioneForm.controls['oggetto'].updateValueAndValidity()
      this.segnalazioneForm.controls['descrizione'].setValidators(
        [Validators.required]
      )
      this.segnalazioneForm.controls['descrizione'].updateValueAndValidity()
      // se è presente un vocale allora descrizioneFatto non è obbligatoria
      if (this.segnalazione.hashAudio || this.registrazioneVocaleDaSalvare) {
        this.segnalazioneForm.controls['descrizioneFatto'].clearValidators()
        this.segnalazioneForm.controls['descrizioneFatto'].updateValueAndValidity()
      } else {
        this.segnalazioneForm.controls['descrizioneFatto'].setValidators(
          [Validators.required]
        )
        this.segnalazioneForm.controls['descrizioneFatto'].updateValueAndValidity()
      }

    } else {
      // i dati identificativi non sono obbligatori
      this.onAnonimaClick(false)
      this.segnalazioneForm.controls['oggetto'].setValidators(null)
      this.segnalazioneForm.controls['oggetto'].updateValueAndValidity()
      this.segnalazioneForm.controls['descrizione'].setValidators(null)
      this.segnalazioneForm.controls['descrizione'].updateValueAndValidity()
      this.segnalazioneForm.controls['descrizioneFatto'].clearValidators()
      this.segnalazioneForm.controls['descrizioneFatto'].updateValueAndValidity()
    }
    this.segnalazioneForm.controls['oggetto'].markAsTouched()
    this.segnalazioneForm.controls['descrizione'].markAsTouched()
    this.segnalazioneForm.controls['segnalazioneVocale'].markAsTouched()
    // cambia il valore al campo segnalazioneVocale
    this.segnalazione.segnalazioneVocale = this.segnalazioneForm.controls['segnalazioneVocale'].value
    this.recuperaTestoRichiestaConsensoAudioLinguaUtente()
    // Ora il form deve essere rivalidato
    this.segnalazioneForm.markAsTouched()
    this.segnalazioneForm.updateValueAndValidity()

  }

  get segnalazioneVocaleConsentita() {
    return this.sessionData.configurazione?.segnalazioneVocaleAbilitata;
  }

  get etichettaTipoSegnalazione() {
    if (this.segnalazioneForm.controls['segnalazioneVocale']) {
      if (this.segnalazioneForm.controls['segnalazioneVocale'].value) {
        return $localize`Desidero segnalare in forma scritta e vocale`
      } else {
        return $localize`Desidero segnalare in forma solo vocale`
      }
    } else {
      return $localize`Desidero segnalare in forma scritta e vocale`
    }
  }

  get segnalazioneVocale() {
    if (this.segnalazioneForm.controls['segnalazioneVocale']) {
      return this.segnalazioneForm.controls['segnalazioneVocale'].value
    } else {
      return false
    }
  }

  get dominiPermessi() {
    return this.sessionData.configurazione?.dominiAziendali
  }

  apriDialogManualeUtente() {
    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: this.sessionData.configurazione?.manualeUtenteDocumento,
        testo: this.sessionData.configurazione?.manualeUtente,
        titolo: $localize`Manuale Utente`,
        tipoDocumento: TipoDocumento.TIPO_INFORMATIVA,
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo: SottoTipoContenuto.MANUALE_UTENTE,
        lingua: this.codiceLingua,
        bypassAuth: true,
        canDownload: false,
        canPrint: false,
      },
    })
  }



  get codiceLingua() {
    // ricava il codice della lingua corrente dalla url
    return window.location.href.split('/')[3]
  }

  apriPannelloSelezioneDestinatari() {
    console.log('apriPannelloSelezioneDestinatari')
  }

  get lavorazioniVisibili() {
    const lavorazioniVisibili =
      isSegnalante(this.segnalazione, this.authService.getUser()) && !this.odv
        ? this.segnalazione.lavorazioni?.filter(
          (lav) =>
            lav.visibileAlSegnalante &&
            this.gestore(lav) !== $localize`Nessuno`
        )
        : this.segnalazione.lavorazioni
    return lavorazioniVisibili
  }

  get visibilitaPannelloLavorazioni() {
    return !(!this.lavorazioniVisibili || this.lavorazioniVisibili.length === 0)
  }

  get labelQuanteLavorazioni() {
    const lavorazioniVisibili = this.lavorazioniVisibili

    const label =
      (lavorazioniVisibili.length === 1
        ? $localize`C'è `
        : $localize`Ci sono `) +
      lavorazioniVisibili.length +
      (lavorazioniVisibili.length
        ? $localize` lavorazione`
        : $localize` lavorazioni`)
    return label
  }

  onKeyDownEnter(event: KeyboardEvent, trigger: MatAutocompleteTrigger): void {
    event.preventDefault()
    // Estrae la prima opzione nell'autocomplete
    const selectedOption = this.autoCitta.options.first

    // Se c'è un'opzione attiva nell'autocomplete, selezionala
    if (selectedOption) {
      // valorizza il campo del form  con il valore dell'opzione selezionata
      this.segnalazioneForm.controls.cittaSegnalante.setValue(
        selectedOption.value
      )

      // Chiudi l'autocomplete dopo aver selezionato un'opzione
      trigger.closePanel()
    } else {
      this.segnalazioneForm.controls.cittaSegnalante.setValue(null)
    }
    // Aggiungi qui il codice per gestire l'azione di ricerca della città, se necessario
  }

  validateCodiceFiscale(codiceFiscale: string) {
    // Inserisci qui la logica di validazione del codice fiscale
    // const isValid = validaCodiceFiscale(codiceFiscale)
    const isValid = true

    this.isCodiceFiscaleValid = isValid
  }

  preventInput(event: KeyboardEvent) {
    event.preventDefault()
  }

  onOraInputChange() {
    const { ora, minuti } = this.segnalazioneForm.value
    const oraInt = parseInt(ora, 10)
    const minutiInt = parseInt(minuti, 10)
    if (!isNaN(oraInt) && !isNaN(minutiInt)) {
      if (typeof this.segnalazione.data === 'string') {
        this.segnalazione.data = new Date(this.segnalazione.data)
      }
      // controlla se data è undefined
      if (!this.segnalazione.data) {
        this.segnalazione.data = new Date()
      }
      this.segnalazione.data.setTime(
        new Date(
          this.segnalazione.data.getFullYear(),
          this.segnalazione.data.getMonth(),
          this.segnalazione.data.getDate(),
          oraInt,
          minutiInt
        ).getTime()
      )
      // aggiorna il form
      this.segnalazioneForm.patchValue({
        data: this.segnalazione.data,
      })
    }
  }

  formatTime(controlName: string): void {
    const value = this.segnalazioneForm.get(controlName).value
    if (value !== null && value !== '') {
      const formattedValue = ('0' + value).slice(-2)
      this.segnalazioneForm.get(controlName).setValue(formattedValue)
    }
    this.onOraInputChange()
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault()
      // Fai qualcosa quando viene premuto il tasto Invio, se necessario
    }
  }

  reloadPage() {
    if (this.reloadAttempts < this.maxReloadAttempts) {
      if (this.authService.isLoggedIn() && this.segnalazione) {
        // SessionData.utente = data;
        // console.log('Navigo nella home.....');
        if (this.segnalazione.id) {
          if (!this.segnalazioneForm) {
            this.reloadSegnalazione()
          }
        } else {
          const segnalazione = this.segnalazione
          const segna = new Segnalante()

          segnalazione.segnalante = segna
          segnalazione.segnalante.utente = this.authService.getUser()
          segnalazione.segnalante.utente.lingua = this.codiceLingua
          segnalazione.anonima = false
          segnalazione.stato = '0'

          segnalazione.presaInCarico = false
          segnalazione.documenti = new Array<Documento>()

          this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
        }
        // console.log('avvio creazione nuova segnalazione:');
      } else {
        // console.log('Navigo nella home.....');
        this.router.navigate(['/landing-page'])
      }
    } else {
      // Mostra un messaggio di errore o gestisci l'errore in un altro modo
      console.error('Errore nel recupero della segnalazione')
    }
  }

  reloadSegnalazione() {
    if (this.segnalazione.id) {
      this.segnalazioniService
        .recuperaSegnalazione(+this.segnalazione.id)
        .subscribe(
          (esito: Esito) => {
            if (esito.esito === ESITO_OK) {
              if (esito.payload) {
                this.sessionData.nuovaSegnalazioneSelezionata(JSON.parse(
                  esito.payload)
                )
              }


              // se il form non è presente lo crea

              this.createForm()

              this.segnalazioneForm.markAsPristine()
              this.documentiService
                .getDocumentiAssociatiASegnalazione(this.segnalazione)

                .then((esito2: Esito) => {
                  if (esito2.esito === ESITO_OK) {
                    const documenti = esito2.payload ? JSON.parse(esito2.payload) : []
                    this.sessionData.getSegnalazioneSelezionata().documenti =
                      documenti
                  } else {
                    //
                    console.log(
                      'Errore nel recupero degli allegati della segnalazione'
                    )
                  }
                })
            } else if (esito.esito === ESITO_IN_PROGRESS) {
              // Gestisci lo stato in progress come preferisci
              // Ad esempio, potresti mostrare un messaggio di attesa all'utente
              console.log(esito.descrizioneEsito)
            }
          },
          (err) => {
            console.error(err)
          }
        )
    }
  }

  patchSegnalazioneForm() {
    this.segnalazioneForm.patchValue(this.segnalazione)
    const patchObject = {}
    for (
      let indice = 0;
      indice < this.segnalazione.segnalati.length;
      indice++
    ) {
      const segnalato = this.segnalazione.segnalati[indice]
      if (segnalato !== null) {
        patchObject['nome_segnalato_' + indice] = segnalato.nome
        patchObject['cognome_segnalato_' + indice] = segnalato.cognome
        patchObject['qualifica_segnalato_' + indice] = segnalato.qualifica
      }
    }
    this.segnalazioneForm.patchValue(patchObject)
  }

  getTranslatedText(key: string): string {
    switch (key) {
      case 'meno-di-1-settimana-fa':
        return $localize`Meno di 1 settimana fa`
      case 'meno-di-1-mese-fa':
        return $localize`Meno di 1 mese fa`
      case 'da-1-a-3-mesi-fa':
        return $localize`Da 1 a 3 mesi fa`
      case 'da-3-a-6-mesi-fa':
        return $localize`Da 3 a 6 mesi fa`
      case 'da-6-mesi-ad-1-anno-fa':
        return $localize`Da 6 mesi ad 1 anno fa`
      case 'oltre-1-anno-fa':
        return $localize`Oltre 1 anno fa`
      case 'omissis':
        return $localize`OMISSIS`
      case '*omissis*':
        return $localize`*OMISSIS*`
      case 'dipendente':
        return $localize`Dipendente`
      case 'ex-dipendente':
        return $localize`Ex Dipendente`
      case 'somministrato':
        return $localize`Somministrato`
      case 'collaboratore':
        return $localize`Collaboratore`
      case 'fornitore':
        return $localize`Fornitore`
      case 'partner-commerciale':
        return $localize`Partner Commerciale`
      case 'consulente':
        return $localize`Consulente`
      case 'cliente':
        return $localize`Cliente`
      case 'altro':
        return $localize`Altro`
      default:
        return key
    }
  }

  get audioCifrato() {
    // verifica tutti i diritti di segnalazione per l'utente corrente
    // e se trova un diritto con audio cifrato restituisce true
    let res = false
    // se è il segnalante restituisco false
    if (this.utente.id === this.segnalazione.segnalante?.utente?.id) {
      return false
    }
    if (this.segnalazione.dirittiSegnalazioneOdv) {
      res = true
      this.segnalazione.dirittiSegnalazioneOdv.forEach((dirsegna) => {
        // controlla che il diritto segnalazione sia applicabile all'utente corrente
        if (
          isMembroDiGruppo(this.utente, dirsegna.odvDestinatario) &&
          !dirsegna.audioCifrato
        ) {
          res = false
          // esci dal foreach
          return
        }
      })
    }
    return res
  }

  autoescludiDialog() {
    openDialogGenerica(
      this.dialog,
      $localize`Disabilita la visualizzazione della Segnalazione per la mia utenza`,
      '',
      // eslint-disable-next-line max-len
      $localize`Confermi di voler procedere all'autoesclusione dall'accesso alla segnalazione? Questa operazione potrà essere annullata solo da un altro gestore.`,
      () => this.doAutoescludi(),
      () => { }
    )
  }

  handleClickBottoneProcessoOscuramento() {
    // se esiste un processo oscuramento in corso entra in modalità gestione oscuramento
    if (this.processoOscuramento) {
      this.modalitaOscuramento = !this.modalitaOscuramento
      this.modalitaOmissis = this.modalitaOscuramento
      this.omissis = this.processoOscuramento.omissis
      this.initSegments()
      this.reloadSegnalazione()
    } else {
      // altrimenti avvia il processo di oscuramento
      this.avviaProcessoOscuramentoDialog()
    }
  }

  avviaProcessoOscuramentoText() {
    // se esiste un processo oscuramento in corso entra in modalità gestione oscuramento
    if (this.modalitaOscuramento) {
      return $localize`Esci dalla modalità di gestione oscuramento`
    } else {
      // altrimenti avvia il processo di oscuramento
      return $localize`Entra in modalità di gestione oscuramento`
    }
  }

  avviaProcessoOscuramentoDialog() {
    openDialogGenerica(
      this.dialog,
      $localize`Avvia processo di oscuramento della Segnalazione`,
      '',
      // eslint-disable-next-line max-len
      $localize`Confermi di voler procedere all'avvio del processo di oscuramento della segnalazione? Questa operazione permetterà di oscurare per tutti le informazioni manifestamente non pertinenti alla segnalazione.`,
      () => this.doAvviaProcessoOscuramento(),
      () => { }
    )
  }

  doAvviaProcessoOscuramento() {
    const dirittiSegnalazioneRicevutiDaSegnalante =
      dirittiSegnalazioneUtenteRicevutiDaSegnalante(
        this.segnalazione,
        this.authService.getUser()
      )
        // seleziono quelli non annullati e non in conflitto
        .filter(
          (dirsegna) =>
            !dirsegna.odvDestinatarioInConflittoDiInteressi &&
            !dirsegna.cancellato &&
            dirsegna.sceltoDaSegnalante
        )
    // se non ne trovo, non posso avviare il processo di oscuramento
    if (dirittiSegnalazioneRicevutiDaSegnalante.length === 0) {
      this.snackBar.open(
        // eslint-disable-next-line max-len
        $localize`Non è possibile avviare il processo di oscuramento della segnalazione perché non sono presenti diritti di segnalazione non annullati e non in conflitto di interessi`,
        null,
        {
          duration: 8000,
        }
      )
      return
    }

    if (
      dirittiSegnalazioneRicevutiDaSegnalante.length === 1 &&
      !this.dirittoSelezionato
    ) {
      this.onGruppoSelezionato(
        true,
        dirittiSegnalazioneRicevutiDaSegnalante[0].odvDestinatario
      )
      this.doAvviaProcessoOscuramento()
      return
    }

    const odvs = dirittiSegnalazioneRicevutiDaSegnalante.map(
      (dirsegna) => dirsegna.odvDestinatario
    )

    // se ho più di un diritto di segnalazione, debbo chiedere quale gruppo utilizzare
    if (odvs.length > 1 && !this.dirittoSelezionato) {
      const dialogConfig = new MatDialogConfig()
      dialogConfig.disableClose = true
      dialogConfig.autoFocus = true
      dialogConfig.data = {
        titolo: $localize`Seleziona il gruppo gestore da impersonare`,
        etichetta: $localize`Gruppo gestore`,
        odvs: odvs,
      }

      const dialogRef = this.dialog.open(
        DialogSelezioneGruppoComponent,
        dialogConfig
      )

      dialogRef.afterClosed().subscribe(
        (odv) => {
          if (odv) {
            this.onGruppoSelezionato(true, odv)
            this.doAvviaProcessoOscuramento()
            return
          }
        },
        (err) => {
          console.error(err)
        }
      )
    } else {
      this.oscuramentiService
        .avviaProcessoOscuramentoDatiManifestamenteNonNecessari(
          this.dirittoSelezionato.id
        )
        .subscribe(
          (esito) => {
            if (esito.esito === ESITO_OK) {
              this.processoOscuramento = JSON.parse(esito.payload)
              this.autorizzazioniOscuramento =
                this.processoOscuramento.autorizzazioniOscuramento
              this.autorizzazioniOscuramentoLavorabili =
                this.processoOscuramento.autorizzazioniOscuramento.filter(
                  (autorizzazione) =>
                    // verifico che l'utente corrente appartenga all'odv destinatario dell'autorizzazione
                    isMembroDiGruppo(this.utente, autorizzazione.odv)
                )
              this.gruppiImpersonati =
                this.autorizzazioniOscuramentoLavorabili.
                  filter(autorizzazione => autorizzazione.utente?.id === this.authService.getUser().id).
                  map(
                    (autorizzazione) => (autorizzazione.odv)
                  )
              // seleziono il gruppo responsabile
              this.onGruppoSelezionato(
                true,
                this.processoOscuramento.responsabile
              )

              this.modalitaOscuramento = true
              this.modalitaOmissis = this.modalitaOscuramento
              this.omissis = this.processoOscuramento.omissis
              this.initSegments()

              this.snackBar.open(
                $localize`Processo di oscuramento avviato con successo`,
                null,
                {
                  duration: 4000,
                }
              )
            } else {
              const codice = esito.esito
              let messaggio = ''
              switch (codice) {
                case Esito.ESISTE_GIA_UN_PROCESSO_DI_OSCURAMENTO_IN_CORSO:
                  messaggio = $localize`Esiste già un processo di oscuramento in corso per questa segnalazione`
                  this.recuperaProcessoOscuramentoInCorso()
                  break
                case Esito.DIRITTO_SEGNALAZIONE_CANCELLATO:
                  messaggio = $localize`Il diritto di segnalazione è stato cancellato`
                  break
                case Esito.DIRITTO_SEGNALAZIONE_NON_SCELTO_DA_SEGNALANTE:
                  messaggio = $localize`Non hai ricevuto la segnalazione direttamente dal segnalante`
                  break
                case Esito.DIRITTO_SEGNALAZIONE_ODV_DESTINATARIO_IN_CONFLITTO_DI_INTERESSI:
                  messaggio = $localize`Sei in conflitto di interessi`
                  break
              }
              this.snackBar.open(
                $localize`Errore durante l'avvio del processo di oscuramento: ` +
                messaggio,
                null,
                {
                  duration: 8000,
                }
              )
            }
          },
          (err) => {
            this.snackBar.open(
              $localize`Errore durante l'avvio del processo di oscuramento`,
              null,
              {
                duration: 4000,
              }
            )
          }
        )
    }
  }

  handleClickBottoneSottomettiProcessoOscuramento() {
    // se esiste un processo oscuramento in corso entra in modalità gestione oscuramento
    if (this.processoOscuramento) {
      openDialogGenerica(
        this.dialog,
        $localize`Condividi la proposta di oscuramento con gli altri gestori`,
        '',
        // eslint-disable-next-line max-len
        $localize`Confermi di voler procedere alla condivisione della proposta di oscuramento con gli altri gestori?`,
        () => this.doCondivisiProcessoOscuramento(),
        () => { }
      )
    } else {
      // altrimenti avvia il processo di oscuramento
      this.snackBar.open(
        // eslint-disable-next-line max-len
        $localize`Non è possibile condividere la proposta di oscuramento perché non è stato avviato un processo di oscuramento`,
        null,
        {
          duration: 8000,
        }
      )
    }
  }

  doCondivisiProcessoOscuramento() {
    this.oscuramentiService
      .condividiProcessoOscuramento(this.processoOscuramento.id)
      .subscribe(
        (esito) => {
          if (esito.esito === ESITO_OK) {
            this.processoOscuramento.condiviso = true
            this.snackBar.open(
              $localize`Processo di oscuramento condiviso con successo`,
              null,
              {
                duration: 4000,
              }
            )
          } else {
            this.snackBar.open(
              $localize`Errore durante la condivisione del processo di oscuramento: ` +
              esito.payload,
              null,
              {
                duration: 4000,
              }
            )
          }
        },
        (err) => {
          this.snackBar.open(
            $localize`Errore durante la condivisione del processo di oscuramento`,
            null,
            {
              duration: 4000,
            }
          )
        }
      )
  }

  recuperaProcessoOscuramentoInCorso() {
    // si accerta che la segnalazione abbia un id e che sia un numero
    if (!this.segnalazione || (!this.segnalazione.id || isNaN(+this.segnalazione.id))) {
      return
    }
    this.oscuramentiService
      .recuperaProcessoOscuramentoInCorso(+this.segnalazione.id)
      .subscribe(
        (esito) => {
          if (esito.esito === ESITO_OK) {
            this.processoOscuramento =
              esito.payload === '{}' ? undefined : JSON.parse(esito.payload)
            if (this.processoOscuramento) {
              this.sottoscriviSeNecessario(this.processoOscuramento)
              this.omissis = this.processoOscuramento.omissis
              this.autorizzazioniOscuramento =
                this.processoOscuramento.autorizzazioniOscuramento
              this.autorizzazioniOscuramentoLavorabili =
                this.processoOscuramento.autorizzazioniOscuramento.filter(
                  (autorizzazione) =>
                    // verifico che l'utente corrente appartenga all'odv destinatario dell'autorizzazione
                    isMembroDiGruppo(this.utente, autorizzazione.odv)
                )
              this.gruppiImpersonati =
                this.autorizzazioniOscuramentoLavorabili.
                  filter(autorizzazione => autorizzazione.utente?.id === this.authService.getUser().id).
                  map(
                    (autorizzazione) => (autorizzazione.odv)
                  )
              // se l'utente corrente ha membership nel gruppo responsabile del processo di oscuramento
              // lo seleziono
              if (
                isMembroDiGruppo(
                  this.utente,
                  this.processoOscuramento.responsabile
                )
              ) {
                this.onGruppoSelezionato(
                  true,
                  this.processoOscuramento.responsabile
                )
              } else {
                // altrimenti seleziono il primo gruppo di cui l'utente corrente è membro
                this.onGruppoSelezionato(true, this.gruppiImpersonati[0])
              }
              this.initSegments()
              this.cd.markForCheck() // Marca il componente per il prossimo ciclo di detection
            } else {
              this.processoOscuramento = undefined
              this.modalitaOmissis = false
              this.modalitaOscuramento = false
              this.omissis = []
              this.loadingSegnalazione = true
              this.reloadSegnalazione()
            }
          } else {
            this.snackBar.open(
              $localize`Errore durante il recupero del processo di oscuramento: ` +
              esito.payload,
              null,
              {
                duration: 4000,
              }
            )
          }
        },
        (err) => {
          this.snackBar.open(
            $localize`Errore durante il recupero del processo di oscuramento`,
            null,
            {
              duration: 4000,
            }
          )
        }
      )
  }

  recuperaUltimoOscuramentoChiuso() {
    // si accerta che la segnalazione abbia un id e che sia un numero
    if (!this.segnalazione || !this.segnalazione.id || isNaN(+this.segnalazione.id)) {
      return
    }
    this.oscuramentiService
      .recuperaUltimoOscuramentoChiuso(
        +this.segnalazione.id
      )
      .subscribe(
        (esito) => {
          if (esito.esito === ESITO_OK) {
            this.ultimoProcessoOscuramentoGestitoEChiuso =
              esito.payload === '{}' || esito.payload === 'undefined' || esito.payload === undefined ? undefined : JSON.parse(esito.payload)
          } else {
            this.snackBar.open(
              $localize`Errore durante il recupero del processo di oscuramento: ` +
              esito.payload,
              null,
              {
                duration: 4000,
              }
            )
          }
        },
        (err) => {
          this.snackBar.open(
            $localize`Errore durante il recupero del processo di oscuramento`,
            null,
            {
              duration: 4000,
            }
          )
        }
      )
  }

  handleClickAutorizzaOscuramento() {
    // apri una dialog per chiedere conferma della volontà di autorizzare l'oscuramento
    openDialogGenerica(
      this.dialog,
      $localize`Autorizza l'oscuramento della Segnalazione`,
      '',
      // eslint-disable-next-line max-len
      $localize`Confermi di voler autorizzare l'oscuramento del contenuto selezionato? L'operazione sarà applicata solo se autorizzata da tutti i gestori destinatari diretti della segnalazione.`,
      () => this.doAutorizzaOscuramento(),
      () => { }
    )
  }

  doAutorizzaOscuramento() {
    if (!this.autorizzazioneOscuramento) {
      this.snackBar.open(
        // eslint-disable-next-line max-len
        $localize`Non è possibile autorizzare l'oscuramento del contenuto della segnalazione perché non sei un destinatario diretto della segnalazione`,
        null,
        {
          duration: 8000,
        }
      )
      return
    }

    this.oscuramentiService
      .autorizzaOscuramento(this.autorizzazioneOscuramento.id)
      .subscribe(
        (esito) => {
          if (esito.esito === ESITO_OK) {
            const autorizzazione = JSON.parse(esito.payload)['autorizzazione']
            const processoChiuso = JSON.parse(esito.payload)['processoChiuso']
            const processoAutorizzato = JSON.parse(esito.payload)[
              'processoAutorizzato'
            ]
            // aggiorna il processo di oscuramento
            this.autorizzazioneOscuramento.autorizzato =
              autorizzazione.autorizzato
            this.autorizzazioneOscuramento.dataDecisione =
              autorizzazione.dataDecisione

            // aggiorna ascoltatori del subject eliminaAutorizzazionePendente
            this.oscuramentiService.eliminaAutorizzazionePendente.next(this.autorizzazioneOscuramento.id)
            if (processoChiuso) {
              this.processoOscuramento = undefined
              this.modalitaOmissis = false
              this.modalitaOscuramento = false
              this.omissis = []
              this.loadingSegnalazione = true
              this.reloadSegnalazione()
              this.recuperaUltimoOscuramentoChiuso()
            }
            this.snackBar.open(
              $localize`Operazione avvenuta con successo` +
              (processoChiuso
                ? processoAutorizzato
                  ? $localize`, processo di oscuramento chiuso ed autorizzato`
                  : $localize`, processo di oscuramento chiuso e respinto`
                : ''),
              null,
              {
                duration: 8000,
              }
            )
          } else {
            this.snackBar.open(
              $localize`Errore durante l'autorizzazione dell'oscuramento: ` +
              esito.payload,
              null,
              {
                duration: 4000,
              }
            )
          }
        },
        (err) => {
          this.snackBar.open(
            $localize`Errore durante l'autorizzazione dell'oscuramento`,
            null,
            {
              duration: 4000,
            }
          )
        }
      )
  }

  handleClickNegaOscuramento() {
    // apri una dialog per chiedere conferma della volontà di negare l'oscuramento
    openDialogGenerica(
      this.dialog,
      $localize`Nega l'oscuramento della Segnalazione`,
      '',
      // eslint-disable-next-line max-len
      $localize`Confermi di voler negare l'oscuramento del contenuto selezionato?
      La tua decisione determinerà il respingimento della proposta di oscuramento.`,
      () => this.doNegaOscuramento(),
      () => { }
    )
  }

  doNegaOscuramento() {
    if (!this.autorizzazioneOscuramento) {
      this.snackBar.open(
        // eslint-disable-next-line max-len
        $localize`Non è possibile negare l'oscuramento del contenuto della segnalazione perché non sei un destinatario diretto della segnalazione`,
        null,
        {
          duration: 8000,
        }
      )
      return
    }

    this.oscuramentiService
      .rifiutaOscuramento(this.autorizzazioneOscuramento.id)
      .subscribe(
        (esito) => {
          if (esito.esito === ESITO_OK) {
            const autorizzazione = JSON.parse(esito.payload)['autorizzazione']
            const processoChiuso = JSON.parse(esito.payload)['processoChiuso']
            const processoAutorizzato = JSON.parse(esito.payload)[
              'processoAutorizzato'
            ]
            // aggiorna il processo di oscuramento
            this.autorizzazioneOscuramento.autorizzato =
              autorizzazione.autorizzato
            this.autorizzazioneOscuramento.dataDecisione =
              autorizzazione.dataDecisione

            // aggiorna ascoltatori del subject eliminaAutorizzazionePendente
            this.oscuramentiService.eliminaAutorizzazionePendente.next(this.autorizzazioneOscuramento.id)

            if (processoChiuso) {
              this.processoOscuramento = undefined
              this.modalitaOmissis = false
              this.modalitaOscuramento = false
              this.omissis = []
              this.loadingSegnalazione = true
              this.reloadSegnalazione()
              this.recuperaUltimoOscuramentoChiuso()
            }
            this.snackBar.open(
              $localize`Operazione avvenuta con successo` +
              (processoChiuso
                ? processoAutorizzato
                  ? $localize`, processo di oscuramento chiuso ed autorizzato`
                  : $localize`, processo di oscuramento chiuso e respinto`
                : ''),
              null,
              {
                duration: 8000,
              }
            )
          } else {
            this.snackBar.open(
              $localize`Errore durante l'esecuzione dell'operazione: ` +
              esito.payload,
              null,
              {
                duration: 4000,
              }
            )
          }
        },
        (err) => {
          this.snackBar.open(
            $localize`Errore durante l'esecuzione dell'operazione`,
            null,
            {
              duration: 4000,
            }
          )
        }
      )
  }

  handleClickAnnullaOscuramento() {
    // apri una dialog per chiedere conferma della volontà di annullare l'oscuramento
    openDialogGenerica(
      this.dialog,
      $localize`Annulla l'oscuramento della Segnalazione`,
      '',
      // eslint-disable-next-line max-len
      $localize`Confermi di voler annullare il processo di oscuramento in corso?`,
      () => this.doAnnullaOscuramento(),
      () => { }
    )
  }

  doAnnullaOscuramento() {
    this.oscuramentiService
      .annullaProcessoOscuramento(this.processoOscuramento.id)
      .subscribe(
        (esito) => {
          if (esito.esito === ESITO_OK) {
            this.processoOscuramento = undefined
            this.modalitaOmissis = false
            this.modalitaOscuramento = false
            this.omissis = []
            this.loadingSegnalazione = true
            this.reloadSegnalazione()
            this.recuperaProcessoOscuramentoInCorso()
            this.snackBar.open(
              $localize`Operazione eseguita con successo`,
              null,
              {
                duration: 4000,
              }
            )
          } else {
            this.snackBar.open(
              $localize`Errore durante l'esecuzione dell'operazione: ` +
              esito.payload,
              null,
              { duration: 4000 }
            )
          }
        },
        (err) => {
          this.snackBar.open(
            $localize`Errore durante l'esecuzione dell'operazione`,
            null,
            { duration: 4000 }
          )
        }
      )
  }

  doAutoescludi() {
    this.segnalazioniService
      .inibisciUtentePerSegnalazione(
        +this.segnalazione.id,
        this.authService.getUser().id,
        true
      )
      .subscribe((esito) => {
        if (esito.esito === ESITO_OK) {
          this.snackBar.open(
            $localize`Auto esclusione avvenuta con successo`,
            null,
            {
              duration: 4000,
            }
          )

          // elimina la segnalazione dalla lista delle segnalazioni dell'utente
          this.sessionData.segnalazioni = this.sessionData.segnalazioni.filter(
            (segnalazione) => segnalazione.id !== this.segnalazione.id
          )
          // torna alla home
          this.router.navigate(['/home'])
        } else {
          this.snackBar.open(
            $localize`Auto esclusione Fallita. Errore: ` + esito.payload,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
  }

  abilitaModificaDatiSegnalante() {
    this.datiSegnalanteInModifica = true
    this.segnalazioneForm.controls.nomeSegnalante.enable()
    this.segnalazioneForm.controls.cognomeSegnalante.enable()
    this.segnalazioneForm.controls.genereSegnalante.enable()
    this.segnalazioneForm.controls.codiceFiscaleSegnalante.enable()
    this.segnalazioneForm.controls.dataDiNascitaSegnalante.enable()
    this.segnalazioneForm.controls.luogoDiNascitaSegnalante.enable()
    this.segnalazioneForm.controls.indirizzoDiResidenzaSegnalante.enable()
    this.segnalazioneForm.controls.capSegnalante.enable()
    this.segnalazioneForm.controls.cittaSegnalante.enable()
    this.segnalazioneForm.controls.regioneSegnalante.enable()
    this.segnalazioneForm.controls.statoSegnalante.enable()
    this.segnalazioneForm.controls.telefonoSegnalante.enable()
    this.segnalazioneForm.controls.emailSegnalante.enable()
    this.segnalazioneForm.controls.rapportoConLaSocietaSegnalante.enable()
    this.segnalazioneForm.controls.incarico.enable()
    this.segnalazioneForm.controls.orgUnitAndSite.enable()
    this.segnalazioneForm.controls.qualificaSegnalante.enable()
    this.segnalazioneForm.controls.incaricoT0.enable()
    this.segnalazioneForm.controls.orgUnitAndSiteT0.enable()
    this.segnalazioneForm.controls.qualificaSegnalanteT0.enable()
  }

  get sonoGestoreSceltoDaSegnalante() {
    return helperSegnalazioni.sonoGestoreSceltoDaSegnalante(
      this.segnalazione,
      this.utente
    )
  }

  ritiroSegnalazioneDialog() {
    openDialogGenerica(
      this.dialog,
      $localize`Ritiro Segnalazione`,
      '',
      // eslint-disable-next-line max-len
      $localize`Confermi di voler ritirare la segnalazione? Dopo il ritiro il segnalante non potrà più accedervi.`,
      () => this.doRitiroSegnalazione(true),
      () => { }
    )
  }

  annullaRitiroSegnalazioneDialog() {
    openDialogGenerica(
      this.dialog,
      $localize`Annulla il ritiro della segnalazione`,
      '',
      // eslint-disable-next-line max-len
      $localize`Confermi di voler annullare il ritiro della segnalazione? Dopo questa operazione il segnalante potrà accedervi nuovamente.`,
      () => this.doRitiroSegnalazione(false),
      () => { }
    )
  }

  doRitiroSegnalazione(ritira: boolean) {
    this.segnalazioniService
      .ritiraSegnalazione(+this.segnalazione.id, ritira)
      .subscribe((esito) => {
        if (esito.esito === ESITO_OK) {
          this.snackBar.open(
            $localize`Operazione avvenuto con successo`,
            null,
            {
              duration: 4000,
            }
          )
          // aggiorna lo stato della segnalazione
          if (ritira) {
            this.segnalazione.stato = RITIRATA_INDEX.toString()
          } else {
            this.segnalazione.stato =
              TUTTE_LE_LAVORAZIONI_CHIUSE_INDEX.toString()
          }
        } else {
          this.snackBar.open(
            $localize`Operazione Fallita. Errore: ` + esito.payload,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
  }

  get segnalazioneRitirata() {
    return +this.segnalazione.stato === RITIRATA_INDEX
  }

  get gestioneConflittoInteressiAttivo() {
    return this.sessionData.configurazione?.abilitaGestioneConflittoInteressi
  }

  get ciSonoOmissisNellOscuramento() {
    return this.processoOscuramento.omissis.length > 0
  }

  onGruppoSelezionato(sourceSelected, gruppo: Odv) {
    if (sourceSelected) {
      this.gruppoSelezionato = this.gruppiImpersonati?.find(
        (odv) => odv.id === gruppo.id
      )
      // valorizzo l'autorizzazione oscuramento corrispondente al gruppo selezionato
      this.autorizzazioneOscuramento =
        this.processoOscuramento?.autorizzazioniOscuramento.find(
          (autorizzazione) => autorizzazione.odv.id === gruppo.id &&
            autorizzazione.utente?.id === this.authService.getUser().id
        )
      const dirittiSegnalazioneRicevutiDaSegnalante =
        dirittiSegnalazioneUtenteRicevutiDaSegnalante(
          this.segnalazione,
          this.authService.getUser()
        )
          // seleziono quelli non annullati e non in conflitto
          .filter(
            (dirsegna) =>
              !dirsegna.odvDestinatarioInConflittoDiInteressi &&
              !dirsegna.cancellato
          )
      // seleziona i dirittiSegnalazioneRicevutiDaSegnalante per il gruppo selezionato
      this.dirittoSelezionato = dirittiSegnalazioneRicevutiDaSegnalante.find(
        (dirsegna) => dirsegna.odvDestinatario.id === gruppo.id
      )
      this.cdr.detectChanges()
    }
  }

  aggiornaDocumenti() {
    if (this.segnalazione && this.segnalazione.id) {
      this.documentiService
        .getDocumentiAssociatiASegnalazione(this.segnalazione)

        .then((esito: Esito) => {
          if (esito.esito === ESITO_OK) {
            const documenti = esito.payload ? JSON.parse(esito.payload) : []
            this.segnalazione.documenti = documenti
          } else {
            //
            console.log('Errore nel recupero degli allegati della segnalazione')
          }
        })
    }
  }

  get gruppoSelezionatoResponsabile() {
    return (
      this.gruppoSelezionato?.id === this.processoOscuramento?.responsabile?.id
    )
  }

  mostrareBadge(elemento: MatButton) {
    const badge = elemento._elementRef.nativeElement.querySelector(
      '.mat-badge-content'
    ) as HTMLElement

    if (badge) {
      if (
        this.processoOscuramento &&
        this.processoOscuramento.inCorso &&
        this.processoOscuramento.condiviso
      ) {
        badge.style.backgroundColor = 'red'
        badge.style.color = 'white'
        return true
      }
    }

    return false
  }

  contaAutorizzazioniPendenti() {
    // conta le autorizzazioni pendenti per l'utente corrente
    // quindi si assicura che riguardino gruppi di cui l'utente corrente è membro
    if (this.processoOscuramento && this.authService.getUser().id === this.processoOscuramento.utenteResponsabile.id) {
      return this.contaAutorizzazioniPendentiTotali()
    }
    return this.processoOscuramento?.autorizzazioniOscuramento.filter(
      (autorizzazione) =>
        autorizzazione.autorizzato === null &&
        autorizzazione.utente?.id === this.authService.getUser().id &&
        isMembroDiGruppo(this.utente, autorizzazione.odv)
    ).length
  }

  contaAutorizzazioniPendentiTotali() {
    // conta le autorizzazioni pendenti per l'utente corrente
    // quindi si assicura che riguardino gruppi di cui l'utente corrente è membro
    // ma se l'utente è il gestore mostra il totale complessivo
    if (this.processoOscuramento && this.authService.getUser().id === this.processoOscuramento.utenteResponsabile.id) {
      return this.processoOscuramento?.autorizzazioniOscuramento.filter(
        (autorizzazione) =>
          autorizzazione.autorizzato === null
      ).length
    }
  }

  possiedeAutorizzazioniPendenti(odv: Odv) {
    // verifica se l'utente corrente ha autorizzazioni pendenti per l'odv passato
    return this.processoOscuramento?.autorizzazioniOscuramento.filter(
      (autorizzazione) =>
        autorizzazione.odv.id === odv.id &&
        autorizzazione.utente?.id === this.authService.getUser().id &&
        autorizzazione.autorizzato === null
    ).length
  }
  get utentiEsclusi() {
    return this.inibizioniUtente
  }

  async recuperaUtentiInibitiPerSegnalazione() {
    // si accerta che la segnalazione abbia un id e che sia un numero
    if (!this.segnalazione || !this.segnalazione.id || isNaN(+this.segnalazione.id)) {
      return []
    }
    try {
      this.segnalazioniService
        .recuperaUtentiInibitiPerSegnalazione(+this.segnalazione.id)
        .subscribe((esito) => {
          if (esito.esito === ESITO_OK) {
            this.inibizioniUtente = JSON.parse(
              esito.payload
            ) as InibizioneUtenteGestoreSuSegnalazione[]
          } else {
            this.snackBar.open(
              $localize`Non è stato possibile recuperare gli utenti inibiti per la segnalazione con id ${this.segnalazione.id}
        ed avente ad oggetto ${this.segnalazione.oggetto}.
        ${esito.descrizioneEsito}`,
              null,
              {
                duration: 4000,
              }
            )
            return []
          }
        })
    } catch (error) {
      this.snackBar.open(
        $localize`Non è stato possibile recuperare gli utenti inibiti per la segnalazione con id ${this.segnalazione.id}
        ed avente ad oggetto ${this.segnalazione.oggetto}.
        ${error}`,
        null,
        {
          duration: 4000,
        }
      )
      this.inibizioniUtente = []
    }
  }

  riabilitaUtenteDialog(
    inibizioneUtente: InibizioneUtenteGestoreSuSegnalazione
  ) {
    openDialogGenerica(
      this.dialog,
      $localize`Riabilita la visualizzazione della Segnalazione per l'utente ${inibizioneUtente.utenteInibito.userId}`,
      '',
      $localize`Confermi di voler procedere alla riabilitazione dell'accesso alla segnalazione?`,
      () => this.doRiabilita(inibizioneUtente),
      () => { }
    )
  }

  doRiabilita(inibizioneUtente) {
    this.segnalazioniService
      .inibisciUtentePerSegnalazione(
        +inibizioneUtente.segnalazione.id,
        inibizioneUtente.utenteInibito.id,
        false
      )
      .subscribe((esito) => {
        if (esito.esito === ESITO_OK) {
          this.snackBar.open(
            $localize`Riabilitazione avvenuta con successo`,
            null,
            {
              duration: 4000,
            }
          )

          // elimina l'inibizione dalla lista
          this.inibizioniUtente = this.inibizioniUtente.filter(
            (inibizione) =>
              inibizione.utenteInibito.userId !==
              inibizioneUtente.utenteInibito.userId
          )
        } else {
          this.snackBar.open(
            $localize`Riabilitazione Fallita. Errore: ` + esito.payload,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
  }

  toolTipBottoneModificaDatiSegnalante() {
    return this.processoOscuramento
      ? $localize`Processo d'oscuramento in corso, non è possibile modificare i dati del segnalante`
      : $localize`Modifica i dati del segnalante`
  }

  get hoPresoInCaricoSegnalazione() {
    return helperSegnalazioni.hoPresoInCaricoSegnalazione(
      this.segnalazione,
      this.authService.getUser()
    )
  }

  get oscuramentoInPreparazioneENonSonoGestore() {
    return (
      this.processoOscuramento &&
      !this.processoOscuramento.condiviso &&
      this.utente.id !== this.processoOscuramento.utenteResponsabile.id)
  }
  get oscuramentoInPreparazioneESonoGestore() {
    return (
      this.processoOscuramento &&
      !this.processoOscuramento.condiviso &&
      this.utente.id === this.processoOscuramento.utenteResponsabile.id)
  }

  sottoscriviSeNecessario(processoOscuramento: OscuramentoDatiManifestamenteNonNecessari) {
    if (processoOscuramento) {

      // Se esiste già una sottoscrizione per questo processoOscuramento, non fare nulla
      if (this.processoOscuramentoSubscriptions.has(processoOscuramento.id)) {
        return
      }

      // se non è presente la aggiunge
      this.segnalazioniService.sottoscriviPerAggiornamentiProcessoOscuramento(processoOscuramento)

      const subscription = this.segnalazioniService.notifichePerAggiornamentoProcessoOscuramento.subscribe(
        (processoOscuramentoAggiornato) => {
          if (processoOscuramentoAggiornato.id === processoOscuramento.id) {
            this.recuperaProcessoOscuramentoInCorso()
            this.recuperaUltimoOscuramentoChiuso()
          }
        }
      )

      // Salva la sottoscrizione nel registro
      this.processoOscuramentoSubscriptions.set(processoOscuramento.id, subscription)
    }
  }

  sospendiCancellazione() {
    let titolo = $localize`Sospendi la cancellazione della Segnalazione`
    let conferma = $localize`Confermi di voler procedere alla sospensione della cancellazione della segnalazione?`
    if (this.segnalazione.sospendiCancellazione) {
      titolo = $localize`Riattiva la cancellazione della Segnalazione`
      conferma = $localize`Confermi di voler procedere alla riattivazione della cancellazione della segnalazione?`
    }
    openDialogGenerica(
      this.dialog,
      titolo,
      '',
      conferma,
      () => this.doSospendiCancellazione(),
      () => { }
    )
  }

  doSospendiCancellazione() {
    this.segnalazioniService.sospendiCancellazione(+this.segnalazione.id, !this.segnalazione.sospendiCancellazione).subscribe(
      (esito) => {
        if (esito.esito === ESITO_OK) {
          this.segnalazione.sospendiCancellazione = !this.segnalazione.sospendiCancellazione
          this.snackBar.open(
            $localize`Operazione avvenuta con successo`,
            null,
            {
              duration: 4000,
            }
          )
          this.segnalazione.sospendiCancellazione = !this.segnalazione.sospendiCancellazione
          this.reloadSegnalazione()
        } else {
          this.snackBar.open(
            $localize`Operazione Fallita. Errore: ` + esito.payload,
            null,
            {
              duration: 4000,
            }
          )
        }
      })

  }
  compareAziende(a1: any, a2: any): boolean {
    return a1 && a2 ? a1.id === a2.id : a1 === a2
  }

  get descrizioneObbligatoria() {
    return !this.segnalazioneForm.controls['segnalazioneVocale'].value &&
      !(this.segnalazioneForm.controls['hashAudio']?.value || this.registrazioneVocaleDaSalvare)
  }

  get vocaleObbligatorio() {
    return this.segnalazioneForm.controls['segnalazioneVocale'].value ? '*' : ''
  }

  get isRtl() {
    return ['ar', 'he', 'fa'].includes(this.codiceLingua)
  }
  statoVerificaEmailSegnalante() {
    if (this.segnalazione.segnalante?.utente?.emailVerificata) {
      return $localize`(notifiche e-mail attive)`;
    } else {
      return '';
    }
  }

  // Funzione helper per forzare l'aggiornamento quando necessario
  private refreshView(): void {
    this.cd.detectChanges();
  }

}

export interface Segment {
  campo: string
  parola: string
  progressivo: number
  isOmissis: boolean
  idEntita: number
}
