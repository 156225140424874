import { Component, Output, EventEmitter, Inject, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TipoDocumento } from '../../modello/documento'
import { MAX_FILE_SIZE_MB, MAX_TOTAL_FILE_SIZE_MB } from '../../utility/utility'
import { FileInput } from '../input-file/file-input.model'
import { InputFileComponent } from '../input-file/input-file.component'
import { VisualizzatorePdfDialogComponent } from '../visualizzatore-pdf/visualizzatore-pdf.component'

@Component({
  selector: 'app-allegati-dialog',
  templateUrl: './allegati-dialog.component.html',
  styleUrls: ['./allegati-dialog.component.scss']
})
export class AllegatiDialogComponent {
  allegati: any[]
  @Output() onEliminaAllegato = new EventEmitter<any>()
  @Output() onAggiungiAllegati = new EventEmitter<any>()
  @Output() onFileChanged = new EventEmitter<any>()
  @ViewChild('newattachedfile') newAttachedFile: InputFileComponent
  @Output() fileSelected = new EventEmitter<File[]>()
  formAllegati: FormGroup
  totalFileSize: number
  constructor(
    public dialogRef: MatDialogRef<AllegatiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { allegati: any[] },
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
    this.allegati = data.allegati
    this.formAllegati = this.formBuilder.group({
      files: ['']
    });

    // Calcola la dimensione totale iniziale
    this.totalFileSize = this.allegati?.reduce((acc, doc) => {
      const size = doc.size ? parseInt(doc.size as unknown as string, 10) : 0;
      return acc + (size / (1024 * 1024)); // Converti in MB
    }, 0) || 0;
  }

  ngOnInit() {
    this.formAllegati = new FormGroup({
      files: new FormControl(null)
    })
  }

  eliminaAllegato(allegato: any) {
    const index = this.allegati.indexOf(allegato);
    if (index > -1) {
      this.allegati.splice(index, 1);
      this.totalFileSize -= (allegato.size / (1024 * 1024));

      // Reset del form dopo l'eliminazione
      this.formAllegati.patchValue({
        files: null
      });

      // Reset dell'input file
      if (this.newAttachedFile) {
        this.newAttachedFile.resetInput();
      }
    }
    this.onEliminaAllegato.emit(allegato);
  }

  closeDialog() {
    if (this.totalFileSize > MAX_TOTAL_FILE_SIZE_MB) {
      this.snackBar.open(
        $localize`La dimensione totale dei file supera il limite di ${MAX_TOTAL_FILE_SIZE_MB} MB. Rimuovere alcuni allegati prima di chiudere.`,
        null,
        { duration: 4000 }
      );
      return;
    }
    this.dialogRef.close(this.allegati);
  }

  visualizza(documento) {
    if (documento.id === 'OMISSIS') {
      this.snackBar.open($localize `Non sei abilitato al download del documento!`, null, {
        duration: 6000,
      })
      return
    }

    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: documento,
        testo: undefined, titolo: $localize `Visualizzazione documento`,
        tipoDocumento: TipoDocumento.TIPO_ALLEGATO,
        bypassAuth: false,
        canDownload: false,
        canPrint: false,
      }
    })

  }

  handleFileInput(target: any): void {
    const files = target['files'];
    const fileArray = (this.formAllegati.controls['files'].value as FileInput)?.files;

    // Calcola la nuova dimensione totale
    let newTotalSize = this.totalFileSize;
    // eventuali file con dimensione maggiore di MAX_FILE_SIZE_MB non vengono aggiunti
    for (const file of fileArray) {
      if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
        this.snackBar.open($localize `Il file ${file.name} supera il limite dei ${MAX_FILE_SIZE_MB} MB e non può essere allegato`, null, {
          duration: 4000,
        })
        fileArray.splice(fileArray.indexOf(file), 1)
        continue
      }
      newTotalSize += file.size / (1024 * 1024); // Converti in MB
    }

    // Verifica se supera il limite
    if (newTotalSize > MAX_TOTAL_FILE_SIZE_MB) {
      // giustappone il nome dei nuovi file non aggiunti
      const fileNames = fileArray.map(file => file.name).join(', ');
      this.snackBar.open(
        $localize`La dimensione totale dei file supera il limite di ${MAX_TOTAL_FILE_SIZE_MB} MB. I file ${fileNames} non sono stati aggiunti.`,
        null,
        { duration: 4000 }
      );
      return;
    }

    // Aggiorna la dimensione totale
    this.totalFileSize = newTotalSize;

    this.fileSelected.emit(fileArray);
    if (this.newAttachedFile) {
      this.newAttachedFile.resetInput();
    }
  }

}
