import { ConfigurazioneService } from '../servizi/configurazione.service'
import { LavorazioneService } from '../servizi/lavorazione.service'
import { Esito, ESITO_IN_PROGRESS, ESITO_OK } from '../utility/esito'
import {
  IN_LAVORAZIONE_INDEX,
  CHIUSA_INDEX,
  INAMMISSIBILE_INDEX,
  NON_COMPETENTE_INDEX,
  ISTRUTTTORIA_APERTA_INDEX,
  compareDates,
  getVersione,
  BOZZA_INDEX,
  decodificaStatoRichiestaConsenso,
  NESSUNA_LAVORAZIONE_IN_CORSO_INDEX,
  openDialogGenerica,
  decodeHtml,
  SottoTipoContenuto,
  TipoContenuto,
  RITIRATA_INDEX,
  ALCUNE_LAVORAZIONI_CHIUSE_INDEX,
  LAVORAZIONI_IN_CORSO_INDEX,
  TUTTE_LE_LAVORAZIONI_CHIUSE_INDEX,
} from '../utility/utility'

import { RicercaService } from '../servizi/ricerca.service'

import { AuthService } from '../servizi/auth.service'
import { Segnalazione } from '../modello/segnalazione'
import { SessionData } from '../sessione/dati-sessione'
import { SegnalazioneService } from '../servizi/segnalazione.service'
import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  HostListener,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core'

import { ActivatedRoute, Router } from '@angular/router'

import { compare, statoSegnalazioneLeggibile } from '../utility/utility'

import { WINDOW } from '../servizi/window-service'

import { ConfermaEliminazioneSegnalazioneDialogComponent } from './dialogs/conferma-eliminazione-segnalazione.dialog.component'
import { TipoDocumento } from '../modello/documento'
import {
  isIndirizzataAMioGruppo,
  isCoGestita,
  isSoloAltroGestore,
  isSoloIoGestore,
  isSegnalazioneNonGestita,
  isMembroDiGruppo,
} from '../utility/helper-segnalazioni'
import * as moment from 'moment'
import * as helperSegnalazioni from '../utility/helper-segnalazioni'
import { ChatService } from '../servizi/chat.service'
import { MatSort, Sort } from '@angular/material/sort'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DatePipe, DOCUMENT } from '@angular/common'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { pipe, Subscription, Subject } from 'rxjs'
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations'
import { Consenso } from '../modello/consenso'
import { RichiestaConsensoSegnalanteDialogComponent } from './consensi-segnalante/dialog-richiesta-consenso-segnalante-component'
import { DataOraPipe } from '../pipe/data-ora.pipe'

import { MatButton } from '@angular/material/button'
import { MessaggiStore } from '../stores/messaggi.store'
import { VisualizzatorePdfDialogComponent } from '../componenti/visualizzatore-pdf/visualizzatore-pdf.component'

import { Segnalante } from '../modello/segnalante'
import { Azienda } from '../modello/azienda'
import { AziendeService } from '../servizi/aziende.service'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

@Component({
  selector: 'app-elenco-segnalazioni',
  templateUrl: './elenco-segnalazioni.component.html',
  styleUrls: ['./elenco-segnalazioni.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  providers: [DatePipe]
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  // nuove = true;
  // inLavorazione = true;
  // istruttoriaAperta = true;
  // chiuse = true;
  // inammissibili = true;
  // nonDiCompetenza = true;

  navIsFixed: boolean
  dataSource: MatTableDataSource<Segnalazione>
  sottoscrizioneVisibilitaSegnalazione: Subscription
  unlockedRows: boolean[] = []

  @ViewChild(MatSort, { static: true }) sort: MatSort
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<Consenso>>
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>

  @ViewChildren(MatTable) matTables: QueryList<MatTable<any>>
  loading = false;
  elencoAziende: Azienda[]
  colonneVisualizzate: string[]
  mostraSoloTerminiModificati: boolean
  @ViewChild('toggleTermineModifica') toggleButton
  filteredColumns: Set<string> = new Set()
  stringaRicercata: string
  isInputFocused = false

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator
    }
  }


  innerDisplayedColumns = [
    $localize`ID`,
    $localize`Stato Consenso`,
    $localize`Data Richiesta`,
    $localize`Data Risposta`,
    $localize`Nome`,
    $localize`Cognome`,
    $localize`Richiedente`,
    $localize`Azioni`,
  ]
  innerColumns = [
    'id',
    'stato',
    'dataRichiesta',
    'dataRisposta',
    'nome',
    'cognome',
    'odvRichiedente',
    'azioni',
  ]
  expandedElement: Segnalazione | null

  sortedData

  sottoscrizioni = new Array<any>()
  // gestore: boolean;
  // altroGestore: boolean;

  // Proprietà per la paginazione
  currentPage = 1;
  pageSize = 10;
  totalItems = 0;
  totalPages = 0;
  totalItemsOriginal = 0;

  private isPageChangeInProgress = false;
  private searchSubject = new Subject<string>();
  private searchSubscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private segnalazioniService: SegnalazioneService,
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private ricercaService: RicercaService,
    private lavorazioniService: LavorazioneService,
    private cd: ChangeDetectorRef,
    private sessionData: SessionData,
    private messaggiStore: MessaggiStore,
    private route: ActivatedRoute,
    private aziendaService: AziendeService,
    private datePipe: DatePipe
  ) {

    this.colonneVisualizzate = []

    this.sottoscrizioni.push(
      this.segnalazioniService.notificaFocusInputRicerca.subscribe(
        (isFocused) => {
          this.isInputFocused = true
        }
      )
    )

    this.sottoscrizioni.push(
      segnalazioniService.notifichePerNuovaSegnalazione.subscribe(
        (segnalazione) => {
          this.sessionData.segnalazioni.unshift(segnalazione)
          this.refresh()
          this.snackBar.open(
            $localize`Lista aggiornata con una nuova segnalazione con id ${segnalazione.progressivo}
       ed avente ad oggetto ${segnalazione.oggetto}`,
            null,
            {
              duration: 4000,
            }
          )
        }
      )
    )

    this.sottoscrizioni.push(
      segnalazioniService.notifichePerSegnalazioneAggiornata.subscribe(
        (segnalazione) => {
          const indice = this.sessionData.segnalazioni.findIndex(
            (tmpsegnalazione) => tmpsegnalazione.id === segnalazione.id
          )
          if (indice !== -1) {
            this.sessionData.segnalazioni.splice(indice, 1, segnalazione)
          }
          this.refresh()
          this.snackBar.open(
            $localize`Lista aggiornata con una segnalazione modificata con id ${segnalazione.progressivo}
       ed avente ad oggetto ${segnalazione.oggetto}`,
            null,
            {
              duration: 4000,
            }
          )
        }
      )
    )

    this.sottoscrizioni.push(this.ricercaService.filtraElenco.subscribe((cercami: string) => {
      this.applyFilter(cercami)
    }))

    this.sottoscrizioni.push(
      segnalazioniService.notifichePerSegnalazioneInoltrata.subscribe(
        (segnalazione) => {
          // this.sessionData.segnalazioni.unshift(segnalazione);
          this.refresh()
          this.snackBar.open(
            $localize`Ti è stata inoltrata la segnalazione con id ${segnalazione.progressivo}
       ed avente ad oggetto ${segnalazione.oggetto}`,
            null,
            {
              duration: 4000,
            }
          )
        }
      )
    )

    this.sottoscrizioni.push(
      segnalazioniService.notifichePerSegnalazioneDisinoltrata.subscribe(
        (segnalazione) => {
          const indice = this.sessionData.segnalazioni.findIndex(
            (segna) => segna.id === segnalazione.id
          )
          if (indice !== -1) {
            this.sessionData.segnalazioni.splice(indice, 1)
          }
          this.refresh()
          this.snackBar.open(
            $localize`Non hai più accesso alla segnalazione con id ${segnalazione.progressivo}
       ed avente ad oggetto ${segnalazione.oggetto}`,
            null,
            {
              duration: 4000,
            }
          )
        }
      )
    )

    this.sottoscrizioni.push(
      this.messaggiStore.notifichePerInvitoInChat.subscribe((payload) => {
        const tmpIdSegnalazione = payload[1]
        const index = this.sessionData.segnalazioni.findIndex(
          (segna) => +segna.id === +tmpIdSegnalazione
        )
        if (index === -1 && payload[2] === 'IN') {
          console.log(
            'Debbo ricaricare le segnalazioni in quanto sono stato coinvolto nella chat di quella con id ' +
            tmpIdSegnalazione
          )
          this.caricaNuovaSegnalazione(tmpIdSegnalazione)
          this.refresh()
        } else if (index !== -1 && payload[2] === 'IN') {
          console.log(
            'La segnalazione in cui sono stato coinvolto per chat è già una delle mie'
          )
        } else if (index === -1 && payload[2] === 'OUT') {
          console.log(
            'La segnalazione da cui sono stato etromesso per chat non è nel mio elenco'
          )
        } else if (index !== -1 && payload[2] === 'OUT') {
          console.log(
            'La segnalazione da cui sono stato etromesso per chat è nel mio elenco'
          )
          if (
            !isIndirizzataAMioGruppo(
              this.sessionData.segnalazioni[index],
              this.authService.getUser()
            )
          ) {
            this.sessionData.segnalazioni.splice(index, 1)
            this.refresh()
          }
        }
      })
    )
    // disabilitaBack();

    // Configura il debounce per la ricerca
    this.searchSubscription = this.searchSubject.pipe(
      debounceTime(1000), // Aspetta 500ms dopo l'ultima digitazione
      distinctUntilChanged() // Evita richieste duplicate
    ).subscribe(searchTerm => {
      this.executeSearch(searchTerm);
    });
  }

  ngOnInit() {
    // Reset dello stato di loading all'inizializzazione
    this.loading = false;

    // assegna il valore di default se presente in SessionData.soloTerminiCancellazioneSospesi altrimenti false
    this.mostraSoloTerminiModificati = SessionData.soloTerminiCancellazioneSospesi || false;
    this.recuperaTutteLeAziende();

    if (!this.odv && !this.custode) {
      this.colonneVisualizzate = this.colonneVisualizzate.filter(
        (col) => col !== 'progressivo'
      );
    }

    this.lavorazioniService.sottoscriviPerAggiornamentiCondivisioneLavorazione();
    this.segnalazioniService.sottoscriviPerInoltriEDisinoltriSegnalazioni();

    this.sottoscrizioneVisibilitaSegnalazione =
      this.segnalazioniService.flagVisibilitaSegnalazione.subscribe(
        ({ idSegnalazione, visibile }) => {
          this.caricaListaSegnalazioni()
          if (!visibile) {
            //  Se sono il segnalante non posso perdere la visibilità della segnalazione
            const indiceSegnalazioneDaCancellare =
              this.sessionData.segnalazioni.findIndex(
                (segna) => +segna.id === +idSegnalazione
              )
            if (indiceSegnalazioneDaCancellare !== -1) {
              const segnalazione =
                this.sessionData.segnalazioni[indiceSegnalazioneDaCancellare]
              if (
                segnalazione !== undefined &&
                segnalazione.segnalante.utente.id !==
                this.authService.getUserPk()
              ) {
                // const deletedSegnalazione = this.sessionData.segnalazioni.splice
                //  (indiceSegnalazioneDaCancellare, 1)
                // this.caricaListaSegnalazioni()
                // this.snackBar.open($localize `Non hai più visibilità sulla segnalazione con id ${idSegnalazione}
                // ed avente ad oggetto ${deletedSegnalazione?.[0].oggetto}`, null, {
                //    duration: 4000,
                //  })
              }
            }
          } else {
            //  Verifica se la segnalazione non è in lista
            if (
              this.sessionData.segnalazioni.find(
                (seg) => +seg.id === +idSegnalazione
              ) === undefined
            ) {
              this.caricaNuovaSegnalazione(idSegnalazione)
              this.refresh()
            }
          }
        }
      )
    this.route.data.subscribe((data) => {
      // console.log(data);
      const configurazione = data['configurazione']
      if (configurazione) {
        this.sessionData.configurazione = configurazione
      } else {
        console.error(data)
        this.router.navigate(['/landing-page'], {
          queryParams: { initialize: true },
        })
      }
      return
    })
  }

  toggleRow(event, segnalazione: Segnalazione) {
    console.log('toggle', event)
    // if (element.consensi && element.consensi['data']?.length) {
    this.expandedElement =
      this.expandedElement === segnalazione ? null : segnalazione
    // }
    if (this.expandedElement) {
      const consensiNonVisti = segnalazione.consensi?.filter(
        (consenso) => !consenso.visto
      )
      consensiNonVisti.forEach((consenso) => {
        consenso.visto = true
        this.segnalazioniService.salvaConsenso(consenso)
      })
    }
    this.cd.detectChanges()
    this.innerTables.forEach(
      (table, index) =>
      ((table.dataSource as MatTableDataSource<Consenso>).sort =
        this.innerSort.toArray()[index])
    )
  }

  mostrareBadge(elemento: MatButton, segnalazione: Segnalazione) {
    const badge = elemento._elementRef.nativeElement.querySelector(
      '.mat-badge-content'
    ) as HTMLElement

    if (badge) {
      const isConsensoNegato = this.consensoNegato(segnalazione)
      const isConsensoConcesso = this.consensoConcesso(segnalazione)
      const isConsensoDaApprovare = this.consensoDaApprovare(segnalazione)
      const isConsensoDaInviare = this.consensoDaInviare(segnalazione)

      badge.style.backgroundColor = isConsensoNegato
        ? 'red'
        : isConsensoConcesso
          ? 'green'
          : 'accent'
      badge.style.backgroundColor = isConsensoDaInviare
        ? 'orange'
        : badge.style.backgroundColor
      badge.style.color =
        isConsensoNegato || isConsensoConcesso ? 'white' : 'accent'
    }
    const numeroConsensiNonVisti = this.numeroConsensiNonVisti(segnalazione)
    const nuovaRichiestaDiConsensoDaApprovare =
      this.nuovaRichiestaDiConsensoDaApprovare(segnalazione)
    const numeroConsensiDaApprovare =
      this.numeroConsensiDaApprovare(segnalazione)
    const numeroConsensiDaInviare = this.numeroConsensiDaInviare(segnalazione)

    const result =
      numeroConsensiNonVisti === 0 || !nuovaRichiestaDiConsensoDaApprovare

    const presenzConcessiONegati = segnalazione.consensi?.some(
      (consenso) =>
        consenso.attivo &&
        (consenso.stato === Consenso.STATI.CONCESSO ||
          consenso.stato === Consenso.STATI.NEGATO)
    )
    return (
      (result && numeroConsensiDaApprovare > 0) ||
      presenzConcessiONegati ||
      numeroConsensiDaInviare > 0
    )
  }

  calcolaTooltipBottoneConsensi(segnalazione: Segnalazione) {
    if (this.consensoNegato(segnalazione)) {
      return 'Consenso Negato'
    }
    if (this.consensoConcesso(segnalazione)) {
      return 'Consenso Prestato'
    }
    if (this.numeroConsensiDaApprovare(segnalazione) > 0) {
      return 'Consulta consensi in approvazione'
    }
  }

  numeroConsensiNonVisti(segnalazione: Segnalazione) {
    const numero = segnalazione.consensi?.filter(
      (consenso) => !consenso.visto
    )?.length
    return numero !== undefined && numero !== null ? numero : '-'
  }

  numeroConsensiDaApprovare(segnalazione: Segnalazione) {
    const numero = segnalazione.consensi?.filter(
      (consenso) => consenso.stato === Consenso.STATI.RICHIESTA_INOLTRATA
    )?.length
    return numero
  }

  numeroConsensiDaInviare(segnalazione: Segnalazione) {
    const numero = segnalazione.consensi?.filter(
      (consenso) => consenso.stato === Consenso.STATI.ATTESA_INOLTRO
    )?.length
    return numero
  }

  numeroConsensi(segnalazione: Segnalazione) {
    // somma il numero da inviare e da approvare
    const numeroDaApprovare = this.numeroConsensiDaApprovare(segnalazione) || 0
    const numeroDaInviare = this.numeroConsensiDaInviare(segnalazione) || 0
    const numero = +numeroDaApprovare + +numeroDaInviare

    return numero !== 0 ? numero : '-'
  }

  consensoNegato(segnalazione: Segnalazione) {
    return segnalazione.consensi?.some(
      (cns) => cns.attivo && cns.stato === Consenso.STATI.NEGATO
    )
  }

  consensoConcesso(segnalazione: Segnalazione) {
    return segnalazione.consensi?.some(
      (cns) => cns.attivo && cns.stato === Consenso.STATI.CONCESSO
    )
  }

  consensoDaApprovare(segnalazione: Segnalazione) {
    return segnalazione.consensi?.some(
      (cns) => cns.attivo && cns.stato === Consenso.STATI.RICHIESTA_INOLTRATA
    )
  }

  consensoDaInviare(segnalazione: Segnalazione) {
    return segnalazione.consensi?.some(
      (cns) => cns.stato === Consenso.STATI.ATTESA_INOLTRO
    )
  }

  ngAfterViewInit() {
    // Imposta il valore iniziale del toggle button
    this.toggleButton.checked = this.mostraSoloTerminiModificati;

    // Configuro il paginatore prima di caricare i dati
    this.configurePaginator();

    // Carico le segnalazioni
    setTimeout(() => {
      if (!this.loading) {  // Verifico che non sia già in corso un caricamento
        this.caricaListaSegnalazioni();
      }
    });
  }

  ngOnDestroy(): void {
    this.sottoscrizioneVisibilitaSegnalazione?.unsubscribe()
    if (this.sottoscrizioni) {
      this.sottoscrizioni.forEach((sottoscrizione) => {
        sottoscrizione.unsubscribe()
      })
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  configurePaginator() {
    if (this.paginator) {

      this.paginator.hasNextPage = () => this.currentPage < this.totalPages;
      this.paginator.hasPreviousPage = () => this.currentPage > 1;

      const paginatorIntl = this.paginator._intl;
      paginatorIntl.nextPageLabel = '';
      paginatorIntl.previousPageLabel = '';
      paginatorIntl.lastPageLabel = '';
      paginatorIntl.firstPageLabel = '';

      // Aggiorna i metodi di navigazione
      this.paginator.hasNextPage = () => {
        return this.currentPage < Math.ceil(this.totalItems / this.pageSize);
      };

      this.paginator.hasPreviousPage = () => {
        return this.currentPage > 1;
      };

      // Aggiungi gestione prima/ultima pagina
      this.paginator.firstPage = () => {
        if (this.currentPage !== 1) {
          this.currentPage = 1;
          this.caricaListaSegnalazioni();
        }
      };

      this.paginator.lastPage = () => {
        const ultimaPagina = Math.ceil(this.totalItems / this.pageSize);
        if (this.currentPage !== ultimaPagina) {
          this.currentPage = ultimaPagina;
          this.caricaListaSegnalazioni();
        }
      };

      paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0) {
          return `0 di ${this.totalItemsOriginal}`;
        }
        const start = (this.currentPage - 1) * pageSize + 1;
        const end = Math.min(this.currentPage * pageSize, this.totalItems);
        return `${start} - ${end} di ${this.totalItemsOriginal}`;
      };

      // Forza l'aggiornamento del paginatore usando metodi pubblici
      this.paginator.length = this.totalItemsOriginal;
      this.paginator.pageIndex = this.currentPage - 1;
      this.paginator.pageSize = this.pageSize;

      // Forza il refresh
      this.cd.detectChanges();
    }
  }

  get mostraTipiNonConformita() {
    return this.sessionData.configurazione
      ?.mostraTipiNonConformitaSuDettaglioSegnalazione
  }

  get multiAzienda() {
    return this.elencoAziende?.length > 1
  }

  caricaListaSegnalazioni() {
    if (this.loading) {
      console.log('Caricamento già in corso, ignoro la richiesta');
      return;
    }

    this.loading = true;
    console.log('Inizio caricamento segnalazioni');

    // Raccogliamo gli stati attivi dai filtri
    const statiAttivi = [];
    if (SessionData.bozza) statiAttivi.push(BOZZA_INDEX);
    if (SessionData.nessunaLavorazioneInCorso) statiAttivi.push(NESSUNA_LAVORAZIONE_IN_CORSO_INDEX);
    if (SessionData.lavorazioniInCorso) statiAttivi.push(LAVORAZIONI_IN_CORSO_INDEX);
    if (SessionData.alcuneLavorazioniChiuse) statiAttivi.push(ALCUNE_LAVORAZIONI_CHIUSE_INDEX);
    if (SessionData.tutteLeLavorazioniChiuse) statiAttivi.push(TUTTE_LE_LAVORAZIONI_CHIUSE_INDEX);
    if (SessionData.ritirata) statiAttivi.push(RITIRATA_INDEX);

    this.segnalazioniService.recuperaSegnalazioni(this.currentPage, this.pageSize, statiAttivi, this.stringaRicercata).subscribe(
      (esito) => {
        if (esito.esito === ESITO_OK) {
          const response = JSON.parse(esito.payload);
          const { segnalazioni, pagination } = response;

          this.totalItems = pagination.total;
          this.totalItemsOriginal = pagination.total;
          this.totalPages = pagination.totalPages;
          this.currentPage = pagination.page;
          this.sessionData.segnalazioni = segnalazioni;

          // Aggiorno il dataSource
          this.sortedData = segnalazioni.map(segnalazione => ({
            ...segnalazione,
            matData: segnalazione.consensi?.length ?
              new MatTableDataSource(segnalazione.consensi) : null
          }));

          this.dataSource = new MatTableDataSource(this.sortedData);
          this.dataSource.sort = this.sort;

          // Aggiorno il paginatore
          if (this.paginator) {
            this.paginator.length = this.totalItemsOriginal;
            this.paginator.pageSize = this.pageSize;
            this.paginator.pageIndex = this.currentPage - 1;
            this.paginator.hasNextPage = () => this.currentPage < this.totalPages;
            this.paginator.hasPreviousPage = () => this.currentPage > 1;
            this.configurePaginator();
          }

          console.log('Caricamento completato con successo');
          this.loading = false;
        } else {
          console.error('Errore nel caricamento:', esito.payload);
          this.loading = false;
          throw new Error(esito.payload);
        }
      },
      (error: Error) => {
        console.error('Errore nel caricamento:', error);
        this.loading = false;
        this.snackBar.open(
          $localize`Non è stato possibile recuperare le segnalazioni ` + error.message,
          null,
          { duration: 4000 }
        );
      }
    );
  }

  onPageChange(event: PageEvent) {
    if (!this.loading && !this.isPageChangeInProgress) {
      try {
        this.isPageChangeInProgress = true;


        const oldPage = this.currentPage;
        this.currentPage = event.pageIndex == event.previousPageIndex ? this.currentPage - 1 : this.currentPage + 1;


        this.pageSize = event.pageSize;

        console.log(`Changing page from ${oldPage} to ${this.currentPage}, total pages: ${this.totalPages}`);

        // Raccogliamo gli stati attivi per il filtro
        const statiAttivi = [];
        if (SessionData.bozza) statiAttivi.push(BOZZA_INDEX);
        if (SessionData.nessunaLavorazioneInCorso) statiAttivi.push(NESSUNA_LAVORAZIONE_IN_CORSO_INDEX);
        if (SessionData.lavorazioniInCorso) statiAttivi.push(LAVORAZIONI_IN_CORSO_INDEX);
        if (SessionData.alcuneLavorazioniChiuse) statiAttivi.push(ALCUNE_LAVORAZIONI_CHIUSE_INDEX);
        if (SessionData.tutteLeLavorazioniChiuse) statiAttivi.push(TUTTE_LE_LAVORAZIONI_CHIUSE_INDEX);
        if (SessionData.ritirata) statiAttivi.push(RITIRATA_INDEX);

        // Ricarica i dati con i filtri
        this.loading = true;
        this.segnalazioniService.recuperaSegnalazioni(
          this.currentPage,
          this.pageSize,
          statiAttivi,
          this.stringaRicercata
        ).subscribe(
          (esito) => {
            if (esito.esito === ESITO_OK) {
              const response = JSON.parse(esito.payload);
              const { segnalazioni, pagination } = response;

              this.totalItems = pagination.total;
              this.totalItemsOriginal = pagination.total;
              this.totalPages = pagination.totalPages;

              this.sessionData.segnalazioni = segnalazioni;
              this.sortedData = segnalazioni.map(segnalazione => ({
                ...segnalazione,
                matData: segnalazione.consensi?.length ?
                  new MatTableDataSource(segnalazione.consensi) : null
              }));

              this.dataSource = new MatTableDataSource(this.sortedData);
              this.dataSource.sort = this.sort;
            }
            this.loading = false;
          },
          (error) => {
            console.error('Errore nel caricamento:', error);
            this.loading = false;
            this.snackBar.open(
              $localize`Errore nel caricamento della pagina`,
              null,
              { duration: 4000 }
            );
          }
        );
      } finally {
        this.isPageChangeInProgress = false;
      }
    }
  }

  caricaNuovaSegnalazione(tmpIdSegnalazione: number): any {
    this.segnalazioniService.recuperaSegnalazione(tmpIdSegnalazione).subscribe(
      (esito) => {
        if (esito.esito === ESITO_OK) {
          this.sessionData.segnalazioni.unshift(JSON.parse(esito.payload))
          this.refresh()
          this.snackBar.open(
            $localize`All\'elenco delle segnalazioni è stata aggiunta quella con id ${tmpIdSegnalazione}`,
            null,
            {
              duration: 4000,
            }
          )
        } else if (esito.esito === ESITO_IN_PROGRESS) {
          // Gestisci lo stato in progress come preferisci
          // Ad esempio, potresti mostrare un messaggio di attesa all'utente
          console.log(esito.descrizioneEsito);
        } else {
          this.snackBar.open(
            $localize`Non è stato possibile recuperare la segnalazione ${JSON.stringify(
              esito.descrizioneEsito
            )}`,
            null,
            {
              duration: 4000,
            }
          )
        }
      },
      (error) => {
        this.snackBar.open(
          $localize`Non è stato possibile recuperare la segnalazione ${tmpIdSegnalazione}`,
          null,
          {
            duration: 4000,
          }
        )
      }
    )
  }

  lavorazioniInCorso() {
    return SessionData.lavorazioniInCorso
  }

  chiuse() {
    return SessionData.tutteLeLavorazioniChiuse
  }

  ritirate() {
    return SessionData.ritirata
  }

  alcuneLavorazioniChiuse() {
    return SessionData.alcuneLavorazioniChiuse
  }

  bozza() {
    return SessionData.bozza
  }
  nessunaLavorazioneInCorso() {
    return SessionData.nessunaLavorazioneInCorso
  }

  get consensiAbilitati() {
    return this.sessionData.configurazione.consensiSegnalante
  }

  public get segnalazioni(): Segnalazione[] {
    return this.sessionData.segnalazioni
  }

  applicaFiltroIniziale(): any {
    let valore = '3'
    if (this.authService.getUser()?.odv) {
      valore = SessionData.filtroGestore
    }
    this.applyFilter(valore, 'gestore');

    ['1', '2', '3', '4', '5', '6', '7'].forEach((key) => {
      this.applyFilter(key, 'stato')
    })

    this.applyFilter('1', 'sospendiCancellazione')
  }

  /*   refreshNotifier(): any {
      this.recuperaMessaggiNonLetti();
    } */

  aggiornaFiltro(event) {
    // console.log('filtro:' + event['stato']);
    switch (event['stato']) {
      case '1':
        SessionData.bozza = !SessionData.bozza
        break
      case '2':
        SessionData.nessunaLavorazioneInCorso =
          !SessionData.nessunaLavorazioneInCorso
        break
      case '3':
        SessionData.lavorazioniInCorso = !SessionData.lavorazioniInCorso
        break
      case '4':
        SessionData.alcuneLavorazioniChiuse =
          !SessionData.alcuneLavorazioniChiuse
        break
      case '5':
        SessionData.tutteLeLavorazioniChiuse =
          !SessionData.tutteLeLavorazioniChiuse
        break
      case '6':
        SessionData.ritirata = !SessionData.ritirata
        break
    }

    // Raccogliamo gli stati attivi
    const statiAttivi = [];
    if (SessionData.bozza) statiAttivi.push(BOZZA_INDEX);
    if (SessionData.nessunaLavorazioneInCorso) statiAttivi.push(NESSUNA_LAVORAZIONE_IN_CORSO_INDEX);
    if (SessionData.lavorazioniInCorso) statiAttivi.push(LAVORAZIONI_IN_CORSO_INDEX);
    if (SessionData.alcuneLavorazioniChiuse) statiAttivi.push(ALCUNE_LAVORAZIONI_CHIUSE_INDEX);
    if (SessionData.tutteLeLavorazioniChiuse) statiAttivi.push(TUTTE_LE_LAVORAZIONI_CHIUSE_INDEX);
    if (SessionData.ritirata) statiAttivi.push(RITIRATA_INDEX);

    // Ricarica i dati con i filtri
    this.loading = true;
    this.segnalazioniService.recuperaSegnalazioni(
      1,
      this.pageSize,
      statiAttivi,
      this.stringaRicercata
    ).subscribe(
      (esito) => {
        if (esito.esito === ESITO_OK) {
          const response = JSON.parse(esito.payload);
          const { segnalazioni, pagination } = response;

          this.totalItems = pagination.total;
          this.totalItemsOriginal = pagination.total;
          this.totalPages = pagination.totalPages;
          this.currentPage = pagination.page;
          this.sessionData.segnalazioni = segnalazioni;

          // Aggiorno il dataSource
          this.sortedData = segnalazioni.map(segnalazione => ({
            ...segnalazione,
            matData: segnalazione.consensi?.length ?
              new MatTableDataSource(segnalazione.consensi) : null
          }));

          this.dataSource = new MatTableDataSource(this.sortedData);
          this.dataSource.sort = this.sort;

          if (this.paginator) {
            this.configurePaginator();
          }
        }
        this.loading = false;
      },
      (error) => {
        console.error('Errore nel caricamento:', error);
        this.loading = false;
        this.snackBar.open(
          $localize`Non è stato possibile recuperare le segnalazioni`,
          null,
          { duration: 4000 }
        );
      }
    );
  }

  sortData(sort: Sort) {
    // const data = this.segnalazioni.slice();
    // const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.sortedData = []

      this.segnalazioni.forEach((segnalazione) => {
        if (
          segnalazione.consensi &&
          Array.isArray(segnalazione.consensi) &&
          segnalazione.consensi.length
        ) {
          this.sortedData = [
            ...this.sortedData,
            {
              ...segnalazione,
              matData: new MatTableDataSource(segnalazione.consensi),
            },
          ]
        } else {
          this.sortedData = [...this.sortedData, segnalazione]
        }
      })

      this.dataSource = new MatTableDataSource(this.sortedData)
      this.dataSource.paginator = this.paginator
      this.applicaFiltroIniziale()
      // Reimposta l'indice della pagina corrente del paginatore a 0 dopo l'ordinamento
      this.paginator.firstPage()
    }

    this.sortedData = this.sortedData.sort((a, b) => {
      const isAsc = sort.direction === 'asc'
      switch (sort.active) {
        case 'idPerSegnalante':
          return a && b
            ? compare(
              parseInt(a.idPerSegnalante?.toString(), 10),
              parseInt(b.idPerSegnalante?.toString(), 10),
              isAsc
            )
            : 0
        case 'progressivo':
          return a && b
            ? compare(
              parseInt(a.progressivo?.toString(), 10),
              parseInt(b.progressivo?.toString(), 10),
              isAsc
            )
            : 0
        case 'dataInserimento':
          return compareDates(
            new Date(a.dataInserimento),
            new Date(b.dataInserimento),
            isAsc
          )
        case 'aziendaInteressata':
          if (!a.aziendaInteressata || !b.aziendaInteressata) {
            return 0  // O ritorna -1 o 1 se vuoi che gli oggetti senza aziendaInteressata siano alla fine o all'inizio
          }
          return compare(
            a.aziendaInteressata.nomeAzienda || '',
            b.aziendaInteressata.nomeAzienda || '',
            isAsc
          )

        case 'oggetto':
          return compare(a.oggetto, b.oggetto, isAsc)
        case 'descrizione':
          return compare(a.descrizione, b.descrizione, isAsc)
        case 'stato':
          return compare(a.stato, b.stato, isAsc)
        case 'segnalante':
          return compare(a.anonima, b.anonima, isAsc)
        case 'scadenza':
          return compareDates(
            new Date(a.dataInvio),
            new Date(b.dataInvio),
            isAsc
          )
        default:
          return 0
      }
    })

    this.dataSource = new MatTableDataSource(this.sortedData)
    this.applicaFiltroIniziale()
  }

  applyFilterInnerTable(filterValue: string) {
    this.innerTables.forEach((table) => {
      (table.dataSource as MatTableDataSource<Consenso>).filterPredicate = (
        data: Consenso,
        filter: string
      ) => {
        const miadata = moment(new Date(data['data']), 'dd/MM/yyyy')
        return (
          data['id'].toString().indexOf(filter) !== -1 ||
          (data['nome'] && data['nome'].toLowerCase().indexOf(filter) !== -1) ||
          (data['cognome'] &&
            data['cognome'].toLowerCase().indexOf(filter) !== -1) ||
          (data['tipo'] && data['tipo'].toLowerCase().indexOf(filter) !== -1) ||
          (data['data'] &&
            miadata.format('DD/MM/YYYY').indexOf(filter) !== -1) ||
          (data['stato'] === Consenso.STATI.ATTESA_INOLTRO &&
            'da inoltrare al segnalante'.indexOf(filter) !== -1) ||
          (data['stato'] === Consenso.STATI.RICHIESTA_INOLTRATA &&
            'inoltrata al segnalante'.indexOf(filter) !== -1) ||
          (data['stato'] === Consenso.STATI.CONCESSO &&
            'concesso'.indexOf(filter) !== -1) ||
          (data['stato'] === Consenso.STATI.NEGATO &&
            'negato'.indexOf(filter) !== -1)
        )
      }
      filterValue = filterValue.trim() // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      (table.dataSource as MatTableDataSource<Consenso>).filter = filterValue
    })
  }

  getDatoInnerTable(consenso: Consenso, colonnaConsenso) {
    const dataOraPipe = new DataOraPipe()
    switch (colonnaConsenso) {
      case 'dataRichiesta':
        return dataOraPipe.transform(consenso[colonnaConsenso])
        break
      case 'dataRisposta':
        return dataOraPipe.transform(consenso[colonnaConsenso])
        break
      case 'stato':
        return decodificaStatoRichiestaConsenso(+consenso.stato)
        break
      case 'richiedente':
        return consenso.richiedente.email
        break
      default:
        return consenso[colonnaConsenso]
    }
    // innerColumn === 'data' ? (element[innerColumn] | date: 'dd/MM/yyyy') : element[innerColumn]
  }

  applyFilter(filterValue: string, key?: string) {
    if (this.dataSource) {
      if (!key) {
        // Gestione filtro di ricerca testuale
        this.stringaRicercata = filterValue?.trim().toLowerCase() || '';
        this.isInputFocused = this.stringaRicercata !== '' && this.stringaRicercata !== undefined;
        // Emetti il valore al Subject invece di eseguire direttamente la ricerca
        this.searchSubject.next(this.stringaRicercata);
      } else {
        // Per i filtri di stato, esegui immediatamente
        this.executeSearch(this.stringaRicercata, key);
      }
    }
  }

  private executeSearch(searchTerm: string, key?: string) {
    // Reset alla prima pagina quando si applicano filtri
    this.currentPage = 1;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    // Raccogliamo gli stati attivi
    const statiAttivi = [];
    if (SessionData.bozza) statiAttivi.push(BOZZA_INDEX);
    if (SessionData.nessunaLavorazioneInCorso) statiAttivi.push(NESSUNA_LAVORAZIONE_IN_CORSO_INDEX);
    if (SessionData.lavorazioniInCorso) statiAttivi.push(LAVORAZIONI_IN_CORSO_INDEX);
    if (SessionData.alcuneLavorazioniChiuse) statiAttivi.push(ALCUNE_LAVORAZIONI_CHIUSE_INDEX);
    if (SessionData.tutteLeLavorazioniChiuse) statiAttivi.push(TUTTE_LE_LAVORAZIONI_CHIUSE_INDEX);
    if (SessionData.ritirata) statiAttivi.push(RITIRATA_INDEX);

    this.stringaRicercata = searchTerm
    // Richiedi dati filtrati al backend
    this.loading = true;
    this.segnalazioniService.recuperaSegnalazioni(
      this.currentPage,
      this.pageSize,
      statiAttivi,
      searchTerm
    ).subscribe(
      (esito) => {
        if (esito.esito === ESITO_OK) {
          const response = JSON.parse(esito.payload);
          const { segnalazioni, pagination } = response;

          this.totalItems = pagination.total;
          this.totalItemsOriginal = pagination.total;
          this.totalPages = pagination.totalPages;

          // Aggiorna il dataSource
          this.sortedData = segnalazioni.map(segnalazione => ({
            ...segnalazione,
            matData: segnalazione.consensi?.length ?
              new MatTableDataSource(segnalazione.consensi) : null
          }));

          this.dataSource = new MatTableDataSource(this.sortedData);
          this.dataSource.sort = this.sort;

          if (this.paginator) {
            this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
              if (length === 0) {
                return `0 di ${this.totalItemsOriginal}`;
              }
              return `${page * pageSize + 1} - ${Math.min((page + 1) * pageSize, this.totalItems)} di ${this.totalItemsOriginal}`;
            };

            this.paginator.length = this.totalItems;
            this.configurePaginator();
          }

          // Aggiorna le colonne filtrate se c'è un termine di ricerca
          if (this.stringaRicercata) {
            this.updateFilteredColumns(segnalazioni);
          } else {
            this.filteredColumns.clear();
          }
        }
        this.loading = false;
      },
      (error) => {
        console.error('Errore nel caricamento:', error);
        this.loading = false;
        this.snackBar.open(
          $localize`Non è stato possibile recuperare le segnalazioni`,
          null,
          { duration: 4000 }
        );
      }
    );
  }

  // Metodo helper per aggiornare le colonne filtrate
  private updateFilteredColumns(segnalazioni: any[]) {
    this.filteredColumns.clear();
    const searchTerm = this.stringaRicercata.toLowerCase();

    segnalazioni.forEach(row => {
      this.colonneVisualizzate.forEach(col => {
        if (col === 'dataInserimento') {
          const browserLocale = navigator.language;
          const miadata = this.datePipe.transform(new Date(row[col]), 'short', browserLocale);
          if (miadata?.toLowerCase().includes(searchTerm)) {
            this.filteredColumns.add(col);
          }
        } else if (col === 'scadenza') {
          const rawDate = this.scadenza(row);
          if (rawDate) {
            try {
              const date = new Date(rawDate);
              if (!isNaN(date.getTime())) {
                const dataScadenza = this.datePipe.transform(date, 'shortDate', navigator.language);
                if (dataScadenza?.toLowerCase().includes(searchTerm)) {
                  this.filteredColumns.add(col);
                }
              }
            } catch (error) {
              console.error('Errore durante la trasformazione della data:', error);
            }
          }
        } else if (String(row[col]).toString().toLowerCase().includes(searchTerm)) {
          this.filteredColumns.add(col);
        }
      });
    });
  }

  toggleTerminiCancellazione() {
    SessionData.soloTerminiCancellazioneSospesi = !SessionData.soloTerminiCancellazioneSospesi
    this.mostraSoloTerminiModificati = SessionData.soloTerminiCancellazioneSospesi
    this.toggleButton.checked = this.mostraSoloTerminiModificati
    this.applyFilter(SessionData.soloTerminiCancellazioneSospesi ? '1' : '0', 'sospendiCancellazione')
  }

  getTooltipToggleTermineCancellazione() {
    return SessionData.soloTerminiCancellazioneSospesi ?
      $localize`Mostra tutto` :
      $localize`Mostra solo le segnalazioni con termini di cancellazione sospesi`
  }

  selected(segnalazione) {
    this.loading = true
    console.log('selected:' + segnalazione.oggetto)
    SessionData.segnalazioneSelezionataElenco = segnalazione
    this.segnalazioniService.recuperaSegnalazione(segnalazione.id).subscribe(
      (esito) => {
        if (esito.esito === ESITO_OK) {
          if (esito.payload) {
            this.sessionData.nuovaSegnalazioneSelezionata(
              JSON.parse(esito.payload)
            )
          }

          this.router.navigate(['/segnalazione'])
        } else if (esito.esito === ESITO_IN_PROGRESS) {
          // Gestisci lo stato in progress come preferisci
          // Ad esempio, potresti mostrare un messaggio di attesa all'utente
          console.log(esito.descrizioneEsito);
        } else {
          this.loading = false
          this.snackBar.open(
            $localize`Non è stato possibile recuperare la segnalazione ` +
            JSON.stringify(esito.descrizioneEsito),
            null,
            {
              duration: 4000,
            }
          )
        }
      },
      () => {
        this.loading = false
        this.snackBar.open(
          $localize`Non è stato possibile recuperare la segnalazione ` +
          segnalazione.idPerSegnalante,
          null,
          {
            duration: 4000,
          }
        )
      }
    )
  }

  aggiungiSegnalazione() {
    const segnalazione = helperSegnalazioni.nuovaSegnalazione(
      this.authService.getUser()
    )

    this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
    // console.log('avvio creazione nuova segnalazione:');
    this.router.navigate(['/segnalazione'])
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0
    if (number > 50) {
      this.navIsFixed = true
    } else if (this.navIsFixed && number < 10) {
      this.navIsFixed = false
    }
    // console.log("scroll:" + number);
  }

  cancella(segnalazione) {
    this.sessionData.segnalazioni.splice(
      this.sessionData.segnalazioni.indexOf(segnalazione),
      1
    )
    /*     this.segnalazioniService.cancellaSegnalazione(segnalazione)
          .then(() => {
            this.snackBar.open("Segnalazione " + segnalazione.riferimentoSegnalazione + " eliminata", null, {
              duration: 2000,
            });
          })
          .catch(() => {
            this.snackBar.open("Non è stato possibile eliminare la segnalazione " + segnalazione.riferimentoSegnalazione, null, {
              duration: 4000,
            });
          }) */
    this.refresh()
  }

  refresh(idSegnalazione?: number) {
    // Reset alla prima pagina solo se non è specificato un ID segnalazione
    if (!idSegnalazione) {
      this.currentPage = 1;
      if (this.paginator) {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = this.pageSize;
      }
    }

    if (this.segnalazioni) {
      this.sortedData = []
      this.segnalazioni.forEach((segnalazione) => {
        if (
          segnalazione.consensi &&
          Array.isArray(segnalazione.consensi) &&
          segnalazione.consensi.length
        ) {
          this.sortedData = [
            ...this.sortedData,
            {
              ...segnalazione,
              matData: new MatTableDataSource<Consenso>(segnalazione.consensi),
            },
          ]
        } else {
          this.sortedData = [...this.sortedData, segnalazione]
        }
      })
      // se ho ricevuto un id di segnalazione, la sposto in testa
      if (idSegnalazione) {
        const index = this.sortedData.findIndex(
          (segnalazione) => segnalazione.id === idSegnalazione
        )
        if (index !== -1) {
          const segnalazione = this.sortedData[index]
          this.sortedData.splice(index, 1)
          this.sortedData.unshift(segnalazione)
        }
      }


      this.dataSource = new MatTableDataSource(this.sortedData)
      this.innerTables.forEach(
        (table) =>
        ((table.dataSource as MatTableDataSource<Consenso>).data = (
          table.dataSource as MatTableDataSource<Consenso>
        ).data)
      )
    }
  }

  openGestioneUtenti() {
    this.router.navigate(['/utenti'], { queryParams: { page: 1 } })
  }

  openDialogConfermaEliminazioneSegnalazione(segnalazione: Segnalazione): void {
    const dialogRef = this.dialog.open(
      ConfermaEliminazioneSegnalazioneDialogComponent,
      {
        width: '500px',
        data: { id: segnalazione.id, oggetto: segnalazione.oggetto },
        disableClose: true,
        hasBackdrop: true,
      }
    )

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cancella(segnalazione)
      }
    })
  }

  openDialogConfermaAzioneConsenso(
    autorizza: boolean,
    consenso: Consenso,
    segnalazione: Segnalazione
  ): void {
    openDialogGenerica(
      this.dialog,
      autorizza ? $localize`Autorizza Consenso` : $localize`Nega Consenso`,
      decodeHtml(
        this.sessionData.configurazione.testoRichiestaConsensoSegnalante
      ),
      autorizza
        ? $localize`Confermi di voler autorizzare il consenso richiesto?`
        : $localize`Confermi di voler negare il consenso richiesto?`,
      autorizza
        ? () => this.autorizzaRichiestaConsenso(consenso, segnalazione)
        : () => this.respingiRichiestaConsenso(consenso, segnalazione),
      () => { },
      autorizza ? $localize`Visualizza Informativa` : '',
      autorizza ? () => this.mostraInformativa() : () => { },
      '50%'
    )
  }

  mostraInformativa() {
    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: this.sessionData.configurazione.informativaTrattamentoDocumento,
        testo: this.sessionData.configurazione.informativaTrattamento,
        titolo: $localize`Informativa Privacy`,
        tipoDocumento: TipoDocumento.TIPO_INFORMATIVA,
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo:
          SottoTipoContenuto.INFORMATIVA_SUL_TRATTAMENTO_DEI_DATI_PERSONALI,
        lingua: this.codiceLingua,
        bypassAuth: true,
        canDownload: true,
        canPrint: true,
      },
    })
  }

  get codiceLingua(): string {
    return this.window.location.href.split('/')[3]
  }

  _convertiStatoSegnalazione(indice): string {
    return statoSegnalazioneLeggibile(indice, !this.authService.getUser().odv)
  }

  statoSegnalazione(segnalazione: Segnalazione) {
    if (segnalazione.oggetto === 'OMISSIS') {
      return 'OMISSIS'
    }
    return this._convertiStatoSegnalazione(
      segnalazione !== undefined ? segnalazione.stato : BOZZA_INDEX
    )
  }

  segnalante(segnalazione: Segnalazione) {
    if (segnalazione.oggetto === 'OMISSIS') {
      return 'OMISSIS'
    }
    if (segnalazione.segnalazioneVocale) {
      if (segnalazione.anonima) {
        return $localize`Anonimo - Segnalazione Vocale`
      } else {
        return $localize`Riservato - Segnalazione Vocale`
      }

    }
    if (segnalazione.anonima) {
      return $localize`Anonimo`
    } else {
      return $localize`Riservato`
    }
  }

  openChatSegnalazione(segnalazione: Segnalazione): void {
    if (segnalazione != null) {
      this.sessionData.cambiaSegnalazioneSelezionata(+segnalazione.id)
    }
    // console.log('apro la chat....');
    this.router.navigate(['chat-manager'])
  }
  get odv(): boolean {
    return this.authService.getUser()?.odv
  }

  get custode(): boolean {
    const result = this.authService.getUser()?.custode
    return result
  }

  get isGestore(): boolean {
    return this.authService.getUser()?.odv
  }

  possoChattare(segnalazione) {
    return +segnalazione.stato !== BOZZA_INDEX
  }

  nuovaRichiestaDiConsensoDaApprovare(segnalazione: Segnalazione) {
    const tmp = segnalazione?.consensi?.find(
      (c) => c.stato === Consenso.STATI.RICHIESTA_INOLTRATA
    )
    return tmp !== undefined
  }

  get isNonGestita(): boolean {
    return this.authService.getUser()?.odv
  }

  get versione(): string {
    return getVersione()
  }

  stampaIndice(indice) {
    console.log(indice)
  }

  puoAggiungereRichiesta(segnalazione: Segnalazione) {
    if (
      segnalazione.consensi &&
      helperSegnalazioni.isUtenteMembrodiOdvSceltoDaSegnalante(
        segnalazione,
        this.authService.getUser()
      )
    ) {
      const odvChePossonoFareRichiesta =
        helperSegnalazioni.odvUtenteChePossonoFareRichiestaDiConsensoAlSegnalante(
          this.authService.getUser(),
          segnalazione
        )
      return (
        odvChePossonoFareRichiesta !== undefined &&
        odvChePossonoFareRichiesta.length > 0
      )
    }
    return false
  }

  puoEditareRichiesta(consenso: Consenso, segnalazione: Segnalazione) {
    return (
      consenso.stato === Consenso.STATI.ATTESA_INOLTRO &&
      helperSegnalazioni.isUtenteMembrodiOdvSceltoDaSegnalante(
        segnalazione,
        this.authService.getUser()
      )
    )
  }

  puoInviareRichiesta(consenso: Consenso, segnalazione: Segnalazione) {
    return (
      consenso.stato === Consenso.STATI.ATTESA_INOLTRO &&
      helperSegnalazioni.isUtenteMembrodiOdvSceltoDaSegnalante(
        segnalazione,
        this.authService.getUser()
      )
    )
  }

  puoAnnullareRichiesta(consenso: Consenso, segnalazione: Segnalazione) {
    return (
      (consenso.stato === Consenso.STATI.ATTESA_INOLTRO ||
        consenso.stato === Consenso.STATI.RICHIESTA_INOLTRATA) &&
      helperSegnalazioni.isUtenteMembrodiOdvSceltoDaSegnalante(
        segnalazione,
        this.authService.getUser()
      )
    )
  }

  puoAutorizzareRichiesta(consenso: Consenso, segnalazione: Segnalazione) {
    return (
      helperSegnalazioni.isSegnalante(
        segnalazione,
        this.authService.getUser()
      ) &&
      consenso.stato !== Consenso.STATI.CONCESSO &&
      consenso.attivo
    )
  }

  puoRifiutareRichiesta(consenso: Consenso, segnalazione: Segnalazione) {
    return (
      helperSegnalazioni.isSegnalante(
        segnalazione,
        this.authService.getUser()
      ) &&
      consenso.stato !== Consenso.STATI.NEGATO &&
      consenso.attivo
    )
  }

  segnalazioneSelezionata() {
    return this.sessionData.getSegnalazioneSelezionata()
  }

  getDataSource(segnalazione: Segnalazione) {
    if (segnalazione['matData'] === undefined) {
      segnalazione['matData'] = new MatTableDataSource(segnalazione.consensi)
    }
    return segnalazione['matData']
  }
  creaModificaRichiestaConsenso(
    segnalazione: Segnalazione,
    consenso?: Consenso
  ) {
    this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
    if (!segnalazione.segnalante) {
      this.recuperaSegnalante(segnalazione).then(() => {
        if (segnalazione.segnalante) {
          this.openDialogRichiestaConsenso(
            segnalazione,
            segnalazione.segnalante,
            consenso
          )
        } else {
          this.snackBar.open(
            $localize`Non è stato possibile recuperare la persona segnalante della segnalazione ` +
            segnalazione.idPerSegnalante +
            (this.sessionData.configurazione.custodiaIdentita
              ? $localize`. Un motivo potrebbe essere l'assenza dell'autorizzazione da parte del custode.`
              : '.'),
            null,
            {
              duration: 12000,
            }
          )
        }
      })
    } else {
      this.openDialogRichiestaConsenso(
        segnalazione,
        segnalazione.segnalante,
        consenso
      )
    }
  }

  recuperaSegnalante(segnalazione: Segnalazione) {
    return this.segnalazioniService
      .recuperaPersonaSegnalante(+segnalazione.id)
      .then(
        (esito) => {
          if (esito.esito === ESITO_OK) {
            const segnalante = JSON.parse(esito.payload) as Segnalante
            segnalazione.segnalante = segnalante
          } else {
            console.warn(
              'Non è stato possibile recuperare la persona segnalante della segnalazione ' +
              segnalazione.idPerSegnalante
            )
          }
        },
        () => {
          console.warn(
            'Non è stato possibile recuperare la persona segnalante della segnalazione ' +
            segnalazione.idPerSegnalante
          )
        }
      )
  }

  openDialogRichiestaConsenso(segnalazione, segnalante, consenso?: Consenso) {
    const dialogRef = this.dialog.open(
      RichiestaConsensoSegnalanteDialogComponent,
      {
        width: '600px',
        data: {
          segnalante: segnalante,
          consenso: consenso,
          diritti: segnalazione.dirittiSegnalazioneOdv.filter((diritto) =>
            isMembroDiGruppo(
              this.authService.getUser(),
              diritto.odvDestinatario
            )
          ),
        },
        disableClose: true,
        hasBackdrop: true,
      }
    )

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result !== undefined) {
        const cons = result.data as Consenso
        cons.segnalazione = new Segnalazione()
        cons.segnalazione.id = segnalazione.id
        this.salvaRichiestaConsenso(result.data, segnalazione)
      }
    })
  }

  modificaRichiestaConsenso(consenso: Consenso, segnalazione: Segnalazione) {
    this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
    this.creaModificaRichiestaConsenso(segnalazione, consenso)
  }

  openDialogConfermaInvioRichiestaConsenso(
    consenso: Consenso,
    segnalazione: Segnalazione
  ): void {
    openDialogGenerica(
      this.dialog,
      $localize`Invio Richiesta di Consenso`,
      '',
      $localize`Confermi di voler inviare la richiesta al segnalante?`,
      () => this.inviaRichiestaConsenso(consenso, segnalazione),
      () => { }
    )
  }

  inviaRichiestaConsenso(consenso: Consenso, segnalazione: Segnalazione) {
    this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
    this.segnalazioniService
      .inoltraConsenso(consenso.id)
      .then((esito) => {
        if (ESITO_OK === esito.esito) {
          const temp = JSON.parse(esito.payload) as Consenso
          const consensi = this.sessionData.getSegnalazioneSelezionata().consensi
          consensi.splice(
            consensi.findIndex((cons) => +cons.id === +temp.id),
            1,
            temp
          )
          this.refresh(+segnalazione.id)
          document.getElementById('button-' + segnalazione.id)?.click()
          this.snackBar.open($localize`Inoltro Consenso Eseguito`, null, {
            duration: 4000,
          })
        } else {
          this.snackBar.open(
            $localize`Inoltro Consenso Fallito. Errore: ` +
            esito.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((errore) => {
        this.snackBar.open(
          $localize`Inoltro Consenso Fallito. Errore: ` + errore,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  annullaRichiestaConsenso(consenso: Consenso, segnalazione: Segnalazione) {
    this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
    this.segnalazioniService
      .annullaRichiestaConsenso(consenso.id)
      .then((esito) => {
        if (ESITO_OK === esito.esito) {
          const temp = JSON.parse(esito.payload) as Consenso
          const consensi = this.sessionData.getSegnalazioneSelezionata().consensi
          if (consenso.stato === Consenso.STATI.RICHIESTA_INOLTRATA) {
            consensi.splice(
              consensi.findIndex((cons) => +cons.id === +temp.id),
              1,
              temp
            )
          } else {
            consensi.find((con) => con.id === consenso.id).attivo = false
            consensi.push(temp)
          }
          this.refresh(+segnalazione.id)
          document.getElementById('button-' + segnalazione.id)?.click()
          this.snackBar.open(
            $localize`Annullamento Richiesta di Consenso Eseguito`,
            null,
            {
              duration: 4000,
            }
          )
        } else {
          this.snackBar.open(
            $localize`Annullamento Richiesta di Consenso Fallito. Errore: ` +
            esito.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((errore) => {
        this.snackBar.open(
          $localize`Annullamento Richiesta di Consenso Fallito. Errore: ` +
          errore,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  autorizzaRichiestaConsenso(consenso: Consenso, segnalazione: Segnalazione) {
    this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
    this.segnalazioniService
      .autorizzaConsenso(consenso.id)
      .then((esito) => {
        if (ESITO_OK === esito.esito) {
          const temp = JSON.parse(esito.payload) as Consenso
          const consensi = this.sessionData.getSegnalazioneSelezionata().consensi
          if (consenso.stato === Consenso.STATI.RICHIESTA_INOLTRATA) {
            consensi.splice(
              consensi.findIndex((cons) => +cons.id === +temp.id),
              1,
              temp
            )
          } else {
            consensi.find((con) => con.id === consenso.id).attivo = false
            consensi.push(temp)
          }
          this.refresh(+segnalazione.id)
          document.getElementById('button-' + segnalazione.id)?.click()
          this.snackBar.open(
            $localize`Autorizzazione Richiesta di Consenso Eseguito`,
            null,
            {
              duration: 4000,
            }
          )
        } else {
          this.snackBar.open(
            $localize`Autorizzazione Richiesta di Consenso Fallito. Errore: ` +
            esito.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((errore) => {
        this.snackBar.open(
          $localize`Annullamento Richiesta di Consenso Fallito. Errore: ` +
          errore,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  respingiRichiestaConsenso(consenso: Consenso, segnalazione: Segnalazione) {
    this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
    this.segnalazioniService
      .negaConsenso(consenso.id)
      .then((esito) => {
        if (ESITO_OK === esito.esito) {
          const temp = JSON.parse(esito.payload) as Consenso
          const consensi = this.sessionData.getSegnalazioneSelezionata().consensi
          if (consenso.stato === Consenso.STATI.RICHIESTA_INOLTRATA) {
            consensi.splice(
              consensi.findIndex((cons) => +cons.id === +temp.id),
              1,
              temp
            )
          } else {
            consensi.find((con) => con.id === consenso.id).attivo = false
            consensi.push(temp)
          }
          this.refresh(+segnalazione.id)
          document.getElementById('button-' + segnalazione.id)?.click()
          this.snackBar.open(
            $localize`Rifiuto Richiesta di Consenso Eseguito`,
            null,
            {
              duration: 4000,
            }
          )
        } else {
          this.snackBar.open(
            $localize`Rifiuto Richiesta di Consenso Fallito. Errore: ` +
            esito.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((errore) => {
        this.snackBar.open(
          $localize`Annullamento Richiesta di Consenso Fallito. Errore: ` +
          errore,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  salvaRichiestaConsenso(consenso: Consenso, segnalazione: Segnalazione) {
    this.segnalazioniService
      .salvaConsenso(consenso)
      .then((esito) => {
        if (ESITO_OK === esito.esito) {
          const temp = JSON.parse(esito.payload) as Consenso
          const segna = this.sessionData.segnalazioni.find(
            (s) => +s.id === +segnalazione.id
          )
          const consensi = segna.consensi
          const indice = consensi.findIndex((c) => c.id === temp.id)
          if (indice > -1) {
            consensi.splice(indice, 1, temp)
          } else {
            consensi.push(temp)
          }
          this.refresh(+segnalazione.id)
          document.getElementById('button-' + segnalazione.id)?.click()
          this.snackBar.open($localize`Salvataggio Consenso Eseguito`, null, {
            duration: 4000,
          })
        } else {
          this.snackBar.open(
            $localize`Salvataggio Consenso Fallito. Errore: ` +
            esito.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((errore) => {
        this.snackBar.open(
          $localize`Salvataggio Consenso Fallito. Errore: ` + errore,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  getIdSegnalazione(segnalazione) {
    return helperSegnalazioni.getIdSegnalazione(
      segnalazione,
      this.authService.getUser()
    )
  }

  nomeSegnalante(consenso: Consenso) {
    return helperSegnalazioni.nomeSegnalante(
      consenso,
      this.sessionData.configurazione.custodiaIdentita
    )
  }

  cognomeSegnalante(consenso: Consenso) {
    return helperSegnalazioni.cognomeSegnalante(
      consenso,
      this.sessionData.configurazione.custodiaIdentita
    )
  }

  scadenza(segnalazione: Segnalazione) {
    // ricava la data di scadenza sommando alla data invio il numero di giorni presenti nel campo
    return helperSegnalazioni.scadenza(
      segnalazione,
      this.sessionData.configurazione
    )
  }

  nomeOdvRichiedente(consenso: Consenso) {
    return consenso.odvRichiedente?.odvDestinatario?.nomeOdv
  }

  checkIfRichiedenteIsVisible(innerDisplayedColumn) {
    return innerDisplayedColumn === $localize`Richiedente`
  }

  checkIfNomeIsVisible(innerDisplayedColumn) {
    return innerDisplayedColumn === $localize`Nome`
  }

  checkIfCognomeIsVisible(innerDisplayedColumn) {
    return innerDisplayedColumn === $localize`Cognome`
  }

  checkIfAzioniIsVisible(innerDisplayedColumn) {
    return innerDisplayedColumn === $localize`Azioni`
  }

  recuperaTutteLeAziende() {
    this.elencoAziende = []

    this.aziendaService
      .recuperaAziende()
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          const aziende = JSON.parse(esito.payload)
          this.elencoAziende = aziende
          this.aggiornaElencoColonne()
        } else {
          this.snackBar.open(
            $localize`Recupero elenco aziende Fallito. Errore: ` +
            esito.payload,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Recupero elenco aziende Fallito. Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
      })
      .finally(() => {
        // this.loading = false
      })
  }

  aggiornaElencoColonne() {
    this.colonneVisualizzate = this.mostraTipiNonConformita && this.multiAzienda
      ? [
        'progressivo',
        'idPerSegnalante',
        'aziendaInteressata',
        'oggetto',
        'dataInserimento',
        'descrizione',
        'segnalante',
        'stato',
        'scadenza',
        'azioni',
      ]
      : !this.mostraTipiNonConformita && this.multiAzienda
        ? [
          'progressivo',
          'idPerSegnalante',
          'aziendaInteressata',
          'oggetto',
          'dataInserimento',
          'segnalante',
          'stato',
          'scadenza',
          'azioni',
        ]
        : this.mostraTipiNonConformita && !this.multiAzienda
          ? [
            'progressivo',
            'idPerSegnalante',
            'oggetto',
            'dataInserimento',
            'descrizione',
            'segnalante',
            'stato',
            'scadenza',
            'azioni',
          ]
          : [
            'progressivo',
            'idPerSegnalante',
            'oggetto',
            'dataInserimento',
            'segnalante',
            'stato',
            'scadenza',
            'azioni',
          ]
  }

  hasNextPage(): boolean {
    return this.currentPage < Math.ceil(this.totalItemsOriginal / this.pageSize);
  }

  hasPreviousPage(): boolean {
    return this.currentPage > 1;
  }

}
